import '../assets/css/style.css'
import Util from '../Util/Util';
import config from '../config';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {Card, Grid, CardContent, TextField, Button, Divider, CircularProgress, CardHeader} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {Helmet} from "react-helmet";

export default function Login(props) {

    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [isLoggingIn, setIsLogginIn] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);

    const history = useHistory();

    const loginAction = () => {
        setIsLogginIn(true);
        Util.apiCall(config.server, "/login", "POST", {username, password}).then( res => {
            console.log(res);
            if (res.authenticated === 0) {
                // Login failed.
                setLoginFailed(true);
            } else if (res.isActive === 0) {
                localStorage.setItem("jwt_token", res.jwt_token);
                history.push(`/authenticateEmail/${res.email}`);
            } else if (res.authenticated === 1 && res.isActive === 1){
                // Login success
                localStorage.setItem("jwt_token", res.jwt_token);
                localStorage.setItem('department', res.department);
                if (res.user_type === "ins") {
                    history.push('/dashboard/instructor/profile');
                } else if(res.user_type === "stu") {
                    history.push('/dashboard/student/profile');
                } else if (res.user_type === "adm") {
                    history.push('/dashboard/admin/profile');
                }
            }
            setIsLogginIn(false);
        });
    }

    return (
            <Grid container justify="center" style={{marginTop: '30vh'}}>
                <Helmet>
                    <title>ThesisGate - Σύνδεση</title>
                </Helmet>
                <Grid xs={12} md={4}>
                    <Card style={{padding: '15px'}}>
                        <CardHeader title="ThesisGate" />
                        <CardContent>
                            <TextField
                                style={{width:'100%'}}
                                placeholder="Όνομα χρηστη"
                                value={username}
                                onChange={(e) => {setUsername(e.currentTarget.value)}}
                                label="Όνομα χρηστη"
                            />
                            <div className="mt-2">
                                <TextField
                                    style={{width:'100%'}}
                                    id="standard-password-input"
                                    type="password"
                                    placeholder="Κωδικός πρόσβασης"
                                    value={password}
                                    onChange={(e) => {setPassword(e.currentTarget.value)}}
                                    label="Κωδικός πρόσβασης"
                                />
                            </div>

                            <Divider />

                            <Grid justify="center">
                                <Grid item span={12} className="flex justify-content-center">
                                    <div className="flex justify-content-center mt-2">
                                        <Button variant="contained" color="primary" onClick={() => loginAction()} disableElevation>
                                            {
                                                isLoggingIn ?
                                                    <CircularProgress /> :
                                                    <span>Σύνδεση</span>
                                            }
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>

                            {
                                loginFailed ?
                                    <div className="mt-2">
                                        <Alert severity="error">Λάθος κωδικός ή όνομα. Παρακαλώ προσπαθήστε ξανά!</Alert>
                                    </div> : <></>
                            }
                            <Grid>
                                <Grid item span={12} className="text-center mt-2">
                                    <p>Δεν έχεις λογαριασμό; <Link onClick={() => {history.push('/register')}}>Δημιούργησε έναν καινούργιο!</Link></p>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
        </Grid>
  
    );
}

