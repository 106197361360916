import React, {useState} from "react";
import {Dialog, DialogTitle,DialogContentText, DialogContent,DialogActions, Button, Card, CardContent, CardHeader, TextField} from "@material-ui/core";

export default function ThesisEpilogue({updateThesisMark})
{
    const [grade, setGrade] = useState(1);
    const [alertVisible, setAlertVisible] = useState(false);

    return (
        <Card>
            <CardHeader
                title="Βαθμολόγηση διατριβής"
                subheader="Η διατριβή έχει απο πλευράς μαθητή. Για να τελειώσει ολοκληρωτικά χρειάζεται την βαθμολογία σας."
            />
            <CardContent>
                <TextField
                    type="number"
                    label="Βαθμολογία (1-10)"
                    value={grade}
                    onChange={(val) => {
                        const value = parseInt(val.target.value);
                        if (value >= 1 && value <= 10) setGrade(value);
                    }}
                    aria-valuemin={1}
                    aria-valuemax={10}
                /><br />
                <Button onClick={() => setAlertVisible(true)} style={{marginTop: "10px"}} variant="contained" color="primary" size="small">Αποθήκευση</Button>
            </CardContent>

            <Dialog
                open={alertVisible}
                onClose={() => setAlertVisible(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Βαθολόγηση διατριβής με ${grade}/10?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Με την βαθμολόγηση της διατριβής η διατριβή θα προστεθεί στις ολοκληρωμένες διατριβές.
                        Είστε σίγουροι για αυτήν την κίνηση;
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setAlertVisible(false);}} color="secondary" variant="contained">
                        Ακύρωση
                    </Button>
                    <Button onClick={() => {setAlertVisible(false);updateThesisMark(grade);}} color="primary" variant="contained" autoFocus>
                        Αποδοχή
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}