import {
    Card,
    Accordion,
    CardContent,
    Grid,
    Paper,
    Typography,
    IconButton,
    CardHeader,
    Tooltip, Chip,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import config from '../../../config';
import Util from '../../../Util/Util';
import PreferedThesis from './PreferedThesis';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReactHtmlParser from 'react-html-parser';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import TodoListItem from "./TodoListItem";
import {
    getCurrentDoListItemPercentage,
    getCurrentUserTodoItem, getStartAndEndDateOfDoListItem
} from "../../../DataTypes/Models/TodoListItem/TodoListItem";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export default function MyThesis(props) {

    const [preferedThesis, setPreferedThesis] = useState([]);
    const [thesis, setThesis] = useState(null);
    const [requestLoaded, setRequestLoaded] = useState(false);

    const [readMore, setReadMore] = useState(false);

    useEffect( () => {
        if (!requestLoaded) {
            const jwt_token = localStorage.getItem("jwt_token");
            setRequestLoaded(true);
            Util.apiCall(config.server, `/v1/student/thesis/current?jwt_token=${jwt_token}`, "GET").then( res => {
                if (res.hasOwnProperty("preferedThesis")) {
                    setPreferedThesis(res.preferedThesis);
                } else {
                    // Set right order on doLists.
                    const thesisCp = Object.assign({}, res.thesis);

                    let doLists;
                    if (thesisCp.doList) {
                        doLists = thesisCp.doList.doListItems.sort( ( a, b ) => {
                            if ( a.dliOrder < b.dliOrder ){
                                return -1;
                            }
                            if ( a.dliOrder > b.dliOrder ){
                                return 1;
                            }
                            return 0;
                        } );
                    }

                    thesisCp.doListItems = doLists;
                    setThesis(thesisCp);
                }
            });
        }
    }, [thesis]);

    const setThesisVisibility = (visibility) => {
        const jwt_token = localStorage.getItem("jwt_token");
        Util.apiCall(config.server, `/v1/student/thesis/${thesis.thesisID}/visibility/${visibility}`, "PUT", {jwt_token}).then( res => {
            const th = Object.assign({}, thesis);
            th.thesisIsPublicByStu = visibility === 1;
            setThesis(th);
        });
    }

    const updateTodoItem = (todoItem) => {
        const doItems = [...thesis.doList.doListItems];

        let pos = -1;
        for (let i=0;i<doItems.length;i++) {
            if (doItems[i].dliID === todoItem.dliID) {
                pos = i;
            }
        }

        const thesisCp = Object.assign({}, thesis);
        thesisCp.doList.doListItems[pos] = todoItem;

        setThesis(thesisCp);
    }

    const getTotalDuration = (todoItem) => {
        let totalDur;
        if (todoItem.dliDurationUnit === "weeks") {
            totalDur = 7 * todoItem.dliDuration;
        } else if (todoItem.dliDurationUnit === "months") {
            totalDur = 30  *todoItem.dliDuration;
        } else {
            totalDur = todoItem.dliDuration;
        }
        return totalDur;
    }


    if (thesis) {

        // Get end date for each one.
        const doListItemDays = [];

        if (thesis.doList) {
            thesis.doList.doListItems.map((doItem, index) => {
                let days = 0;
                doListItemDays.map( listDays => {
                    days += listDays;
                })
                days += getTotalDuration(doItem);
                doListItemDays.push(days);
            })
        }

        return (
            <Grid container>
                <Helmet>
                    <title>ThesisGate - Η διατριβή μου</title>
                </Helmet>
                <Grid item xs={12} lg={8}>
                    <Card variant="outlined" style={{margin: "10px"}}>
                    <CardHeader
                        title={
                            thesis.thesisMark ?
                                <>
                                    {thesis.thesisTitle}
                                    <Chip style={{marginLeft: "20px"}} color="primary" label={`Ολοκληρώθηκε με βαθμό ${thesis.thesisMark}`} />
                                </>
                                :
                                <Typography><b>{thesis.thesisTitle}</b></Typography>

                        }
                        action={
                            thesis.thesisIsPublicByStu ?
                            <>
                                <Tooltip title="Ιδιωτική εμβέλια διατριβής">
                                    <IconButton onClick={() => {setThesisVisibility(0)}}>
                                        <VisibilityOffIcon />
                                    </IconButton>
                                </Tooltip>
                            </> : <>
                                <Tooltip title="Δημόσια εμβέλια διατριβής">
                                    <IconButton onClick={() => {setThesisVisibility(1)}}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                            }
                        />
                        <CardContent>
                            {
                                readMore ?
                                <>
                                    {ReactHtmlParser(thesis.thesisDescr)}
                                    <Typography style={{color: "#3f51b5", cursor: "pointer"}} onClick={() => setReadMore(false)}>Λιγότερα...</Typography>
                                </>
                                :
                                <>
                                    {ReactHtmlParser(thesis.thesisDescr.slice(0,200))}...
                                <Typography style={{color: "#3f51b5", cursor: "pointer"}} onClick={() => setReadMore(true)}>Περισσότερα...</Typography>
                                </>
                            }
                        </CardContent>
                    </Card>
                </Grid>
                {
                    thesis.doList ?
                        <Grid item xs={12} lg={4}>

                            <div style={{margin: "10px"}}>
                                <Paper elevation={2} style={{padding: "10px", marginBottom: "10px"}}>
                                    <Typography>{thesis.doList.doListTitle}</Typography>
                                </Paper>
                                <Card style={{maxHeight: "75vh", overflowY: "scroll"}}>
                                    <CardContent>
                                        {
                                            thesis.doList.doListItems.map((doItem, index) => {

                                                let isOpen = false;
                                                if (index === 0) {
                                                    isOpen = true;
                                                } else {
                                                    if (thesis.doList.doListItems[index - 1].dliInstructorOK === 1 &&
                                                        thesis.doList.doListItems[index - 1].dliStudentOK === 1) {
                                                        isOpen = true;
                                                    }
                                                }
                                                return <TodoListItem
                                                    hasUser={true}
                                                    pos={index}
                                                    currentUserTodoItem={getCurrentUserTodoItem(thesis.doList.doListItems)}
                                                    userType="stu"
                                                    updateTodoItem={(doItem) => updateTodoItem(doItem)}
                                                    isOpen={isOpen}
                                                    finishPercentage={getCurrentDoListItemPercentage(thesis.doList.doListItems, doItem, thesis.thesisStartDate.date)}
                                                    todoItem={doItem}
                                                    startAndEnd={getStartAndEndDateOfDoListItem(thesis.doList.doListItems, doItem, thesis.thesisStartDate.date)}
                                                />
                                            })
                                        }
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid> : <></>
                }
            </Grid>
        )
    } else {
        return (
            <div style={{marginLeft: "10px"}}>
                <Paper style={{width: '80%', margin: "10px", padding: "5px 10px"}}>
                <Typography style={{marginTop: "10px"}} variant="h4">Αιτήσεις διατριβών</Typography>
                <Typography variant="subtitle1">Εδώ βρίσκονται όλες οι διατριβές τις οποίες έχετε δηλώση</Typography>
                </Paper>
                {
                    preferedThesis.length > 0 ?
                        <PreferedThesis preferedThesis={preferedThesis} setThesis={(thesis) => setThesis(thesis)} /> :
                        <Paper style={{width: '80%', margin: "10px", padding: "15px 15px"}}>
                                Φένεται πως δεν έχετε δηλώσει κάποια διατριβή. Πηγαίνετε στις ανοιχτές διατριβές και δηλώστε κάποια που σας ενδιαφέρει
                            <Grid container className="mt-2">
                                <Link to="/dashboard/student/theses/open" style={{borderBottom: "none"}}>
                                    <Button className="mt-2" variant="contained" color="primary">Ανοιχτές Διατριβές</Button>
                                </Link>
                            </Grid>
                        </Paper>
                }
            </div>
        )
    }
}