const FIELD_CATEGORIES = {
    title: 'title',
    intentedDate: 'intentedData',
    assignedTo: 'assignedTo',
    description: 'description',
    prefersTheses: 'prefersTheses',
    type: 'type',
    visibility: 'visibility',
    keywords: 'keywords',
    instructorInfo: 'instructoriInfo'
};

export default FIELD_CATEGORIES;