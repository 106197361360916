import {sortDoList} from "../Thesis/Thesis";
import Util from "../../../Util/Util";
import config from "../../../config";
import {addDays} from "../../../Util/DateFunctions";

export default class TodoListItem {
    constructor(
        dliID = null,
        doListID = null,
        dliTitle = '',
        dliDescr = '',
        dliOrder = '',
        dliDuration = '',
        dliDurationUnit = 'days',
        dliStudentOK = 0,
        dliInstructorOK = 0,
        stuOKWhen = null,
        instrOKWhen = null
    ) {
        this.dliID = dliID;
        this.doListID = doListID;
        this.dliTitle = dliTitle;
        this.dliDescr = dliDescr;
        this.dliOrder = dliOrder;
        this.dliDuration = dliDuration;
        this.dliDurationUnit = dliDurationUnit;
        this.dliStudentOK = dliStudentOK;
        this.dliInstructorOK = dliInstructorOK;
        this.stuOKWhen = stuOKWhen;
        this.instrOKWhen = instrOKWhen;
    }
}

export function cancelStudentDliFinish(dli) {
    const jwt_token = localStorage.getItem("jwt_token");
    return Util.apiCall(config.server, `/v1/instructor/todoItem/${dli.dliID}/student/cancel`, "POST", {jwt_token});
}

/**
 *
 * @param {TodoListItem} todoItem
 * @returns {string|number}
 */
export function getTotalDuration(todoItem) {
    let totalDur;
    if (todoItem.dliDurationUnit === "weeks") {
        totalDur = 7 * todoItem.dliDuration;
    } else if (todoItem.dliDurationUnit === "months") {
        totalDur = 30  *todoItem.dliDuration;
    } else {
        totalDur = todoItem.dliDuration;
    }
    return totalDur;
}

/**
 *
 * @param {TodoListItem[]} doList
 * @param {TodoListItem} doListItem
 * @param {Date} thesisStart
 */
export function getCurrentDoListItemPercentage(doList, doListItem, thesisStart)
{
    const {startDate, endDate} = getStartAndEndDateOfDoListItem(doList, doListItem, thesisStart);
    let endDateStartDateDiff = Util.DateFunctions.getDifferentInDays(endDate, startDate);
    let startDateNowDiff = Util.DateFunctions.getDifferentInDays(new Date(), startDate);

    return 100 - ((endDateStartDateDiff - startDateNowDiff)/endDateStartDateDiff) * 100;
}

/**
 *
 * @param {TodoListItem[]} doList
 * @param thesisStart
 * @param estimatedEndDate
 */
export function getEstimatedTotalTimePercentage(doList, thesisStart, estimatedEndDate) {
    let startDate = new Date(thesisStart.date);
    let endDate = new Date(estimatedEndDate.date);

    let totalTodoItemDays = 0;
    for (let i=0;i<doList.length;i++) {
        totalTodoItemDays +=  getTotalDuration(doList[i]);
    }

    const totalDays = Util.DateFunctions.getDifferentInDays(startDate, endDate);
    return 100 - ((totalDays - totalTodoItemDays) / totalDays) * 100;
}

export function getEstimatedEndDate(doList, thesisStart) {
    let startDate = new Date(thesisStart.date);

    let totalTodoItemDays = 0;
    for (let i=0;i<doList.length;i++) {
        totalTodoItemDays +=  getTotalDuration(doList[i]);
    }

    return Util.DateFunctions.addDays(startDate, totalTodoItemDays);
}

export function getRealEndDate(doList, thesisStart) {
    let startDate = new Date(thesisStart.date);
    let prevDate = startDate;

    let totalTodoItemDays = 0;
    for (let i=0;i<doList.length;i++) {
        if (!doList[i].stuOKWhen) {
            totalTodoItemDays +=  getTotalDuration(doList[i]);
            prevDate = Util.DateFunctions.addDays(prevDate, totalTodoItemDays);
        } else {
            const currDate = new Date(doList[i].stuOKWhen.date);
            totalTodoItemDays += Util.DateFunctions.getDifferentInDays(currDate, prevDate);
            prevDate = currDate;
        }
    }

    return Util.DateFunctions.addDays(startDate, totalTodoItemDays);
}

export function getRealTotalTimePercentage(doList, thesisStart, estimatedEndDate) {
    let startDate = new Date(thesisStart.date);
    let endDate = new Date(estimatedEndDate.date);

    let prevDate = startDate;

    let totalTodoItemDays = 0;
    for (let i=0;i<doList.length;i++) {
        if (!doList[i].stuOKWhen) {
            totalTodoItemDays +=  getTotalDuration(doList[i]);
            prevDate = Util.DateFunctions.addDays(prevDate, totalTodoItemDays);
        } else {
            const currDate = new Date(doList[i].stuOKWhen.date);
            totalTodoItemDays += Util.DateFunctions.getDifferentInDays(currDate, prevDate);
            prevDate = currDate;
        }
    }

    const totalDays = Util.DateFunctions.getDifferentInDays(startDate, endDate);
    return 100 - ((totalDays - totalTodoItemDays) / totalDays) * 100;
}

export function getStartAndEndDateOfDoListItem(doList, doListItem, thesisStart) {
    let endDate = new Date(thesisStart);
    let startDate = null;

    console.log(doList);

    let doListItemFound = false;
    let pos = 0;
    while(!doListItemFound) {

        startDate = endDate;

        if (doList[pos].stuOKWhen !== null) {
            endDate = new Date(doList[pos].stuOKWhen.date);
        } else {
            console.log(getTotalDuration(doList[pos]));
            endDate = Util.DateFunctions.addDays(endDate, getTotalDuration(doList[pos]));
            console.log(endDate);
        }

        if(doList[pos].dliID === doListItem.dliID) {
            doListItemFound = true;
        }

        pos++;
    }

    return {startDate, endDate}
}

export function deleteDoListItem(id) {
    const jwt_token = localStorage.getItem("jwt_token");
    return Util.apiCall(config.server, `/v1/instructor/todo-list/item/${id}`, "DELETE", {jwt_token});
}

export function updateOrder(id, order) {
    const jwt_token = localStorage.getItem("jwt_token");
    return Util.apiCall(config.server, `/v1/instructor/todo-list/item/${id}/order/${order}`, "PUT", {jwt_token});
}

/**
 * @param todoItems
 * @returns {*}
 */
export function getCurrentUserTodoItem(todoItems) {

    let orderedTodoItems = sortDoList(todoItems);

    let pos = -1;
    for (let i=0;i<orderedTodoItems.length;i++) {
        if (orderedTodoItems[i].dliInstructorOK === 1 && orderedTodoItems[i].dliStudentOK === 1) {
            pos++;
        }
    }

    if (pos === -1) return orderedTodoItems[0];
    if (pos + 1 === orderedTodoItems.length) return null;

    return orderedTodoItems[pos+1];
}