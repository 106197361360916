import config from "../../config";
import TodoListItem from "../../DataTypes/Models/TodoListItem/TodoListItem";
import Util from "../../Util/Util";

export default async function TodoListItemsDataContainer(route) {
    let todoLists = [];
    const jwt_token = localStorage.getItem("jwt_token");
    await Util.apiCall(config.server, `/v1/instructor${route}?jwt_token=${jwt_token}`, "GET").then( res => {
        res.map( item => {
           const todoList = new TodoListItem(
               item['dliID'],
               item['doListID'],
               item['dliTitle'],
               item['dliDescr'],
               item['dliOrder'],
               item['dliDuration'],
               item['dliDurationUnit'],
               item['dliStudentOK'],
               item['dliInstructorOK'],
               item['stuOKWhen'],
               item['instruOKWhen']
           )
           todoLists.push(todoList);
        });
    });
    return todoLists;
}