import React, {useState, useEffect} from 'react';
import ThesisDataContainer from '../../DataContainers/ThesisDataContainer';
import ThesisCardCollection from './parts/ThesisCard/ThesisCardCollection';
import OverviewTemplate from '../templates/OverviewTemplate';
import PaginationWrapper from "./parts/PaginationWrapper";
import ThesisFilters from "./parts/ThesisFilters";
import {deleteSingleThesis, sortDoList} from "../../../DataTypes/Models/Thesis/Thesis";
import {Helmet} from "react-helmet";

export class Filters {
    constructor(sign, value, controlType, dataType) {
        this.sign = sign;
        this.value = value;
        this.controlType = controlType;
        this.dataType = dataType;
    }

    evaluate(value) {
        if (this.controlType === "string") {
            if (this.sign === "=") {
                return value.includes(this.value);
            }
            return true;
        } else if (this.controlType === "radio") {
            if (this.value === "" || this.value === null) return true;
            if (this.sign === "=") {
                return this.value == value;
            } else if (this.sign === "<") {
                return this.value < value;
            } else if (this.sign === ">") {
                return this.value > value;
            }
        }
        // Value is an array in this case.
        else if (this.controlType === "range") {
            if (this.value === null || this.value == null) return true;
            if (this.dataType === "date") {
                const currDate = new Date(value);
                const min = this.value[0];
                if (min) {
                    return currDate > min;
                }

                const max = this.value[1];
                if (max) {
                    return currDate < max;
                }

                return true;
            }
        }
        else if (this.controlType === "includeAll") {
            for (let i=0;i<this.value.length;i++) {
                let isItIncluded = false;
                for (let j=0;j<value.length;j++) {
                    if (this.value[i] === value[j].keyword) {
                        isItIncluded = true;
                    }
                }

                if (!isItIncluded) {
                    return false;
                }
            }

            return true;
        }

        return true;
    }
}

export default function OverviewThesis({apiRoute, fields, isEditable, viewRoute, cardTitle, breadcrumb}) {

    const [thesisLoaded, setThesisLoaded] = useState(false);
    const [thesisCards, setThesisCards] = useState([]);
    const [filteredCards, setFilteredCards] = useState([]);
    const [pagination, setPagination] = useState(1);

    const [filters, setFilters] = useState({
        thesisTitle: new Filters("=", "", "string", "string"),
        thesisAssignType: new Filters("NONE", "", "radio", "integer"),
        thesisIntendedEnd: new Filters("NONE", [null, null], "range", "date"),
        keywords: new Filters("=", [], "includeAll", "string")
    });

    useEffect(() => {
        ThesisDataContainer(apiRoute ? apiRoute : '/theses').then( theses => {

            theses.map( thesis => {
                if (thesis.doList) {
                    const doLists = [...thesis.doList.doListItems];
                    thesis.doList.doListItems = sortDoList(doLists);
                    console.log(thesis.doList.doListItems);
                }
            })

            setThesisLoaded(true);
            setThesisCards(theses);
            setFilteredCards(theses);
        });
    }, []);

    const setFilter = (field, sign, value, pos = null) => {
        let filtersCp = Object.assign({}, filters);
        filtersCp[field].sign = sign;

        if (pos !== null) {
            filtersCp[field].value[pos] = value;
        } else {
            filtersCp[field].value = value;
        }

        setFilters(filtersCp);

        const filteredCardsCp = thesisCards.filter( (thesis) => {
            let thesisPasses = true;
            Object.keys(filters).map( filter => {
                if (!filters[filter].evaluate(thesis[filter])) {
                    thesisPasses = false;
                }
            })

            return thesisPasses;
        });
        
        setFilteredCards(filteredCardsCp);
    }

    const deleteThesis = (id) => {
        deleteSingleThesis(id).then( () => {
            setFilteredCards(filteredCards.filter(thesis => {
                return thesis.thesisID !== id;
            }))
        })
    }

    return (
        <>
            <Helmet>
                <title>ThesisGate - Διατριβές</title>
            </Helmet>
        <OverviewTemplate
            breadcrumb={breadcrumb}
            list={
                <ThesisCardCollection
                    loaded={thesisLoaded}
                    viewRoute={viewRoute}
                    isEditable={isEditable}
                    fields={fields}
                    list={filteredCards}
                    pagination={pagination}
                    deleteThesis={(id) => deleteThesis(id)}
                />
            }
            cardTitle={cardTitle}
            filters={
                <ThesisFilters
                    filters={filters}
                    removeFilters={() => {
                    setFilters({
                        thesisTitle: new Filters("=", "", "string", "string"),
                        thesisAssignType: new Filters("NONE", "", "radio", "integer"),
                        thesisIntendedEnd: new Filters("NONE", [null, null], "range", "date"),
                        keywords: new Filters("=", [], "includeAll", "string")
                    })
                    }}
                    setFilter={(field, sign, value, pos = null) => setFilter(field, sign, value, pos)} />
            }
            pagination={
                <PaginationWrapper 
                    setPagination={(val) => setPagination(val)}
                    list={filteredCards}
                />
            }
        />
        </>
    )
}