import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Paper } from "@material-ui/core";
import {useEffect, useState} from "react";
import DepartmentModel, {Department} from "../../../DataTypes/Models/Department/Department.model";
import {Add, Delete, Edit} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";
import {DepartmentNewModel} from "./DepartmentNewModel";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {DepartmentEditModel} from "./DepartmentEditModel";
import Typography from "@material-ui/core/Typography";

const columns = [
    { field: 'id', headerName: 'ID', width: 70},
    { field: 'depName', headerName: 'Όνομα', width: 180},
    { field: 'institution', headerName: 'Ινστιτούτο', width: 180,
        valueGetter: (params) => {
            console.log(params)
            return params.row.institution.instName
        }
    },
    {
        field: 'depEmail',
        headerName: 'Email',
        width: 150
    },
    {
        field: 'depPhone',
        headerName: 'Τηλέφωνο',
        width: 100
    },
    {
        field: 'depCity',
        headerName: 'Πόλη',
        width: 100
    },
];

export default function DepartmentControl() {

    const [deps, setDeps] = useState([]);
    const [rowSelected, setRowSelected] = useState(null);

    const [isSaveOpen, setIsSaveOpen] = useState(false);
    const [isDeleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);
    const [deleteSBText, setDeleteSBText] = useState(null);

    const [isEditOpen, setIsEditOpen] = useState(false);


    /**
     *
     * @param {Department[]} rows
     */
    const adaptDBColumns = (rows) => {
        const depsCp = [...deps];
        for (let i=0;i<rows.length;i++) {
            depsCp.push({...rows[i], id: rows[i].depID});
        }
        setDeps(depsCp);
    }

    useEffect(() => {
       DepartmentModel.getAll().then( res => {
           adaptDBColumns(res);
       })
    }, []);

    const deleteDepartment = () => {
        DepartmentModel.delete(rowSelected.depID).then( res => {
            if (res.hasOwnProperty('message')) {
                setDeleteSBText(res.message);
                setDeleteSnackbarOpen(true);
            } else {
                const depCp = [...deps];
                let pos = -1;
                for (let i=0;i<depCp.length;i++) {
                    if (depCp[i].depID === rowSelected.depID) {
                        pos = i;
                        break;
                    }
                }

                depCp.splice(pos, 1);
                setDeps(depCp);
                setRowSelected(null);
            }
        });
    }

    return (
        <>
            <Paper style={{padding: "10px", margin: "15px"}}>
                <Typography variant="h5">Διαχείρηση Τμημάτων</Typography>
            </Paper>
        <Paper elevation={2} style={{margin: "15px"}}>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid onRowSelected={(e) => setRowSelected(e.data)} rows={deps} columns={columns} />
            </div>
        </Paper>
            <Fab onClick={() => setIsSaveOpen(true)} style={{float: 'right', marginRight: "15px"}} color="primary" aria-label="add">
                <Add />
            </Fab>
            <Fab onClick={() => setIsEditOpen(true)} disabled={rowSelected === null} style={{float: 'right', marginRight: "15px"}} color="primary" aria-label="add">
                <Edit />
            </Fab>
            <Fab onClick={() => deleteDepartment()} disabled={rowSelected === null} style={{float: 'right', marginRight: "15px"}} color="primary" aria-label="add">
                <Delete />
            </Fab>
            <DepartmentNewModel onClose={() => setIsSaveOpen(false)} onSave={(dep) => {
                setIsSaveOpen(false);
                setDeps(deps.concat(dep));
            }} isOpen={isSaveOpen} />

            <DepartmentEditModel onSave={(dep) => {
                setIsSaveOpen(false);
                const depCp = [...deps];
                let pos = -1;
                for (let i=0;i<depCp.length;i++) {
                    if (dep.depID === depCp[i].depID) {
                        pos = i;
                        break;
                    }
                }

                depCp[pos] = dep;
                setDeps(depCp);
            }} onClose={() => setIsEditOpen(false)} isOpen={isEditOpen} department={rowSelected} />

            <Snackbar open={isDeleteSnackbarOpen} onClose={() => setDeleteSnackbarOpen(false)}>
                <Alert onClose={() => setDeleteSnackbarOpen(false)} severity="error">
                    {deleteSBText}
                </Alert>
            </Snackbar>
        </>
    );
}