import React, {useEffect, useState} from 'react';
import { CardHeader, Divider, Grid, Card, CardContent, TextField, MenuItem, FormControl, Select, InputLabel, Button, Snackbar, Typography } from '@material-ui/core';
import Util from '../../../Util/Util';
import config from "../../../config";
import { Alert } from '@material-ui/lab';
import TodoList from "../../../DataTypes/Models/TodoList/TodoList";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TodoListItemAccordion from './todoListItem/TodoListItemAccordion';
import { Editor } from '@tinymce/tinymce-react';
import {TodoListMenu} from "./TodoListMenu";
import {Helmet} from "react-helmet";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiCardHeader-title": {
            fontSize: "20px",
        },
        marginLeft: "15px"
    },
}));

export default function CreateThesis() {

    const todoList = new TodoList();
    const classes = useStyles();

    // Form data
    const [formValues, setFormValues] = useState(todoList);
    const [selectedTemplate, setSelectedTemplate] = useState({});

    // Alert message
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);

    const history = useHistory();


    const updateForm = (e, field) => {
        let formValuesCp = Object.assign({}, formValues);
        formValuesCp[field] = e.target.value;
        setFormValues(formValuesCp);
    }

    /**
     * Change the unique identifier of the form
     * entity.
     *
     * @param {Event} e
     * @param {string} field
     * @param {string} uniq
     */
    const updateFormEntity = (e, field, uniq) => {

    }

    const updateDB = () => {

        if (hasErrors()) {
            return;
        }

        let todoList = formValues;

        const jwt_token = localStorage.getItem("jwt_token");
        Util.apiCall(config.server, '/v1/instructor/todo-list', "POST", {todoList, jwt_token, template: selectedTemplate.doListID}).then( (res) => {
            if (!isNaN(res) && res >= 400) {

            } else {
                setIsSuccessOpen(true);
                setTimeout(() => {
                    setIsSuccessOpen(false);
                },3000);

                console.log(parseInt(res));

                todoList.doListID = parseInt(res);
                history.push({pathname: `/dashboard/instructor/todo-list/edit/${parseInt(res)}`, state: {todoList} });
            }
        })
    }

    const hasErrors = () => {
        return formValues.doListTitle === '';
    }

    const onEditorChange = (s) => {
        let formValuesCp = Object.assign({}, formValues);
        formValuesCp["doListDescr"] = s;
        setFormValues(formValuesCp);
    }

    return (
        <Grid container className="mt-2">
            <Helmet>
                <title>ThesisGate - Δημιουργία Εγρασίας</title>
            </Helmet>
            <TodoListMenu breadcrumbItems={
                <Typography>Προσθήκη νέας</Typography>
            }/>

            <Grid item lg={7}>
                
                <Card className={classes.root}>
                    <CardHeader
                        title="Προσθήκη"
                    />
                    <Divider />
                    <CardContent>
                        <Grid container justify="center">
                            <Grid item lg={10}>

                                <Grid container>

                                    <Grid item lg={9}>
                                            <TextField
                                                error={formValues.doListTitle === ''}
                                                required 
                                                id="title"
                                                label="Τίτλος"
                                                value={formValues.doListTitle} 
                                                onChange={(e) => updateForm(e, "doListTitle")}
                                            />
                                    </Grid>

                                    <Grid container className="mt-2">
                                        <Grid item lg={12} className="mt-2">
                                            <Editor
                                                initialValue={formValues.doListDescr}
                                                init={{
                                                height: 350,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat | help'
                                                }}
                                                onEditorChange={onEditorChange}
                                            />
                                        </Grid>
                                    </Grid>

                
                                    <Grid container style={{marginTop: "35px"}}>

                                        <div className="mt-2">
                                            <Grid item lg={12} className="mt-2">
                                                <Button onClick={() => updateDB()} variant="contained" color="primary">ΑΠΟΘΗΚΕΥΣΗ</Button>
                                            </Grid>
                                        </div>

                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Snackbar open={isSuccessOpen}>
                <Alert severity="success">
                    Η διατριβή σας έχει αποθηκευτεί
                </Alert>
            </Snackbar>

        </Grid>
    )
}