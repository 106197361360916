import config from "../../config";
import TodoList from "../../DataTypes/Models/TodoList/TodoList";
import Util from "../../Util/Util";

export default async function TodoListContainer(route = "/todo-lists") {
    let todoLists = [];
    const jwt_token = localStorage.getItem("jwt_token");
    await Util.apiCall(config.server, `/v1/instructor${route}?jwt_token=${jwt_token}`, "GET").then( res => {
        res.map( item => {
            const doList = new TodoList(
                item.doListID,
                item.instructor,
                item.thesisID,
                item.doListTitle,
                item.doListDescr,
                item.doListWhen ? item.doListWhen.date : null,
                item.doListItems
            );
            todoLists.push(doList);
        });
    });
    return todoLists;
}