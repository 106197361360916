import React from 'react';
import {ListItem, ListItemIcon, ListItemText, List, ListSubheader, MenuList } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import WorkIcon from '@material-ui/icons/Work';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SchoolIcon from '@material-ui/icons/School';
import PeopleIcon from '@material-ui/icons/People';

const profileSettings = [
    {
        text: "Προφιλ",
        href: "/dashboard/admin/profile",
        index: 0,
        icon: <AccountCircleIcon />
    },
]

const itemList = [
    {
        text: "Ινστιτούτα",
        href: "/dashboard/admin/institutions",
        index: 0,
        icon: <AccountBalanceIcon />
    },
    {
        text: "Τμήματα",
        href: "/dashboard/admin/departments",
        index: 1,
        icon: <SchoolIcon />
    },
    {
        text: "Χρήστες",
        href: "/dashboard/admin/users",
        index: 1,
        icon: <PeopleIcon />
    },
];

export default function AdminMenu() {
    const history = useHistory();

    return (

        <MenuList
            style={{
                borderRight:'1px solid #e0e0e0',
                maxHeight:'100vh',
                height: '100vh',
                backgroundColor: '#fff',
                paddingRight: '14px',
            }}
        >
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                style={{position:'relative !important'}}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Προφιλ
                    </ListSubheader>
                }
            >

                {
                    profileSettings.map( (item, index) => {
                        return (
                            <ListItem button key={index}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText onClick={() => history.push(item.href)} primary={item.text} />
                            </ListItem>
                        )
                    })
                }
            </List>

            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                style={{position:'relative !important'}}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader-2">
                        Διαχείρηση
                    </ListSubheader>
                }
            >

                {
                    itemList.map( (item, index) => {
                        return (
                            <ListItem button key={index}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText onClick={() => history.push(item.href)} primary={item.text} />
                            </ListItem>
                        )
                    })
                }
            </List>

        </MenuList>
    )
}