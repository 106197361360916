import React from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Button } from "@material-ui/core";

export default function AcceptThesisDialog({open, handleCancel, handleAccept}) {

    return (
        <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Θέλετε σίγουρα να επιλέξετε αυτήν την πτυχιακή?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Σε περίπτωση αποδοχής οι υπόλοιπες αιτήσεις για διατριβές ακυρόνονται! 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Ακύρωση
          </Button>
          <Button onClick={handleAccept} color="primary" autoFocus>
            Αποδοχή
          </Button>
        </DialogActions>
      </Dialog>
    )
}