import Thesis from "../Thesis/Thesis";
import Student from "../Users/Student";
import Util from "../../../Util/Util";
import config from "../../../config";

export default class TodoList {
    
    /**
     * 
     * @param {int} doListID 
     * @param {Student} instructor 
     * @param {Thesis} thesisID
     * @param {string} doListTitle 
     * @param {string} doListDescr 
     * @param {string} doListWhen 
     */
    constructor(
        doListID = null, 
        instructor = null, 
        thesisID = null, 
        doListTitle = null, 
        doListDescr = null,
        doListWhen = null,
        doListItems = []
    ) {
        if (thesisID === null) {
            thesisID = new Thesis();
            thesisID.thesisID = -1;
        }

        this.doListID = doListID;
        this.instructor = instructor;
        this.thesisID = thesisID;
        this.doListTitle = doListTitle;
        this.doListDescr = doListDescr;
        this.doListWhen = doListWhen;
        this.doListItems = doListItems;
    }
}



/**
 *
 * @param {number} id
 */
export function deleteTodoListItem(id)
{
    const jwt_token = localStorage.getItem("jwt_token");
    return Util.apiCall(config.server, `/v1/instructor/todo-list/${id}`, "DELETE", {jwt_token});
}

export function getTotalDuration(todoItem) {
    let totalDur;
    if (todoItem.dliDurationUnit === "weeks") {
        totalDur = 7 * todoItem.dliDuration;
    } else if (todoItem.dliDurationUnit === "months") {
        totalDur = 30  *todoItem.dliDuration;
    } else {
        totalDur = todoItem.dliDuration;
    }
    return totalDur;
}


export function getDoListItemCompletionDays(doListItems) {
    const doListItemDays = [];
    let days = 0;
    doListItems.map( doItem => {
        // Add total days that doListItems need for completion.
        days += getTotalDuration(doItem);
        doListItemDays.push(days);
    })
    return doListItemDays;
}

export function getCompletedDoListItemsDuration(doListItems) {
    let days = 0;
    doListItems.map( doItem => {
        // Add total days that doListItems need for completion.
        if (doItem.dliStudentOK === 1 && doItem.dliInstructorOK === 1) days += getTotalDuration(doItem);
    })
    return days;
}

export function copyTodoList(id, thesisID) {
    const jwt_token = localStorage.getItem("jwt_token");
    return Util.apiCall(config.server, `/v1/instructor/todo-list/${id}/copy/${thesisID}`, "POST", {jwt_token});
}


export function getAllTodoLists() {
    const jwt_token = localStorage.getItem("jwt_token");
    return Util.apiCall(config.server, `/v1/user/todo-lists?jwt_token=${jwt_token}`, "GET");
}