import React, {useEffect, useState} from 'react';
import {
    CardContent,
    CardActions,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    RadioGroup,
    Radio,
    Typography,
    TextField
} from '@material-ui/core';
import TagControl from "../../../TagControl/TagControl";
import KeywordsAPI from "../../../../DataTypes/API/KeywordsAPI";
import {Autocomplete} from "@material-ui/lab";

export default function ThesisFilters(props) {

    const [keywords, setKeywords] = useState();
    const [selectedKeywords, setSelectedKeywords] = useState();

    useEffect(() => {
        let keywordAPI = new KeywordsAPI();
        keywordAPI.getAll().then( res => {
            setKeywords(res.map(item => {
                return {text: item.keyword, type: "exists"}
            }))
        })
    }, []);

    return (
        <div style={{margin: "20px"}}>
            <CardContent style={{padding: "10px"}}>
                <Grid container style={{marginTop: "10px"}}>
                    <FormControl component="fieldset" style={{marginTop: "15px"}}>
                        <FormLabel component="legend">Τίτλος διατριβής</FormLabel>
                        <TextField
                            id="nothing"
                            onChange={(e) =>
                                props.setFilter("thesisTitle", "=", e.currentTarget.value)
                            }
                            defaultValue={undefined}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
            <Grid container style={{marginTop: "20px"}}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Ρυθμός ολοκλήρωσης</FormLabel>
                    <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        onChange={(e) => {
                            props.setFilter("thesisAssignType", "=", e.currentTarget.value)}
                        }
                        value={props.filters.thesisAssignType.value}>
                        <FormControlLabel value="immediate" control={<Radio size="small" />} label="Άμεσα" />
                        <FormControlLabel value="onDeadline" control={<Radio size="small" />} label="Στην προθεσμία" />
                    </RadioGroup>
                </FormControl>
            </Grid>
                <Grid container style={{marginTop: "10px"}}>
                    <FormControl component="fieldset" style={{marginTop: "15px"}}>
                        <FormLabel component="legend">Προθεσμία για ενδιαφέρον</FormLabel>
                        <Grid container spacing={3} style={{marginTop: "2px"}}>
                            <Grid item xs={6}>
                                <TextField
                                    id="date"
                                    label="Από"
                                    type="date"
                                    defaultValue={undefined}
                                    onChange={(e) =>
                                        props.setFilter("thesisIntendedEnd", "NONE", e.currentTarget.valueAsDate, 0)
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="date"
                                    label="Μέχρι"
                                    type="date"
                                    onChange={(e) =>
                                        props.setFilter("thesisIntendedEnd", "NONE", e.currentTarget.valueAsDate, 1)
                                    }
                                    defaultValue={undefined}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>

                    </FormControl>
                </Grid>
                <Grid container style={{marginTop: "10px"}}>
                    <FormControl component="fieldset" style={{marginTop: "15px"}}>
                        <FormLabel component="legend">Λέξεις Κλειδιά</FormLabel>
                        <Autocomplete
                            id="combo-box-demo"
                            blurOnSelect
                            options={keywords ? keywords : []}
                            multiple
                            limitTags={4}
                            getOptionLabel={(option) => option.text}
                            style={{ width: 200, fontSize: "11px" }}
                            renderInput={(params) => <TextField {...params} label="Keywords" variant="outlined" />}
                            selectOnFocus
                            onSelect={(e) => {}}
                            onChange={(e, value) => {
                                console.log(value);
                                if (Array.isArray(value)) {
                                    props.setFilter("keywords", "=", value.map( item => item.text))
                                }
                            }}
                        />
                    </FormControl>
                </Grid>

        </CardContent>
        <Divider />
        <CardActions>
            <Typography color="primary" onClick={() => props.removeFilters()}>Αφαίρεση φίλτρων</Typography>
        </CardActions>
    </div>
    )
}