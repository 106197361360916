import config from "../../config";
import Thesis from "../../DataTypes/Models/Thesis/Thesis";
import Util from "../../Util/Util";

export default async function ThesisDataContainer(route = "instructor/theses") {
    
    let theses = [];
    const jwt_token = localStorage.getItem("jwt_token");
    await Util.apiCall(config.server, `/v1/${route}?jwt_token=${jwt_token}`, "GET").then( res => {
        console.log(res);
        res.map( item => {
            let thesis = new Thesis(
                item.thesisID,
                item.thesisTitle,
                item.thesisDescr,
                item.thesisAssignType,
                item.thesisIsPublicByInst ? "1" : "0",
                item.thesisIsPublicByStu ? "1": "0",
                item.thesisIntDeadline != null ? item.thesisIntDeadline.date.split(" ")[0] : null,
                item.thesisStartDate != null ? item.thesisStartDate.date.split(" ")[0] : null,
                item.thesisIntendedEnd != null ? item.thesisIntendedEnd.date.split(" ")[0] : null,
                item.thesisEndDate != null ? item.thesisEndDate.date.split(" ")[0] : null,
                item.thesisMark,
                item.thesisAbstract,
                item.student,
                item.doList,
                item.prefersTheses,
                item.keywords,
                item.instructor,
                item.attachment,
                item.featuredImageID
            );
            theses.push(thesis);
        });
    });
    return theses;
}