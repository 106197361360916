import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

export default function WarningAlert({isOpen, message}) {
    return (
        <Snackbar open={isOpen}>
            <Alert severity="warning">
                {message}
            </Alert>
        </Snackbar>
    )
}