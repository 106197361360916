import '../assets/css/style.css'
import Util from '../Util/Util';
import config from '../config';
import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    TextField,
    Typography
} from "@material-ui/core";
import {Alert} from "@material-ui/lab"
import { useHistory } from 'react-router-dom';
import DepartmentModel from "../DataTypes/Models/Department/Department.model";
import {Helmet} from "react-helmet";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


export default function Register(props) {

    const [username, setUsername] = useState('');
    const [uName, setUName] = useState('');
    const [uSurname, setUSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [isLoggingIn, setIsLogginIn] = useState(false);

    const [departments, setDepartments] = useState([]);
    const [chosenDepartment, setChosenDepartment] = useState(null);

    const history = useHistory();

    const [accountAlreadyExists, setAccountAlreadyExists] = useState(false);

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        DepartmentModel.getAll().then( res => {
            setDepartments(res);
            if (Array.isArray(res) && res.length > 0) {
                setChosenDepartment(res[0]);
            }
        })
    }, [])

    const registerAction = () => {

        if(checkForErrors()) {
            return
        }

        const userCredentials = {
            username,
            password,
            email,
            uName,
            uSurname
        }

        setIsLogginIn(true);
        Util.apiCall(config.server, "/register", "POST", {...userCredentials, department: chosenDepartment.depID}).then( res => {
            if (res.hasOwnProperty('status') && res.status === 1) {
                setIsLogginIn(false);
                setAccountAlreadyExists(true);
                return;
            }
            history.push(`/authenticateEmail/${email}`);
        });
    }

    const checkForErrors = () => {

        const errors = [];
        if (uName.length === 0) {
            errors.push(addError("Όνομα είναι απαραίτητο"));
        }

        if (uSurname.length === 0) {
            errors.push(addError("Επίθετο είναι απαραίτητο"));
        }

        if (username.length === 0) {
            errors.push(addError("Όνομα χρήστη είναι παραίτητο"));
        }

        if (password.length === 0 || passwordConfirmation.length === 0) {
            errors.push(addError("Ο κωδικός είναι παραίτητο"));
        }

        setErrors(errors);

        if (!Util.isEmail(email)) {
            return true;
        }

        if (password !== passwordConfirmation) {
            return true;
        }

        return errors.length !== 0;
    }

    const addError = (text) => {
        return <Typography className="mt-2">{text}</Typography>
    }

    const passwordsMatch = () => {
        if (password.length > 0 && 
            passwordConfirmation.length > 0 && 
            password !== passwordConfirmation
        ) {
            return false;    
        }
        return true;
    }

    return (
        <Grid container justify="center" style={{marginTop: '30vh'}}>
            <Helmet>
                <title>ThesisGate - Εγγραφή</title>
            </Helmet>
            <Grid xs={12} md={4}>
                <Card style={{padding: '15px'}}>
                    <CardHeader title="ThesisNET" />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    style={{width:'100%'}}
                                    placeholder="Όνομα χρηστη"
                                    value={username}
                                    onChange={(e) => {setUsername(e.currentTarget.value)}}
                                    label="Όνομα χρηστη"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    style={{width:'100%'}}
                                    placeholder="Όνομα"
                                    value={uName}
                                    onChange={(e) => {setUName(e.currentTarget.value)}}
                                    label="Όνομα"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    style={{width:'100%'}}
                                    id="standard-password-input2"
                                    type="text"
                                    placeholder="Επίθετο"
                                    value={uSurname}
                                    onChange={(e) => {setUSurname(e.currentTarget.value)}}
                                    label="Επίθετο"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    style={{width:'100%'}}
                                    id="standard-password-input"
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => {setEmail(e.currentTarget.value)}}
                                    label="Email"
                                />
                                {
                                    !Util.isEmail(email) && email.length > 3 ?
                                        <Grid item xs={12}>
                                        <Alert severity="warning">Παρακαλώ δώστε ένα σωστό email!</Alert>
                                        </Grid>: <></>
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    style={{width:'100%'}}
                                    id="standard-password-input"
                                    type="password"
                                    placeholder="Κωδικός πρόσβασης"
                                    value={password}
                                    onChange={(e) => {setPassword(e.currentTarget.value)}}
                                    label="Κωδικός πρόσβασης"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    style={{width:'100%'}}
                                    id="standard-password-input"
                                    type="password"
                                    placeholder="Επαλήθευση Κωδικού πρόσβασης"
                                    value={passwordConfirmation}
                                    onChange={(e) => {setPasswordConfirmation(e.currentTarget.value)}}
                                    label="Επαλήθευση Κωδικού πρόσβασης"
                                />
                            </Grid>
                            <Grid item xs={12} style={{marginTop: "20px"}}>
                                <FormControl style={{width: "100%"}}>
                                    <label>Τμήμα</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={chosenDepartment ? chosenDepartment.depID : null}
                                        onChange={(e) => {
                                            let chosenDep = null;
                                            const id = e.target.value;
                                            for (let i=0;i<departments.length;i++) {
                                                if (departments[i].depID === id) {
                                                    chosenDep = departments[i];
                                                }
                                            }

                                            setChosenDepartment(chosenDep);
                                        }}
                                    >
                                        {
                                            departments.length > 0 ?
                                                departments.map( department => {
                                                    return (
                                                        <MenuItem value={department.depID}>{department.depName}</MenuItem>
                                                    )
                                                }) : <></>
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                !passwordsMatch() ?
                                    <Grid item xs={12}>
                                        <Alert severity="error">Οι κωδικοί που βάλατε δεν ταιριάζουν!</Alert>
                                    </Grid>
                                        : <></>
                            }
                            {
                                accountAlreadyExists ?
                                    <Grid item xs={12}>
                                        <Alert severity="error">Ο χρήστης υπάρχει ήδη!</Alert>
                                    </Grid>
                                    : <></>
                            }
                        </Grid>

                        <Divider />

                        <Grid justify="center">
                            <Grid item span={12} className="flex justify-content-center">
                                <div className="flex justify-content-center mt-2">
                                    <Button variant="contained" color="primary" onClick={() => registerAction()} disableElevation>
                                        {
                                            isLoggingIn ?
                                                <CircularProgress /> :
                                                <span>Εγγραφή</span>
                                        }
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        {
                            errors.map( (item, index) => {
                                return <span key={index}>{item}</span>;
                            })
                        }

                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

