import { Breadcrumbs, Card, CardContent } from '@material-ui/core';
import React from 'react';

export default function BreadcrumbTemplate(props) {
    return (
        <Card style={{margin:'15px'}}>
            <CardContent style={{paddingBottom: '15px'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    {
                        props.items.map( item => {return item})
                    }
                </Breadcrumbs>
            </CardContent>
        </Card>
    )
}