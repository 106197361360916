import User from "./User";
import Util from "../../../Util/Util";
import config from "../../../config";

export default class Instructor extends User {
    constructor(
        username,
        uName,
        uSurname,
        uEmail,
        uIsPublic = 0,
        cvFilename = null,
        instrRank = null
    ) {
        super(username, uName, uSurname, uEmail, uIsPublic, cvFilename);
        this.instrRank = instrRank;
    }
}

export class InstructorModel {

    static instrRank = {
        prof: "professor",
        assoc: "associate",
        assis: "assistant",
        lec: "lecturer",
        adj: "adjunct"
    }

    static getAll() {
        const jwt_token = localStorage.getItem('jwt_token');
        return Util.apiCall(config.server, `/v1/admin/users/ins?jwt_token=${jwt_token}`, "GET");
    }

    static save(instr, depID) {
        const jwt_token = localStorage.getItem('jwt_token');
        return Util.apiCall(config.server, `/v1/admin/user/ins`, "POST", {jwt_token, ...instr, depID});
    }

    static delete(instID) {
        const jwt_token = localStorage.getItem('jwt_token');
        return Util.apiCall(config.server, `/v1/admin/user/${instID}`, "DELETE", {jwt_token});
    }
}