import React, {useEffect} from 'react';
import authenticate from "../Util/authenticate";
import UserValidator from "../DataTypes/Authentication/UserValidator";
import {useHistory} from "react-router-dom";

export default function UserAuthentication(props) {

    const history = useHistory();

    useEffect(() => {
        authenticate(props.userType).then( res => {
            const validator = new UserValidator(res.type, res.isJWTValid, res.isUserActive, res.email);
            if (validator.type === null || validator.isJWTValid == 0) {
                history.push('/login');
            }
            if (validator.isJWTValid == 1 && validator.isUserActive == 0) {
                history.push(`/authenticateEmail/${validator.email}`)
            }
            if (validator.isJWTValid == 1 && validator.isUserActive == 1 && window.location.pathname === "/") {
                if (validator.type === "ins") {
                    history.push("/dashboard/instructor/profile");
                } else if (validator.type === "stu") {
                    history.push("/dashboard/student/profile");
                }
            }
        })
    }, []);

    return(
        <>
            {props.children}
        </>
    )
}