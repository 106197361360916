import React, {useState} from 'react';
import {
    Card,
    List,
    GridList,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    CardActionArea,
    Drawer,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography, Fab
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import propTypes from "prop-types";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {ThesisMenu} from "../thesis/ThesisMenu";
import {Helmet} from "react-helmet";

/**
 * A template for overviewing items.
 * @param {*} props 
 */
export default function OverviewTemplate(props) {

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <Grid container className="mt-2">
            <Helmet>
                <title>ThesisGate - Εργασίες</title>
            </Helmet>
            <Grid item lg={12} md={12}>

                {
                    props.breadcrumb
                }

                <Fab variant="extended" color="primary" style={{position: "absolute", bottom: "20px", right: "20px"}} size="medium" onClick={() => setIsDrawerOpen(true)}>
                    <FilterListIcon />
                    Φίλτρα
                </Fab>

                    <GridList style={{height:'70vh', width:'100%'}}>
                        {props.list}
                    </GridList>

                <Drawer anchor="right" open={isDrawerOpen} onClose={() => {setIsDrawerOpen(false)}}>
                    {props.filters}
                </Drawer>

            </Grid>
            
            {props.pagination}

        </Grid>
    )
}

OverviewTemplate.propTypes = {
    /**
     * Filters are related to the props.list where
     * filters are there to filter the list items.
     */
    filters: propTypes.element,

    /**
     * Title of the cards
     */
    cardTitle: propTypes.string,

    /**
     * Array of items that are going to be 
     * rendered on the overview
     */
    list: propTypes.array,

    /**
     * The pagination element is used to 
     * provide easy nagivation between pages.
     */
    pagination: propTypes.element
}