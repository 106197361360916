import {Grid, TextField} from "@material-ui/core";
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Alert} from "@material-ui/lab";
import User, {updatePassword} from "../../DataTypes/Models/Users/User";
import Snackbar from "@material-ui/core/Snackbar";

export default function PasswordChanger() {

    const [isModelOpen, setIsModelOpen] = useState(false);

    const [oldPass, setOldPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [newPassConfirm, setNewPassConfirm] = useState('');

    const [warningMessage, setWarningMessage] = useState('');
    const [isWarningOpen, setIsWarningOpen] = useState(false);

    const arePassesTheSame = () => {
        if (newPass.length < 4) return true;
        return newPass === newPassConfirm;
    }

    const updateUserPassword = () => {
        updatePassword(oldPass, newPass).then( res => {
            if (res.hasOwnProperty('state')) {
                if (res.state === User.WrongPassword) {
                    setWarningMessage('Λάθος κωδικός πρόσβασης');
                    setIsWarningOpen(true);
                }
            } else {
                setIsModelOpen(false)
            }
        });
    }

    return (
        <>
            <Button onClick={() => setIsModelOpen(true)} variant="contained">Αλλαγή κωδικού</Button>
            <Dialog style={{padding: "10px"}} open={isModelOpen} onClose={() => setIsModelOpen(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Αλλαγή κωδικού</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item lg={7} xs={12}>
                            <TextField
                                fullWidth
                                onChange={(e) => {setOldPass(e.target.value)}}
                                id="old-pass"
                                value={oldPass}
                                type="password"
                                label={'Παλιός Κωδικος'}
                                required
                            />
                        </Grid>
                        <Grid item lg={7} xs={12}>
                            <TextField
                                fullWidth
                                onChange={(e) => {setNewPass(e.target.value)}}
                                id="new-pass"
                                value={newPass}
                                type="password"
                                label={'Νέος Κωδικος'}
                                required
                            />
                        </Grid>
                        <Grid item lg={7} xs={12}>
                            <TextField
                                fullWidth
                                onChange={(e) => {setNewPassConfirm(e.target.value)}}
                                id="new-pass-confirm"
                                value={newPassConfirm}
                                type="password"
                                label={'Νέος Κωδικος Επαλήθευση'}
                                required
                            />
                            {
                                !arePassesTheSame() ?
                                    <Alert severity="error">Οι κωδικού δεν είναι ίδιοι!</Alert> : <></>
                            }
                        </Grid>
                    </Grid>
                    <Grid container className="mt-2">
                        <Button disabled={!arePassesTheSame() || newPass.length < 4}
                                onClick={() => {
                                    updateUserPassword();
                                }}
                                variant="contained">Αποθήκευση</Button>
                    </Grid>

                </DialogContent>
            </Dialog>

            <Snackbar open={isWarningOpen} autoHideDuration={6000} onClose={() => setIsWarningOpen(false)}>
                <Alert onClose={() => setIsWarningOpen(false)} severity="warning">
                    {warningMessage}
                </Alert>
            </Snackbar>
        </>
    )
}
