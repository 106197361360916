import Util from "../../../Util/Util";
import config from "../../../config";

export default class User {

    static WrongPassword = 1;

    constructor(
        username,
        uName,
        uSurname,
        uEmail,
        uIsPublic = 0,
        cvFilename = null,
        keywords = []
    ) {
        this.username = username;
        this.uName = uName;
        this.uSurname = uSurname;
        this.uEmail = uEmail;
        this.uIsPublic = uIsPublic;
        this.cvFilename = cvFilename;
        this.keywords = keywords;
    }
}

export function updatePassword(oldpass, newpass) {
    const jwt_token = localStorage.getItem('jwt_token');
    return Util.apiCall(config.server, `/v1/user/update-password`, 'PUT',  {jwt_token, oldpass, newpass});
}