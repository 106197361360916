import React, {useEffect, useState} from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Popover,
    Chip
} from '@material-ui/core';
import { Skeleton} from "@material-ui/lab";
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ThesisDataContainer from '../../DataContainers/ThesisDataContainer';
import SingleTemplate from '../templates/SingleTemplate';
import ReactHtmlParser from 'react-html-parser';
import StarsIcon from '@material-ui/icons/Stars';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import {addNewPrefersThesis, removePrefersThesis} from "../../../DataTypes/Models/PrefersThesis";
import SuccessAlert from "../../RootScreenLevelInformation/SuccessAlert";
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import {
    assingStudent,
    updateThesisMark
} from '../../../DataTypes/Models/Thesis/Thesis';
import WarningAlert from '../../RootScreenLevelInformation/WarningAlert';
import TodoListItem from "../../Student/MyThesis/TodoListItem";
import ThesisEpilogue from "./parts/ThesisEpilogue";
import {
    getCurrentDoListItemPercentage,
    getCurrentUserTodoItem, getStartAndEndDateOfDoListItem
} from "../../../DataTypes/Models/TodoListItem/TodoListItem";
import {getAllTodoLists} from "../../../DataTypes/Models/TodoList/TodoList";
import DoListItemQuickSelection from "./parts/SingleThesis/DoListItemQuickSelection";
import DeleteDoListItem from "./parts/SingleThesis/DeleteDoListItem";
import {Edit, Face, Send} from "@material-ui/icons";
import {Link} from "react-router-dom";
import Timeline from "@material-ui/lab/Timeline";
import Fab from "@material-ui/core/Fab";
import EventIcon from '@material-ui/icons/Event';
import ImageRetriever from "../../../DataTypes/ImageRetriever";
import NonTimelineTodoList from "./parts/NonTimelineTodoList";

export default function SingleThesis({userType, dataContainer}) {

    const [thesis, setThesis] = useState({});
    const [user, setUser] = useState({});

    const [anchoredEl, setAnchoredEl] = useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const [isSuccessOpen, setIsSuccessOpen] = useState(false);
    const [isWraningOpen, setIsWarningOpen] = useState(false);

    const [doLists, setDoLists] = useState([]);

    const [prefOrder, setPrefOrder] = useState(null);

    // Id of the currently edditing Thesis
    const {id} = useParams();

    useEffect(() => {
        if (Object.keys(thesis).length === 0) setRealThesis();

        if (Object.keys(user).length === 0) {
            dataContainer().then( user => {
                setUser(user[0])
            });
        }

        if (doLists.length === 0) {
            getAllTodoLists().then( res => {
                if (res.length > 0) setDoLists(res);
            })
        }

        if (!anchoredEl) {
            let el = document.getElementById("anchoredEl");
            setAnchoredEl(el);
        }
    }, []);

    const getOrderedDoListItemsThesis = (thesis) => {
        return Object.assign({}, thesis);
    }

    const setRealThesis = () => {
            ThesisDataContainer(`user/thesis/${id}`).then( thesis => {
                setThesis(getOrderedDoListItemsThesis(thesis[0]));
            });
    }

    const hasUserPrefferedThisThesis = () => {
        if (Object.keys(thesis).length === 0) {
            return false;
        }

        let userPrefersThesis = false;

        if (thesis.hasOwnProperty('prefersTheses')) {
            thesis.prefersTheses.map( pref => {
                if (pref.username === user.username) {
                    userPrefersThesis = true;
                }
            });
        }

        return userPrefersThesis;
    }

    const isVisible = () => {
        if (!thesis.student) {
            return thesis.thesisIsPublicByInst === "1"
        }

        return thesis.thesisIsPublicByStu === "1";
    }

    const thesisOneStepBeforeFinish = () => {
        if (!thesis.doList || thesis.thesisMark) return false;

        let finished = true;
        thesis.doList.doListItems.map( doList => {
            if (
                doList.dliStudentOK === 0 ||
                doList.dliStudentOK === null ||
                doList.dliInstructorOK === 0 ||
                doList.dliInstructorOK === null
            ) {
                finished = false;
            }
        });

        return finished;
    }

    const sendUserPrefers = () => {
        addNewPrefersThesis(id, parseInt(prefOrder)).then( res => {
            setIsPopoverOpen(false);
            console.log(res);
            if (typeof res === "object" && res.hasOwnProperty("status") && res.status === 400) {
                setIsWarningOpen(true);
                setTimeout(() => {
                    setIsWarningOpen(false);
                }, 4000);
            } else {
                setIsSuccessOpen(true);
                setTimeout(() => {
                    setIsSuccessOpen(false);
                }, 800);
    
                const thesisCp = Object.assign({}, thesis);
                thesisCp.prefersTheses.push({student: user.username});
                setThesis(thesisCp);
            }
        });
    }

    const removeUserPrefers = () => {
        removePrefersThesis(id).then( res => {
            let thesisCp = Object.assign({}, thesis);
            thesisCp.prefersTheses = [];
            setThesis(thesisCp);

            let el = document.getElementById("anchoredEl");
            setAnchoredEl(el);
        })
    }

    const setStudentOnThesis = (student, thesisID, isAssigned = true) => {
        assingStudent(student, thesisID, isAssigned ? 1 : 0).then( res => {
            const thesisCp = Object.assign({}, thesis);
            thesisCp.prefersTheses = thesisCp.prefersTheses.map( stu => {
                if (stu.students.username === student) {
                    stu.isAssigned = isAssigned;
                }
                return stu;
            })
            setThesis(thesisCp);
        })
    }

    const getTotalDuration = (todoItem) => {
        let totalDur;
        if (todoItem.dliDurationUnit === "weeks") {
            totalDur = 7 * todoItem.dliDuration;
        } else if (todoItem.dliDurationUnit === "months") {
            totalDur = 30  *todoItem.dliDuration;
        } else {
            totalDur = todoItem.dliDuration;
        }
        return totalDur;
    }

    const updateTodoItem = (todoItem) => {
        const doItems = [...thesis.doList.doListItems];

        let pos = -1;
        for (let i=0;i<doItems.length;i++) {
            if (doItems[i].dliID === todoItem.dliID) {
                pos = i;
            }
        }

        const thesisCp = Object.assign({}, thesis);
        thesisCp.doList.doListItems[pos] = todoItem;

        setThesis(thesisCp);
    }
    
    let isUserAssigned = false;

    if (thesis.hasOwnProperty("prefersTheses") && Array.isArray(thesis.prefersTheses)) {
        thesis.prefersTheses.map( (stu) => {
            if (stu.isAssigned) isUserAssigned = stu.uName + " " + stu.uSurname;
        });
    }

    // Get end date for each one.
    const doListItemDays = [];

    if (thesis.hasOwnProperty('doList') && thesis.doList) {
        thesis.doList.doListItems.map((doItem, index) => {
            let days = 0;
            doListItemDays.map( listDays => {
                days += listDays;
            })
            days += getTotalDuration(doItem);
            doListItemDays.push(days);
        })
    }

    return (
        <SingleTemplate
            menu={
                null
            }
            header={
                <>

                    <Grid item lg={12}>
                        <img src={ImageRetriever.retrieve(thesis.featuredImageID, "cover")} style={{width: "100%", height: "250px", objectFit: "cover"}} />
                    </Grid>

                </>
            }
            content={
                Object.keys(thesis).length > 0 ?
                <>
                    <Typography variant="h5">{thesis.thesisTitle}</Typography>
                    <span>Του καθηγητή <span style={{color: "blue"}}>{thesis.instructor.uName} {thesis.instructor.uSurname}</span></span>

                    <Grid container spacing={2}>
                        <Grid item lg={12}>
                            <div style={{height: "35vh", overflow: "auto"}}>
                                <Typography>{ReactHtmlParser(thesis.thesisDescr)}</Typography>
                            </div>
                        </Grid>
                        <Card>

                        </Card>
                        <Grid item lg={12}>
                            <Grid container className="mt-2">
                                {
                                    thesis.student !== null && thesis.student !== "" ?
                                        <Grid item lg={12}>
                                            <Typography>
                                                Την έχει αναλάβει {thesis.student.uName + ' ' + thesis.student.uSurname}
                                            </Typography>
                                        </Grid> : <></>
                                }
                            </Grid>
                        </Grid>
                        {
                            thesis.student === "" || thesis.student === null ?
                            <>
                                {
                                    userType === "ins" || userType === "instructor" ?
                                        <Grid item lg={6}>
                                            <Grid item lg={12}>
                                                <Card
                                                    style={{maxHeight: "400px"}}
                                                    title="Επιθυμούν να αναλάβουν την διατριβή:"
                                                >
                                                    <CardContent>
                                                        {
                                                            thesis.prefersTheses.length > 0 ?
                                                                thesis.prefersTheses.filter(prefersTheses => prefersTheses.isAssigned == 0 || prefersTheses.isAssigned === false).map( stu => {
                                                                    return (
                                                                        <Typography color="primary">
                                                                            {stu.uName} {stu.uSurname} ({stu.prefOrder})
                                                                            {
                                                                                userType !== "student" ?
                                                                                    <Button
                                                                                        size="small"
                                                                                        style={{marginLeft: "10px"}}
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        onClick={() => {setStudentOnThesis(stu.username, thesis.thesisID)}}
                                                                                        disableElevation
                                                                                    >Ανάθεση</Button> : <></>
                                                                            }
                                                                        </Typography>
                                                                    )
                                                                }) : <></>
                                                        }
                                                        {
                                                            thesis.prefersTheses.length > 0 ?
                                                                thesis.prefersTheses.filter(prefersThesis => prefersThesis.isAssigned == 1 || prefersThesis.isAssigned === true).map( stu => {
                                                                    return (
                                                                        <Typography color="primary">
                                                                            {stu.uName} {stu.uSurname} ({stu.prefOrder})
                                                                            {
                                                                                userType !== "student" ?
                                                                                    <Button
                                                                                        size="small"
                                                                                        style={{marginLeft: "10px"}}
                                                                                        variant="contained"
                                                                                        color="secondary"
                                                                                        onClick={() => {setStudentOnThesis(stu.username, thesis.thesisID, false)}}
                                                                                        disableElevation
                                                                                    >Ακύρωση</Button> : <></>
                                                                            }
                                                                        </Typography>
                                                                    )
                                                                }) : <></>
                                                        }
                                                        {
                                                            thesis.prefersTheses.length === 0 && thesis.student === null ?
                                                                <Typography variant="p">Κανείς δεν δήλωσε ενδιαφέρον</Typography> : <></>
                                                        }
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid> : <></>
                                }
                            </> : <></>
                            }

                        <div style={{margin: "10px"}}>
                            <Chip
                                variant="secondary"
                                style={{marginRight: "10px"}}
                                icon={<AccessAlarmIcon />}
                                label={`${thesis.thesisAssignType === "immediate" ? "Άμεση ολοκλήρωση" : "Ολοκλήρωση στο προκαθορισμένο χρόνο"}`}
                                clickable
                                color="primary"
                            />
                            <Chip
                                variant="secondary"
                                icon={<EventIcon />}
                                label={`Προθεσμία: ${new Date(thesis.thesisIntDeadline).toLocaleDateString('el-GR')}`}
                                clickable
                                color="primary"
                            />
                        </div>

                        {
                            userType === "stu" || userType === "student" ?
                                <>
                                <Popover
                                    open={isPopoverOpen}
                                    onClose={() => setIsPopoverOpen(false)}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    anchorEl={anchoredEl}
                                >
                                    <Card>
                                        <CardContent>
                                            <TextField
                                                id="standard-basic"
                                                label="Βαθμός προταιρεότητας"
                                                helperText="1 = Ανώτερος και 100 = κατώτερος"
                                                value={prefOrder}
                                                onChange={
                                                    (e) => {
                                                        setPrefOrder(e.currentTarget.value)
                                                    }}
                                            />
                                        </CardContent>
                                        <CardContent>
                                            <Button endIcon={<Send />} onClick={() => sendUserPrefers()} variant="contained" color="primary">Αποστολή</Button>
                                        </CardContent>
                                    </Card>
                                </Popover>


                                <Grid item lg={12}>
                                    <Button
                                        onClick={(e) => {
                                            setAnchoredEl(e.currentTarget);
                                            setIsPopoverOpen(!isPopoverOpen)
                                        }}
                                        style={{float: "right"}}
                                        variant="outlined"
                                        color="secondary"
                                        id="anchoredEl"
                                        startIcon={<StarsIcon />}
                                    >
                                        Δήλωσε ενδιαφέρον!
                                    </Button>
                                </Grid>
                                </> : <></>
                        }

                        <WarningAlert
                            isOpen={isWraningOpen}
                            message="Κάποιος καθηγητής έχει αποδεχτεί το αίτημα σας. Πρέπει να αποδεχτείτε η να απορρίψετε εκείνο το αίτημα πριν προχωρήσετε!"
                        />
                        <SuccessAlert
                            isOpen={isSuccessOpen}
                            message={"Αποθηκέυτηκε η δήλωση ενδιαφέροντος."}
                        />
                        {
                            userType !== "student" ?
                                <Link to={{
                                    pathname: `/dashboard/instructor/thesis/edit/${thesis.thesisID}`,
                                    thesis
                                }}>
                                <Fab style={{position: "absolute", bottom: "20px", right: "20px", zIndex: "10"}} color="secondary" aria-label="edit">
                                    <Edit />
                                </Fab>
                                </Link>: <></>
                        }
                        </Grid>
                </> : <Skeleton variant="rect" width="100%" height={400} />
            }

            sidebar={
                <div>
                    {
                        thesis.doList ?
                        <>
                        <Grid item lg={12} style={{height: "60vh", overflowY: "scroll", maxHeight: "65vh"}}>
                            <Card elevation={3}>
                                <CardContent>
                                    <Grid item lg={12}>
                                        <Typography variant="p" color="primary">{thesis.doList.doListTitle}</Typography>
                                    </Grid>
                                    <Grid item lg={12}>
                                        <Typography variant="caption">{ReactHtmlParser(thesis.doList.doListDescr)}</Typography>
                                    </Grid>
                                    {
                                        thesis.student !== null && thesis.student !== "" ?
                                            <Timeline align="center">
                                                {
                                                    thesis.doList.doListItems.map((doItem, index) => {
                                                        return <TodoListItem
                                                            hasUser={thesis.student !== null && thesis.student !== ""}
                                                            pos={index}
                                                            currentUserTodoItem={getCurrentUserTodoItem(thesis.doList.doListItems)}
                                                            userType="ins"
                                                            updateTodoItem={(doItem) => updateTodoItem(doItem)}
                                                            isOpen={true}
                                                            finishPercentage={getCurrentDoListItemPercentage(thesis.doList.doListItems, doItem, thesis.thesisStartDate)}
                                                            todoItem={doItem}
                                                            startAndEnd={getStartAndEndDateOfDoListItem(thesis.doList.doListItems, doItem, thesis.thesisStartDate)}
                                                        />
                                                    })
                                                }
                                            </Timeline> :
                                                thesis.doList.doListItems.map((doItem, index) => {
                                                    return <NonTimelineTodoList pos={index} todoItem={doItem} />
                                                })
                                    }
                                </CardContent>
                                {
                                    userType === "instructor" && thesisOneStepBeforeFinish() ?
                                        <div style={{marginTop:"10px"}}><ThesisEpilogue updateThesisMark={(mark) => {
                                            const thesisCp = Object.assign({}, thesis);
                                            thesisCp.thesisMark = mark;
                                            setThesis(thesisCp);

                                            updateThesisMark(thesis.thesisID, thesisCp.thesisMark);
                                        }} /></div> : <></>
                                }
                                {
                                    userType === "instructor" ?
                                        <DeleteDoListItem thesis={thesis} setThesis={(thesis) => setThesis(thesis)} /> : <></>
                                }
                            </Card>
                        </Grid>
                        </> :
                            doLists.length > 0 ?
                                <DoListItemQuickSelection thesis={thesis} setThesis={(thesis) => setThesis(thesis)} doLists={doLists} /> : <></>

                    }
                </div>
            }
        />
    )
}