import config from "../config";

export default class ImageRetriever {
    static retrieve(name, type = "preview") {
        console.log(name);
        if (name === null) {
            name = "";
        }
        return `${config.server}/v1/user/image/${type}/${name}?jwt_token=${localStorage.getItem('jwt_token')}`;
    }
}