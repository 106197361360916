import React, {useState} from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    LinearProgress,
    AccordionSummary,
    AccordionDetails,
    Accordion,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Dialog
} from '@material-ui/core';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReactHtmlParser from 'react-html-parser';
import ThesisCardFieldParts from "../ThesisCardFieldParts";
import {
    deleteTodoListItem,
    getCompletedDoListItemsDuration,
    getDoListItemCompletionDays
} from "../../../../../DataTypes/Models/TodoList/TodoList";
import {getThesisEndDate, getTotalMonthsToFinish} from "../../../../../DataTypes/Models/Thesis/Thesis";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {TodoListItemAction} from "../../../../Student/MyThesis/TodoListItem";
import {blue, green, red} from "@material-ui/core/colors";
import {DeleteForever} from "@material-ui/icons";
import WarningIcon from '@material-ui/icons/Warning';
import makeStyles from "@material-ui/core/styles/makeStyles";

const DEFAULT_FIELDS = [
    ThesisCardFieldParts.title,
    ThesisCardFieldParts.intentedDate,
    ThesisCardFieldParts.assignedTo,
    ThesisCardFieldParts.keywords
];

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiCardHeader-root": {
            margin: "0px",
            padding: "10px 16px"
        },
        "& .MuiCardHeader-content": {
            "& .MuiTypography-body1": {
                fontSize: "20px",
                width: "300px",
                display: "inline-block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            }
        }
    },
}));
//
// width: 300px;
// overflow:hidden;
// white-space:nowrap;
// text-overflow: ellipsis;

/**
 * Presentational layer of thesis cards.
 *
 * @param {Thesis} param0
 */
export default function ThesisCard({thesis, fields, isEditable, viewRoute, deleteThesis}) {

    if (!fields) {
        fields = DEFAULT_FIELDS;
    }

    const classes = useStyles();

    const [isDeleteDoListItemDialogOpen, setIsDeleteDoListItemDialogOpen] = useState(false);

    const isPublic = () => {
        if (thesis.student !== null && thesis.student !== '') {
            return thesis.thesisIsPublicByStu;
        }
        return thesis.thesisIsPublicByInst === "1";
    }

    const isStudentAssigned = () => {
        return (thesis.student !== null && thesis.student !== '');
    }

    const doesHaveType = () => {
        return thesis.thesisAssignType !== null;
    }

    const getCurrentTask = () => {
        if (!thesis.doList) {
            return 'Η διατριβή δεν έχει λίστα υποχρεώσεων';
        }

        let currentTask = null;
        for (let i=0;i<thesis.doList.doListItems.length;i++) {
            if (thesis.doList.doListItems[i].dliInstructorOK === 0 || thesis.doList.doListItems[i].dliStudentOK === 0) {
                currentTask = thesis.doList.doListItems[i].dliTitle;
                break;
            }
        }

        if (!currentTask) {
            currentTask = "Ολοκληρώθηκαν οι υποχρεώσεις"
        }

        return currentTask;
    }


    // Get end date for each one.
    let doListItemDays = [];
    let completedDays = 0;

    if (thesis.doList) {
        doListItemDays = getDoListItemCompletionDays(thesis.doList.doListItems);
        completedDays = getCompletedDoListItemsDuration(thesis.doList.doListItems);
        console.log(doListItemDays, completedDays);
    }


    return (
        <Grid item lg={6} md={6}>
        <Card className={classes.root} style={{margin:'0px 5px 15px 15px'}}>
            <CardHeader
                style={{background: 'linear-gradient(to right, #7f7fd5, #86a8e7)', marginBottom: '10px', color: '#fff'}}
                title={<Typography><b>
                    <Link
                    style={{color: "#fff", borderBottom: "none !important"}}
                        to={{
                        pathname: `/${viewRoute}/${thesis.thesisID}`,
                        thesis
                    }}>
                    {
                    thesis.thesisMark?
                        <>
                            <Typography variant="h6">
                                {
                                    thesis.thesisTitle
                                }
                            </Typography>
                          <Chip style={{float: 'right'}} label={`Τελικός Βαθμός ${thesis.thesisMark}`} />
                          </> :
                        <Typography variant="h6">
                            {
                                thesis.thesisTitle
                            }
                        </Typography>

                    }</Link></b></Typography>}
                action={
                    <>
                        {
                            isEditable ?
                                <>
                                    {
                                        thesis.doList === null ?
                                            <Tooltip title="Δεν περιέχει todo-list. Χωρίς αυτήν οι μαθητές δε θα μπορούν να πάρουν μέρος στην διατριβή">
                                                <IconButton>
                                                    <WarningIcon style={{color: "#f1c40f"}} />
                                                </IconButton>
                                            </Tooltip> : <></>
                                    }
                                    <Link
                                        to={{
                                            pathname: `/dashboard/instructor/thesis/edit/${thesis.thesisID}`,
                                            thesis
                                        }}
                                    >
                                        <Tooltip title="Επεξεργασία">
                                            <IconButton onClick={() => {
                                            }}>
                                                <EditIcon style={{color: "#fff"}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                    <Tooltip title="Διαγραφή">
                                        <IconButton onClick={() => {
                                            setIsDeleteDoListItemDialogOpen(true);
                                        }}>
                                            <DeleteForever style={{color: "#fff"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </>
                                : <></>
                        }
                        </>
                }
            />
            {
                fields.includes(ThesisCardFieldParts.keywords) ?
                    <CardContent style={{padding: '1px 15px'}}>
                        {
                            thesis.keywords.map( keyword => {
                                return <Chip variant='outlined' style={{marginLeft: "5px", marginTop: "10px"}} label={keyword.keyword} />
                            })
                        }
                    </CardContent> : <></>
            }
            {
                fields.includes(ThesisCardFieldParts.instructorInfo) ?
                    <CardContent style={{padding: '1px 15px', marginTop: "10px"}}>
                        <Typography variant="body1">
                            Επιβλέπων Καθηγητής: <Chip color="primary" label={thesis.instructor.uName + " " + thesis.instructor.uSurname} />
                        </Typography>
                    </CardContent> : <></>
            }
            {
                fields.includes(ThesisCardFieldParts.description) ?
                    <CardContent>
                        <Accordion style={{boxShadow: "none"}}>
                            <AccordionSummary
                                style={{paddingLeft: "0px"}}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                            >
                                Περιγραφή
                            </AccordionSummary>
                            <AccordionDetails
                                style={{paddingLeft: "0px"}}
                            >
                                <Typography variant="p">{ReactHtmlParser(thesis.thesisDescr).slice(0, 120)}</Typography>
                            </AccordionDetails>
                        </Accordion>
                     <Divider />
                     </CardContent> : <></>
            }
            {
                fields.includes(ThesisCardFieldParts.prefersTheses) ?
                    <>
                        <CardContent>
                            {

                            }
                            <Typography variant="body1"><b>
                                {
                                    thesis.prefersTheses.length === 0 ?
                                        'Δεν υπάρχουν ακόμα αιτήσεις από μαθητές γιαυτήν την διατριβή' :
                                        'Ενδιαφέρονται γιαυτήν την διατριβή:'
                                }
                                </b></Typography><br />
                            {
                                thesis.prefersTheses.map( item => {
                                    return (
                                        <Grid item xs={12}>
                                            <Link to={
                                                {
                                                    pathname: `/dashboard/instructor/profile/preview/${item.student}`,
                                                }
                                            } >
                                                <Typography color="primary" variant="p">
                                                    {item.students.uName} {item.students.uSurname}
                                                    <Tooltip title="Βαθμός προτεραιότητας διατριβής"><small>({item.prefOrder})</small></Tooltip>
                                                </Typography>
                                            </Link>
                                        </Grid>
                                    )
                                })
                            }
                        </CardContent>
                        <Divider />
                    </> : <></>
            }
            <CardContent>
                {
                    fields.includes(ThesisCardFieldParts.assignedTo) ?
                        <Grid container alignItems="center">
                        <Grid item lg={6}>
                            <Typography variant="body1" style={{marginBottom: '0px'}}>Assigned to: </Typography>
                        </Grid>
                        {
                            isStudentAssigned() ?
                            <Grid item lg={6}>
                                <Link to={
                                    {
                                        pathname: `/dashboard/instructor/profile/preview/${thesis.student.username}`,
                                        profile: thesis.student
                                    }
                                } >
                                    <p>{thesis.student.uName + " " + thesis.student.uSurname}</p>
                                </Link>
                            </Grid> :
                            <Grid item lg={6}>
                                <Chip color="secondary" label="None" />
                            </Grid>
                        }
                    </Grid> : <></>
                }

                {
                    doesHaveType() && fields.includes(ThesisCardFieldParts.type) ?
                    <Grid container alignItems="center" className="mt-2">
                        <Grid item lg={6}>
                            <Typography variant="body1" style={{marginBottom: '0px'}}>Type: </Typography>
                        </Grid>
                        <Grid item lg={6}>
                            <Chip color="primary" label={thesis.thesisAssignType} />
                        </Grid>
                    </Grid> : <></>
                }

                {
                    fields.includes(ThesisCardFieldParts.visibility) ?
                    <Grid container alignItems="center" className="mt-2">
                        <Grid item lg={6}>
                            <Typography variant="body1" style={{marginBottom: '0px'}}>is public? </Typography>
                        </Grid>
                        <Grid item lg={6}>
                            {
                                isPublic() ?
                                <IconButton size="small" color="primary">
                                    <CheckSharpIcon color="success" />
                                </IconButton> :
                                <IconButton size="small" color="secondary">
                                    <CloseIcon color="success" />
                                </IconButton>
                            }
                        </Grid>
                    </Grid> : <></>
                }
                {
                    thesis.thesisMark ?
                        <Typography variant="p">Συγγραφέας
                            <Link style={{marginLeft: "5px"}} to={
                                {
                                    pathname: `/dashboard/instructor/profile/preview/${thesis.student.username}`,
                                    profile: thesis.student
                                }
                            }>{thesis.student.uName} {thesis.student.uSurname}</Link>
                        </Typography> : <></>
                }
            </CardContent>

            <Divider />
            <CardContent>

                {
                    thesis.thesisMark ?
                        <Grid container>

                            <Grid item lg={6}>
                                <Typography variant="body1">Ημερ/νία έναρξης:</Typography>
                            </Grid>
                            <Grid item lg={1}></Grid>
                            <Grid item lg={5}>
                                <Typography paragraph color="primary">
                                    <Chip label={
                                        new Date(thesis.thesisStartDate).toLocaleDateString('el-GR')
                                    } />
                                </Typography>
                            </Grid>

                            <Grid item lg={6}>
                                <Typography variant="body1">Ημερ/νία Ολοκλήρωσης:</Typography>
                            </Grid>
                            <Grid item lg={1}></Grid>
                            <Grid item lg={5}>
                                <Typography paragraph color="primary">
                                    <Chip label={
                                        new Date( getThesisEndDate(thesis).split(" ")[0]).toLocaleDateString('el-GR')
                                    } />
                                </Typography>
                            </Grid>

                            <Grid container>

                                <Grid item lg={6}>
                                    <Typography variant="body1">Συνολικοί Μήνες Για Την Ολοκλήρωση:</Typography>
                                </Grid>
                                <Grid item lg={1}></Grid>
                                <Grid item lg={5}>
                                    <Typography paragraph color="primary">{getTotalMonthsToFinish(thesis)}</Typography>
                                </Grid>

                            </Grid>

                        </Grid>
                            : <></>
                }
                {
                    isStudentAssigned() && !thesis.thesisMark ?
                    <>
                        <Grid container>

                            <Grid item lg={6}>
                                <Typography variant="body1">Τρέχον εργασία:</Typography>
                            </Grid>
                            <Grid item lg={1}></Grid>
                            <Grid item lg={5}>
                                <Typography paragraph color="primary">
                                    <Chip label={getCurrentTask()} />
                                </Typography>
                            </Grid>

                            <Grid item lg={6}>
                                <Typography variant="body1">Ολοκλήρωση (% σε χρόνο):</Typography>
                            </Grid>
                            <Grid item lg={1}></Grid>
                            <Grid item lg={5}>
                                {
                                    doListItemDays.length > 0 ?
                                        <LinearProgress variant="determinate" value={
                                            parseFloat(100 - ((doListItemDays[doListItemDays.length - 1] - completedDays) / doListItemDays[doListItemDays.length - 1]) * 100).toFixed(2)} />
                                        : <></>
                                }
                            </Grid>
                        </Grid>

                        <Grid container>
 
                            <Grid item lg={6}>
                                <Typography variant="body1">Ημερ/νια έναρξης:</Typography>
                            </Grid>
                            <Grid item lg={1}></Grid>
                            <Grid item lg={5}>
                                <Typography paragraph color="primary">{
                                    new Date(thesis.thesisStartDate).toLocaleDateString('el-GR')
                                }</Typography>
                            </Grid>

                        </Grid>
                    </> :
                <>
                    {
                        thesis.thesisIntendedEnd !== null && fields.includes(ThesisCardFieldParts.intentedDate) ?
                        <Grid container>

                            <Grid item lg={8}>
                                <Typography variant="body1">Λήξη ημερ/νιας έκφρασης ενδιαφέροντος:</Typography>
                            </Grid>
                            <Grid item lg={1}></Grid>
                            <Grid item lg={3}>
                                <Typography paragraph color="primary">{new Date(thesis.thesisIntendedEnd).toLocaleDateString('el-GR')}</Typography>
                            </Grid>

                        </Grid> :
                            <>{
                                thesis.thesisMark ? <></> :
                                    <Grid container>
                                        <Grid item lg={12}>
                                            <Typography variant="body1" color="secondary">Δεν υπάρχει λήξη ημερ/νια
                                                έκφρασης ενδιαφέροντος</Typography>
                                        </Grid>
                                    </Grid>
                            }
                            </>
                    }
                </>
                }

                {
                    isEditable ?
                        <Dialog
                            open={isDeleteDoListItemDialogOpen}
                            onClose={() => setIsDeleteDoListItemDialogOpen(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Διαγραφή διατριβής;"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Θέλετε σίγουρα να διαγράψετε οριστικά αυτήν την διατριβή;
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {
                                    setIsDeleteDoListItemDialogOpen(false);
                                }} color="primary">
                                    Ακύρωση
                                </Button>
                                <Button onClick={() => {
                                    setIsDeleteDoListItemDialogOpen(false);
                                    deleteThesis(thesis.thesisID);
                                }} color="primary" autoFocus>
                                    Αποδοχή
                                </Button>
                            </DialogActions>
                        </Dialog> : <></>
                }
            </CardContent>
        </Card>
   </Grid>

    )
}