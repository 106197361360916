import { Alert, Autocomplete } from "@material-ui/lab";
import React, {useState, useEffect, useRef} from "react";
import config from "../../config";
import SuccessAlert from "../RootScreenLevelInformation/SuccessAlert";
import ProfileTemplate from "../Instructor/templates/ProfileTemplate";
import InformationBlock from "./InformationBlock";
import {
    Button,
    CardContent,
    Divider,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    TextField,
    Chip
} from "@material-ui/core";
import KeywordsAPI from "../../DataTypes/API/KeywordsAPI";
import User from "../../DataTypes/Models/Users/User";
import Skeleton from "@material-ui/lab/Skeleton";
import {Helmet} from "react-helmet";
import PasswordChanger from "./PasswordChanger";

const MAX_FILE_SIZE = 3000000;

export default function InsProfile(props) {

    const [instructor, setInstructor] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [keywords, setKeywords] = useState([]);
    const [initialUserKeywords, setInitialUserKeywords] = useState([]);

    const [dataLoaded, setDataLoaded] = useState(false);

    // Alert message
    const [isSuccessOpen ,setIsSuccessOpen] = useState(false);

    const [selectVal, setSelectVal] = useState(null);

    const tagRef = useRef();

    useEffect(() => {
        props.dataContainer().then( instructors => {
            let instr = Object.assign({}, instructors[0]);

            instr.keywords = instructors[0].keywords.map( item => {
                return {text: item.keyword, type: "existedAndAdded"};
            })

            setInitialUserKeywords([...instr.keywords]);
            setInstructor(instr)
            setDataLoaded(true);
        });
        let keywordAPI = new KeywordsAPI();
        keywordAPI.getAll().then( res => {
            setKeywords(
                res.map(item => {
                    return {text: item.keyword, type: "exists"}
                })
            )
        })
    }, []);

    const setInstructorCheckboxAttribute = (field, val) => {
        const instr = Object.assign({}, instructor);
        instr[field] = val === "true";
        setInstructor(instr);
    }

    const setInstructorAttribute = (field, val) => {
        const instr = Object.assign({}, instructor);
        instr[field] = val;
        setInstructor(instr);
    }

    const saveUserInformation = () => {

        const jwt_token = localStorage.getItem("jwt_token");

        for (let i=0;i<initialUserKeywords.length;i++) {

            let keywordExistedAndDoesntExistNow = false;

            for (let j=0;j<instructor.keywords.length;j++) {
                if (initialUserKeywords[i].text === instructor.keywords[j].text) {
                    keywordExistedAndDoesntExistNow = true;
                }
            }

            if (!keywordExistedAndDoesntExistNow) {
                initialUserKeywords[i].type = "deleted";
                instructor.keywords.push(initialUserKeywords[i]);
            }
        }

        if (selectedFile && selectedFile.size > MAX_FILE_SIZE) {
            return;
        }

        const formdata = new FormData();
        formdata.append("jwt_token", jwt_token);
        formdata.append("user", JSON.stringify(instructor));
        formdata.append("tags", JSON.stringify({keywords: instructor.keywords}))

        if (selectedFile !== null) {
            formdata.append("selectedFile", selectedFile);
        }

        var xhr = new XMLHttpRequest();
        // Add any event handlers here...
        xhr.open('POST', config.server + props.editUserApi, true);
        xhr.send(formdata);

        xhr.onreadystatechange = function() {
            if (xhr.readyState == XMLHttpRequest.DONE) {
                const instr = Object.assign({}, instructor);
                if (this.responseText) {
                    instr.cvFilename = this.responseText;
                }
                setInstructor(instr);
                setSelectedFile(null);

                setIsSuccessOpen(true);
                setTimeout( () => {
                    setIsSuccessOpen(false);
                }, 1000);
            }
        }
    }

    const findKeywordByName = (name) => {
        for (let i=0;i<keywords.length;i++) {
            if (keywords[i].text === name) {
                return keywords[i];
            }
        }
    }

    const selectFile = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    const getSizeToRightFormat = (size) => {
        if (size > 1000000) {
            return (size/1000000) + "MB";
        } else if (size > 1000) {
            return (size/1000) + "KB";
        } else {
            return size;
        }
    }

    return (
        <ProfileTemplate>
            <Helmet>
                <title>ThesisGate - Προφίλ</title>
            </Helmet>
            <Divider />
            {
                dataLoaded ?
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <Grid container>

                                <InformationBlock updateVal={(field, val) => setInstructorAttribute(field, val)} field="uName" label="Όνομα" value={instructor.uName} />
                                <InformationBlock updateVal={(field, val) => setInstructorAttribute(field, val)} field="uSurname" label="Επίθετο" value={instructor.uSurname} />
                                <InformationBlock updateVal={(field, val) => setInstructorAttribute(field, val)} field="uEmail" label="Email" value={instructor.uEmail} />

                                <Grid container className="mt-2">
                                    <Grid item lg={4} xs={12}>
                                        <Typography style={{marginTop: "10px"}}>Είναι δημόσιο προφίλ;</Typography>
                                    </Grid>
                                    <Grid item lg={8} xs={12}>
                                        <RadioGroup
                                            row
                                            aria-label="IsPublic"
                                            name="IsPublic"
                                            value={instructor.uIsPublic}
                                            onChange={(e) => {setInstructorCheckboxAttribute("uIsPublic", e.currentTarget.value);}}
                                        >
                                            <FormControlLabel value={true}  control={<Radio />} label="Ναι" />
                                            <FormControlLabel value={false} control={<Radio />} label="Όχι" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>

                                <Grid container className="mt-2">
                                    <Grid item lg={2} xs={12}>
                                        <Typography>Βιογραφικό</Typography>
                                    </Grid>
                                    {
                                        instructor.cvFilename ?
                                            <Grid item lg={3} xs={12}>
                                                <Typography color="primary"><a href={`${config.server}/user/profile/cv?jwt_token=${localStorage.getItem("jwt_token")}`}>{instructor.cvFilename}</a></Typography>
                                            </Grid> : <></>
                                    }
                                    <Grid item lg={2} xs={12}>
                                        <label htmlFor="btn-upload">
                                            <input
                                                id="btn-upload"
                                                name="btn-upload"
                                                style={{ display: 'none' }}
                                                type="file"
                                                onChange={(e) => {selectFile(e)}} />
                                            <Button
                                                className="btn-choose"
                                                variant="outlined"
                                                component="span" >
                                                Ανανέωση
                                            </Button>
                                        </label>
                                    </Grid>
                                    {
                                        selectedFile ?
                                            <Grid item lg={3}>
                                                <Typography>{selectedFile.name}({getSizeToRightFormat(selectedFile.size)})</Typography>
                                            </Grid> : <></>
                                    }
                                </Grid>


                                {
                                    selectedFile && selectedFile.size > MAX_FILE_SIZE ?
                                        <Grid container>
                                            <Grid item lg={2} />
                                            <Grid item lg={10}>
                                                <Alert severity="error">Μέγιστο μέγεθος αρχείου {getSizeToRightFormat(MAX_FILE_SIZE)}!</Alert>
                                            </Grid>
                                        </Grid> : <></>
                                }

                                <Grid lg={12} className="mt-2">
                                    <PasswordChanger />
                                </Grid>

                                </Grid>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Typography style={{marginBottom: "5px"}}>Επέλεξε θέματα που σε ενδιαφέρουν</Typography>
                            {
                                keywords.length > 0 ?
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={keywords.filter( keyword =>  {
                                            let keywordChosen = false;
                                            instructor.keywords.map( keywordInner => {
                                                if (keyword.text === keywordInner.text && keywordInner.type !== "deleted") {
                                                    keywordChosen = true;
                                                }
                                            });
                                            return !keywordChosen;
                                        })}
                                        value={selectVal}
                                        onInputChange={(event, newInputValue) => {
                                            if (newInputValue === "") return;
                                            const instr = Object.assign({}, instructor);

                                            instr.keywords = instr.keywords.concat(findKeywordByName(newInputValue));
                                            setInstructor(instr);
                                            setSelectVal(newInputValue);

                                            setSelectVal(null);
                                        }}
                                        getOptionLabel={(option) => option.text}
                                        style={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
                                    /> : <></>
                            }
                            <Typography style={{marginTop: "25px"}}>Ακολουθείτε τα παρακάτω θέματα:</Typography>
                            <Grid container className="mt-2">
                                <Grid item xs={12}>
                                    {instructor.keywords.map( keyword => {
                                        if (keyword.type === "deleted") return <></>
                                        return <Chip onDelete={(e) => {
                                            const instr = Object.assign({}, instructor);

                                            console.log(keyword);
                                            for (let i=0;i<instr.keywords.length;i++) {
                                                if (instr.keywords[i].text === keyword.text) {
                                                    instr.keywords.splice(i, 1);
                                                }
                                            }

                                            setInstructor(instr);

                                        }} label={keyword.text} />
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="mt-2">
                            <Grid item lg={12}>
                                <Button style={{float: "right"}} color="primary" variant="contained" onClick={() => {saveUserInformation(MAX_FILE_SIZE)}}>Αποθήκευση αλλαγών</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent> : <>
                    <Grid container>
                        <Skeleton variant="rect" width={'100%'} height={600} />
                    </Grid>
                    </>
            }
            <SuccessAlert message="Η διατριβή σας έχει αποθηκευτεί" isOpen={isSuccessOpen} />
        </ProfileTemplate>
    )
}