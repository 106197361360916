export default class assignmentTypes {
    /**
     * The ENUM value of assignment types
     * 
     * @param {string} value 
     * @param {string} text 
     */
    constructor(value, text) {
        if (value !== "immediate" && value !== "onDeadline") {
            console.warn("Only values immediate and onDeadline are accepted for assignmentTypes");
            return;
        }
        this.value = value;
        this.text = text;
    }
}