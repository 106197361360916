import {Typography} from "@material-ui/core";
import React from "react";

/**
 *
 * @param pos
 * @param todoItem
 * @returns {JSX.Element}
 * @constructor
 */
export default function NonTimelineTodoList({pos, todoItem}) {
    return (
        <div style={{marginTop: "10px"}}>
        <Typography variant="p">{pos}. {todoItem.dliTitle}</Typography><br />
            <Typography variant="caption">{todoItem.dliDescr}</Typography>
        </div>
    )
}