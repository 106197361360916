import React, {useEffect, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    LinearProgress,
    Button,
    AccordionSummary,
    Typography,
    Grid,
    Chip,
    Divider, IconButton, Tooltip
} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Util from "../../../Util/Util";
import config from "../../../config";
import {cancelStudentDliFinish} from "../../../DataTypes/Models/TodoListItem/TodoListItem";
import {Alert} from "@material-ui/lab";
import WarningIcon from '@material-ui/icons/Warning';
import Timeline from "@material-ui/lab/Timeline";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import {Check} from "@material-ui/icons";

export function TodoListItemAction({currentUserTodoItem, todoItem, updateTodoItem, userType, finishPercentage}) {

    const [selectedFile, setSelectedFile] = useState(null);

    const selectFile = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    function finishStep() {
        const formdata = new FormData();
        const jwt_token = localStorage.getItem("jwt_token");

        formdata.append("jwt_token", jwt_token);
        if (selectedFile !== undefined && selectedFile !== null) {
            formdata.append("selectedFile", selectedFile);
        }

        const xhr = new XMLHttpRequest();
        // Add any event handlers here...
        xhr.open('POST', config.server + `/v1/student/todoItem/${todoItem.dliID}/finish`, true);
        xhr.send(formdata);
        xhr.onreadystatechange = function() {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (JSON.parse(xhr.responseText).status === 200) {
                    const todoItemCp = Object.assign({}, todoItem);
                    todoItemCp.dliStudentOK = 1;
                    todoItemCp.stuOKWhen = {date: new Date()};
                    updateTodoItem(todoItemCp);
                }
            }
        }
    }

    function manageFinishStepInstr(cancel = false) {
        const jwt_token = localStorage.getItem("jwt_token");
        Util.apiCall(config.server, `/v1/instructor/todoItem/${todoItem.dliID}/${cancel ? 'finish/cancel' : 'finish'}`, "PUT", {jwt_token}).then( res => {
            if (res.status === 200) {
                const todoItemCp = Object.assign({}, todoItem);
                if (cancel) {
                    todoItemCp.dliInstructorOK = 0;
                } else {
                    todoItemCp.dliInstructorOK = 1;
                }
                updateTodoItem(todoItemCp);
            }
        });
    }

    const getSizeToRightFormat = (size) => {
        if (size > 1000000) {
            return (size/1000000) + "MB";
        } else if (size > 1000) {
            return (size/1000) + "KB";
        } else {
            return size;
        }
    }

    function manageCancelStudentFinish() {
        cancelStudentDliFinish(todoItem).then( res => {
            if (res.status === 200) {
                const todoItemCp = Object.assign({}, todoItem);
                todoItemCp.dliStudentOK = 0;
                updateTodoItem(todoItemCp);
            }
        })
    }

    const getActionByUserType = () => {
        if (userType === "stu") {
            return (
                <>
                    {
                        todoItem.dliStudentOK === 1 && todoItem.dliInstructorOK === 0?
                            <p><strong>Αναμονή απάντησης καθηγητή...</strong></p>
                            :
                            <></>
                    }
                    {
                        todoItem.dliStudentOK === 0 && todoItem.dliInstructorOK === 0 && currentUserTodoItem && currentUserTodoItem.dliID === todoItem.dliID ?
                            <>
                                <Button
                                    onClick={() => finishStep()}
                                    style={{marginBottom: "10px", marginTop: "10px"}}
                                    size="small"
                                    variant="contained" color="primary">Ολοκήρωση βήματος</Button>
                                <label htmlFor="btn-upload">
                                    <input
                                        id="btn-upload"
                                        name="btn-upload"
                                        style={{ display: 'none' }}
                                        type="file"
                                        onChange={(e) => {selectFile(e)}} />
                                    <Button
                                        className="btn-choose"
                                        variant="contained"
                                        size="small"
                                        component='span'
                                    >
                                        {
                                            selectedFile ?
                                                <Typography>{selectedFile.name}({getSizeToRightFormat(selectedFile.size)})</Typography>
                                                : <>Αποστολή αρχείου</>
                                        }
                                    </Button>
                                </label>
                            </>
                            :
                            <></>
                    }
                    {
                        todoItem.dliStudentOK === 1 && todoItem.dliInstructorOK === 1 ?
                            <></> :
                            <>
                                <>
                                    <p style={{fontSize: "11px"}}> μέχρι την λήξη</p>
                                    <LinearProgress style={{width: "110%"}} variant="determinate" value={finishPercentage < 100 ? finishPercentage : 100} />
                                    </>
                            </>
                    }
                    </>
            )
        } else {
            return (
                <>
                    {
                        todoItem.dliStudentOK === 1 && todoItem.dliInstructorOK === 0?

                            <Grid container style={{marginTop: "10px"}}>
                                <Grid item xs={12}>
                                    <Button style={{float: "left"}} startIcon={<Check />} size='small' variant="contained" color="primary" onClick={() => manageFinishStepInstr()}>Αποδοχή</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button  size='small' style={{float: "left"}} startIcon={<ClearIcon />} variant="contained" color="secondary" onClick={() => manageCancelStudentFinish()}>Επιστροφή</Button>
                                </Grid>
                                <Grid item xs={12} style={{marginTop: '10px'}}>
                                    {
                                        todoItem.attachment ?
                                            <a href={`${config.server}/v1/instructor/todo-list/item/${todoItem.dliID}/attachment?jwt_token=${localStorage.getItem("jwt_token")}`}>
                                                <Button size='small' variant="contained">Συννημένο: {todoItem.attachment.attachFileName}</Button>
                                            </a>
                                             : <></>
                                    }
                                </Grid>
                            </Grid>
                            :
                            <></>
                    }

                    {
                        todoItem.dliStudentOK === 1 && todoItem.dliInstructorOK === 1 ?
                            <>{
                                currentUserTodoItem && currentUserTodoItem.dliID === todoItem.dliID ?
                                    <p>
                                        <Button variant="contained" color="secondary" onClick={() => manageFinishStepInstr(true)}>Ακύρωση επιβεβαίωσης βήματος</Button>
                                    </p> : <></>
                            }
                            </>
                           :
                            <div style={{marginTop: "10px"}}>
                                <LinearProgress style={{width: "110%"}} variant="determinate" value={finishPercentage < 100 ? finishPercentage : 100} />
                            </div>
                    }
                </>
            )
        }
    }

    return (
        <Grid item xs={12}>
            <div style={{width: "90%"}}>
                {getActionByUserType()}
            </div>
        </Grid>
    )
}

export default function TodoListItem(
    {
        todoItem,
        pos,
        updateTodoItem,
        userType,
        currentUserTodoItem,
        hasUser,
        finishPercentage,
        startAndEnd
    }
    ) {

    useEffect(() => {

    }, [todoItem])

    const getUserHasSurpassedTimeLimitWarning = () => {
        if (
            currentUserTodoItem &&
            new Date() > startAndEnd.endDate
            && hasUser
            && todoItem.dliID === currentUserTodoItem.dliID
            && currentUserTodoItem.dliStudentOK === 0
        ) {
            return <Alert severity="warning">Η εργασία έχει υπερβεί τον χρόνο</Alert>;
        }
    }

    const isCurrentTodoItem = () => {
        return currentUserTodoItem && todoItem.dliID === currentUserTodoItem.dliID;
    }

    const isEnded = () => {
        return todoItem.dliStudentOK === 1 && todoItem.dliInstructorOK === 1;
    }

    const getTimelineColorDot = () => {
        if (isCurrentTodoItem()) {
            return "primary"
        }

        if (isEnded()) {
            return "grey";
        }

        return "default";
    }

    return (
        <>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot variant="default" color={getTimelineColorDot()} />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    {todoItem.dliTitle}<br />
                    <Typography variant="caption">{todoItem.dliDescr}</Typography><br />
                </TimelineContent>
                <TimelineOppositeContent>
                    <Typography variant="caption" color="textSecondary">
                        Έναρξη - {startAndEnd.startDate.toLocaleDateString(config.dateFormat)} |
                        Λήξη - {startAndEnd.endDate.toLocaleDateString(config.dateFormat)}</Typography>
                    <TodoListItemAction
                        currentUserTodoItem={currentUserTodoItem}
                        updateTodoItem={(item) => updateTodoItem(item)}
                        todoItem={todoItem}
                        userType={userType}
                        finishPercentage={finishPercentage}
                    />
                </TimelineOppositeContent>
            </TimelineItem>
        </>
    )
}