import React from 'react'
import { Card, Divider, Grid, CardContent } from '@material-ui/core'
import propTypes from "prop-types";
import {Helmet} from "react-helmet";

/**
 * This is created as a template for all single entity
 * views.
 * 
 * @param props 
 */
export default function SingleTemplate(props) {
    return (
        <Grid container>
            <Helmet>
                <title>ThesisGate - Διατριβή</title>
            </Helmet>

            <Grid item lg={12}>
                {props.header}
                {props.menu}
            </Grid>

            <Grid item lg={7} xs={12} md={7}>
                <div style={{padding: "20px"}}>
                {/*<div style={{padding: "10px"}}>*/}
                {/*    <Card>*/}
                {/*        {props.header}*/}
                {/*        <Divider />*/}
                {/*        <CardContent>*/}
                            {props.content}
                {/*        </CardContent>*/}
                {/*    </Card>*/}
                {/*</div>*/}
                </div>
            </Grid>

            <Grid item lg={5} md={5} xs={12}>
                <div style={{padding: "10px"}}>
                {props.sidebar}
                </div>
            </Grid>

        </Grid>
    )
}

SingleTemplate.propTypes = {
    /**
     * The card header.
     */
    header: propTypes.element.isRequired,

    /**
     * The content of the card
     */
    content: propTypes.element.isRequired,

    /**
     * Sidebar of the element. Can be a whole elemnt.
     */
    sidebar: propTypes.element
}