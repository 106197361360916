import React from 'react';
import {Card, CardHeader, Grid} from '@material-ui/core';
import propTypes from "prop-types";

/**
 * A template for profiles.
 * @param {*} props 
 */
export default function ProfileTemplate(props) {

    return (
        <Grid container className="mt-2">
            <Grid item lg={12} md={12}>
               <Card style={{margin: '20px'}}>
                   <CardHeader 
                        title="Προφίλ"
                   />
                   {
                       props.children
                   }
               </Card>
            </Grid>
        </Grid>
    )
}

ProfileTemplate.propTypes = {
    children: propTypes.element
}