import React, {useEffect, useRef, useState} from 'react';
import {
    CardHeader,
    Divider,
    Grid,
    Card,
    CardContent,
    TextField,
    MenuItem,
    FormControl,
    Radio,
    RadioGroup,
    Select,
    FormControlLabel,
    Button,
    Snackbar,
    Typography, Menu
} from '@material-ui/core';
import assignmentTypes from "../../../DataTypes/Models/Thesis/AssignmentTypes";
import Thesis from "../../../DataTypes/Models/Thesis/Thesis";
import Util from '../../../Util/Util';
import config from "../../../config";
import { Alert } from '@material-ui/lab';
import { Editor } from '@tinymce/tinymce-react';
import TagControl from "../../TagControl/TagControl";
import MomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {ThesisMenu} from "./ThesisMenu";
import {Helmet} from "react-helmet";
import makeStyles from "@material-ui/core/styles/makeStyles";
import EditThesisImages from "./EditThesisImages";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

/**
 * Thesis assigment acceptable types.
 */
const thesisAssignmentTypes = [
    new assignmentTypes("immediate", "Άμεση ολοκλήρωση"),
    new assignmentTypes("onDeadline", "Ολοκλήρωση στην προθεσμία")
];

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiCardHeader-title": {
            fontSize: "20px",
        },
        marginLeft: "15px"
    },
}));

export default function CreateThesis() {

    const thesis = new Thesis();
    const classes = useStyles();
    const history = useHistory();

    // Form data
    const [formValues, setFormValues] = useState(thesis);

    // Alert message
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);

    const tagRef = useRef(null);

    const updateForm = (e, field) => {
        let formValuesCp = Object.assign({}, formValues);
        formValuesCp[field] = e.target.value;
        setFormValues(formValuesCp);
    }

    const updateFormDate = (e, field) => {
        let formValuesCp = Object.assign({}, formValues);
        formValuesCp[field] = e.target.value._d;
        setFormValues(formValuesCp);
    }

    const updateDB = () => {

        const tags = tagRef.current.childRef.state.tags;

        if (hasErrors()) {
            return;
        }

        const formVals = Object.assign({}, formValues);

        formVals.thesisIntendedEnd = Util.DateFunctions.formatDate(formVals.thesisIntendedEnd);
        formVals.thesisStartDate = Util.DateFunctions.formatDate(formVals.thesisStartDate);
        formVals.thesisEndDate = Util.DateFunctions.formatDate(formVals.thesisEndDate);
        formVals.thesisIntDeadline = Util.DateFunctions.formatDate(formVals.thesisIntDeadline);

        const jwt_token = localStorage.getItem("jwt_token");
        Util.apiCall(config.server, '/v1/instructor/thesis', "POST", {...formVals, jwt_token, tags}).then( (res) => {
            if (!isNaN(res) && res >= 400) {

            } else {
                setIsSuccessOpen(true);
                setTimeout(() => {
                    setIsSuccessOpen(false);
                },3000);

                history.push({pathname: `/dashboard/instructor/thesis/edit/${parseInt(res)}`});
            }
        })
    }

    const resetForm = () => {
        setFormValues(new Thesis());
    }

    const hasErrors = () => {
        return formValues.thesisTitle === "" || formValues.thesisTitle === null
    }

    const onEditorChange = (s) => {
        let formValuesCp = Object.assign({}, formValues);
        formValuesCp["thesisDescr"] = s;
        setFormValues(formValuesCp);
    }

    return (
        <Grid container className="mt-2">
            <Helmet>
                <title>ThesisGate - Δημιουργία Διατριβής</title>
            </Helmet>
            <ThesisMenu breadcrumbItems={
                <Typography>
                    Προσθήκη
                </Typography>
            } />
            <Grid item lg={8}>
                <Card className={classes.root}>
                    <CardHeader
                        title="Προσθήκη"
                    />
                    <Divider />
                    <CardContent>
                        <Grid container justify="center">
                            <Grid item lg={11}>

                            <Grid container>

                                <Grid item lg={6}>
                                    <FormControl>
                                        <TextField
                                            error={formValues.thesisTitle === '' || formValues.thesisTitle === null}
                                            required 
                                            id="title" 
                                            value={formValues.thesisTitle} 
                                            placeholder="Τίτλος"
                                            onChange={(e) => updateForm(e, "thesisTitle")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item lg={6}>
                                    <Select
                                        required
                                        id="thesisAssignType"
                                        value={formValues.thesisAssignType}
                                        placeholder="Τύπος"
                                        onChange={(e) => updateForm(e, "thesisAssignType")}
                                    >
                                        {
                                            thesisAssignmentTypes.map((option, index) => {
                                                return (
                                                    <MenuItem key={index} value={option.value}>
                                                        {option.text}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </Grid>

                                <Grid container className="mt-2">
                                    <Grid item lg={12} className="mt-2">
                                        <Editor
                                            initialValue=""
                                            init={{
                                            height: 350,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar:
                                                'undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help'
                                            }}
                                            onEditorChange={onEditorChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Divider />

                                <Typography variant="h5" style={{marginTop: "20px"}}>Ορατότητας διατριβής</Typography>

                                <Grid item lg={12} style={{marginBottom: "10px"}}>
                                    <FormControl component="fieldset">
                                            <RadioGroup 
                                                aria-label="thesisIsPublicByInst" 
                                                name="thesisIsPublicByInst" 
                                                value={formValues.thesisIsPublicByInst} 
                                                onChange={(e) => updateForm(e, "thesisIsPublicByInst")}
                                            >
                                            <FormControl>
                                                <FormControlLabel value="0" control={<Radio />} label="Ιδιωτική" />
                                                <FormControlLabel value="1" control={<Radio />} label="Δημόσια" />
                                            </FormControl>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <TagControl ref={tagRef} />

                                <Divider />

                                <Typography variant="h5" style={{marginTop: "20px"}}><b>Χρονοπρογραμματισμός</b></Typography>
                                <Divider />

                                <Grid container>

                                    <MuiPickersUtilsProvider
                                        utils={MomentUtils}>
                                        <Grid item lg={6}>
                                            <FormControl>
                                                <span>Προθεσμία αιτήσεων</span>
                                                <DatePicker
                                                    variant="dialog"
                                                    format="DD-MM-YYYY"
                                                    value={
                                                        formValues.thesisIntDeadline
                                                    }
                                                    onChange={(e) => updateFormDate({target: {value: e}}, "thesisIntDeadline")}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item lg={6}>
                                            <FormControl>
                                                <span>Hμερ/νια έναρξης</span>
                                                <DatePicker
                                                    variant="dialog"
                                                    format="DD-MM-YYYY"
                                                    value={formValues.thesisStartDate}
                                                    onChange={(e) => updateFormDate({target: {value: e}}, "thesisStartDate")}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item lg={12} style={{marginTop: "10px"}}>
                                            <FormControl>
                                                <span>Hμερ/νια επιθυμητής ολοκ/ρωσης</span>
                                                <DatePicker
                                                    variant="dialog"
                                                    format="DD-MM-YYYY"
                                                    value={formValues.thesisIntendedEnd}
                                                    onChange={(e) => updateFormDate({target: {value: e}}, "thesisIntendedEnd")}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item lg={12} className="mt-2" style={{marginTop: "20px"}}>
                                            <Button style={{float: "right"}} onClick={() => updateDB()} variant="contained" color="primary">Αποθήκευση</Button>
                                        </Grid>

                                    </MuiPickersUtilsProvider>

                                </Grid>
                            </Grid>

                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item lg={4}>
                <Grid container justify="center">
                    <Grid item lg={12}>

                    </Grid>
                </Grid>
            </Grid>

            <Snackbar open={isSuccessOpen}>
                <Alert severity="success">
                    Η διατριβή σας έχει αποθηκευτεί
                </Alert>
            </Snackbar>

        </Grid>
    )
}