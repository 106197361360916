import React, {useEffect, useState} from "react";
import {getThesesThatNeedInstructorActions} from "../../../DataTypes/Models/Thesis/Thesis";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {Link} from "react-router-dom";

export default function InstructionActionThesis() {

    const [theses, setTheses] = useState([]);
    const [thesesLoaded, setThesesLoaded] = useState(false);

    useEffect(() => {
        if (!thesesLoaded) {
            setThesesLoaded(true);
            getThesesThatNeedInstructorActions().then( res => {
                console.log(res);
                setTheses(res);
            })
        }
    })

    return (
        <div style={{margin: "10px"}}>
            <Card>
                <CardHeader
                    title="Διατριβές που αναμένουν απάντηση σας"
                />
                <CardContent>
                    {
                        theses.length === 0 || !Array.isArray(theses) ?
                            "Καμία διατριβή δεν χρειάζεται την ενέργεια σας" :
                            <ul>
                                {
                                    theses.map( thesis => {
                                        return <li>
                                            <Link
                                                to={{
                                                    pathname: `/dashboard/instructor/thesis/single/${thesis.thesisID}`
                                                }}
                                            >
                                                {thesis.thesisTitle}
                                            </Link>
                                            </li>
                                    })
                                }
                            </ul>
                    }
                </CardContent>
            </Card>
        </div>
    )
}