import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import InstituteModel from "../../../DataTypes/Models/Institute/Institute.model";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DepartmentModel, {Department} from "../../../DataTypes/Models/Department/Department.model";

export function DepartmentEditModel({isOpen = true, onSave, onClose, department}) {

    const [institutions, setInstitutions] = useState([]);
    const [selectedInst, setSelectedInst] = useState(null);

    const [dep, setDep] = useState(null)

    const setDepField = (field, value) => {
        const depCp = Object.assign({}, dep);
        depCp[field] = value;
        setDep(depCp);
    }

    useEffect(() => {

        if (institutions.length === 0) {
            InstituteModel.getAll().then( res => {
                setInstitutions(res);
            })
        }

        if (dep === null && department !== null) {
            setDep(department);
            setSelectedInst(department.institution.instID);
        }

    }, [isOpen, department]);

    const editDepartment = () => {
        let inst = null;
        for (let i=0;i<institutions.length;i++) {
            if (institutions[i].instID === selectedInst) {
                inst = institutions[i];
            }
        }

        DepartmentModel.edit(Object.assign({}, dep)).then(r => {
            onSave(dep);
            resetForm();
        });
    }

    const resetForm = () => {
        setDep(null);
    }

    if (!dep) {
        return <></>
    }

    return (
        <Dialog fullWidth={true} onClose={() => {onClose();resetForm()}} open={isOpen} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Προσθήκη νέου</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="depName"
                            label="Όνομα"
                            value={dep.depName}
                            type="text"
                            onChange={(e) => setDepField("depName", e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <TextField
                            autoFocus
                            value={dep.depEmail}
                            margin="dense"
                            id="email"
                            onChange={(e) => setDepField("depEmail", e.target.value)}
                            label="Email"
                            type="Email"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="phone"
                            onChange={(e) => setDepField("depPhone", e.target.value)}
                            value={dep.depPhone}
                            label="Phone"
                            type="phone"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="city"
                            onChange={(e) => setDepField("depCity", e.target.value)}
                            value={dep.depCity}
                            label="City"
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        {
                            institutions.length > 0 ?
                                <FormControl>
                                    <InputLabel id="institute">Ινστιτούτο</InputLabel>
                                    <Select
                                        value={selectedInst}
                                        labelId="institute"
                                        id="institute"
                                        onChange={(val) => {
                                            setSelectedInst(val.target.value);
                                        }}
                                        fullWidth
                                    >
                                        {
                                            institutions.map( institute => {
                                                return <MenuItem value={institute.instID}>{institute.instName}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl> : <></>
                        }
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <TextField
                            autoFocus
                            margin="dense"
                            onChange={(e) => setDepField("depUrl", e.target.value)}
                            id="url"
                            label="URL"
                            type="text"
                            value={dep.depUrl}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="lng"
                            onChange={(e) => setDepField("depLng", e.target.value)}
                            label="Longitude"
                            type="number"
                            value={dep.depLng}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="lat"
                            onChange={(e) => setDepField("depLat", e.target.value)}
                            value={dep.depLat}
                            label="Latitude"
                            type="number"
                            fullWidth
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {onClose();resetForm()}} color="primary">
                    Ακύρωση
                </Button>
                <Button onClick={() => {
                    editDepartment()
                }} color="primary">
                    Αποθήκευση
                </Button>
            </DialogActions>
        </Dialog>
    )
}