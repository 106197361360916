import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import InstituteModel, {Institute} from "../../../DataTypes/Models/Institute/Institute.model";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
/**
 *
 * @param isOpen
 * @param onSave
 * @param onClose
 * @param {Institute} institute
 * @returns {JSX.Element}
 * @constructor
 */
export default function InstitutionEditModel({isOpen = true, onSave, onClose, institute}) {

    const [localInstitute, setLocalInstitute] = useState(null);

    const save = () => {
        InstituteModel.edit(localInstitute).then(r => {
            onSave(localInstitute);
            resetForm();
        });
    }

    useEffect(() => {
        if (institute !== null && localInstitute === null) {
            setLocalInstitute(institute);
        }
    }, [institute])

    const resetForm = () => {
        setLocalInstitute(null);
    }

    const setField = (field, value) => {
        const depCp = Object.assign({}, localInstitute);
        depCp[field] = value;
        setLocalInstitute(depCp);
    }

    if (!localInstitute) {
        return <></>
    }

    return (
        <Dialog fullWidth={true} onClose={() => {onClose();resetForm()}} open={isOpen} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Προσθήκη νέου</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="instName"
                            label="Όνομα"
                            value={localInstitute.instName}
                            type="text"
                            onChange={(e) => setField("instName", e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <TextField
                            autoFocus
                            value={localInstitute.instURL}
                            margin="dense"
                            id="instURL"
                            onChange={(e) => setField("instName", e.target.value)}
                            label="URL"
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <FormControl>
                            <InputLabel id="type">Είδος</InputLabel>
                            <Select
                                value={localInstitute.instType}
                                labelId="type"
                                id="type"
                                onChange={(e) => {
                                    setField("instType", e.target.value);
                                }}
                                fullWidth
                            >
                                <MenuItem value={Institute.types.uni}>Πανεπιστήμιο</MenuItem>
                                <MenuItem value={Institute.types.tei}>Τ.Ε.Ι</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {onClose();resetForm()}} color="primary">
                    Ακύρωση
                </Button>
                <Button onClick={() => {
                    save();
                }} color="primary">
                    Αποθήκευση
                </Button>
            </DialogActions>
        </Dialog>
    )
}