import { Button, Card, CardContent, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import TodoList from "./TodoList";

export default function TodoListCollection({pagination, paginationAmount = 6, list, todoListItemDelete}) {

    if (list === null) {
        return <>loading...</>
    }

    if (list.length > 0) {
        return list.slice((pagination-1)*paginationAmount, pagination*paginationAmount).map( item => {
            return <TodoList todoListItemDelete={(id) => todoListItemDelete(id)} todo={item} />;
        })
    } else {
        return (
            <Grid item lg={12}>
                <Card style={{marginLeft:'10px'}}>
                    <CardContent>
                        <Grid container justify="center">
                            <Grid item lg={6}>
                                <h2>Δεν υπάρχουν στοιχεία! Δοκιμάστε να πρόσθεσετε μερικά</h2>
                                <Grid container justify="center">
                                    <Button variant="contained" color="primary"><Link style={{color:'#fff'}} to="/dashboard/instructor/todo-list/create">Προσθήκη todo λίστας</Link></Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )
    }
    
}