import { Alert } from 'antd';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import '../assets/css/style.css'

const USER_DOESNT_EXIST = 0;
const CODE_ISNT_ACCEPTED = 1;
const CODE_ACCEPTED = 2;

export default function EmailAuthenticationLabel(props) {
    if (props.status === USER_DOESNT_EXIST) {
        return (
            <Alert
                message="Αυτός ο χρήστης δεν είναι καταγεγραμμένος στο σύστημα. Παρακαλώ κάνετε εγγραφή"
                type="warning"
                closable
            />
        )
    } else if (props.status === CODE_ISNT_ACCEPTED) {
        return (
            <Alert
                message="Λάθος κωδικός, παρακαλώ δοκιμάστε πάλι."
                type="warning"
                closable
            />
        )
    } else if (props.status === CODE_ACCEPTED) {

        localStorage.setItem("jwt_token", props.jwt);
        // Save the JWT and sign-in
        return (
            <Redirect to="/dashboard/student" />
        )
    } else {
        return <></>
    }
}

