import React from "react";
import ThesisCard from "./ThesisCard";
import {Grid, Paper} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import ThesisCardLeftImage from "./ThesisCardLeftImage";

export default function ThesisCardCollection({pagination, list, fields, isEditable, viewRoute, deleteThesis, loaded = true}) {

    if (!loaded) {
        return (
            <Grid container spacing={1} style={{paddingLeft: "20px", paddingRight: "20px", overflow: "hidden"}}>
                <Grid item lg={3} md={6}>
                    <Skeleton variant="rect" height={200} />
                </Grid>
                <Grid item lg={9} md={6}>
                    <Skeleton variant="rect" height={200} />
                </Grid>
                <Grid item lg={3} md={6}>
                    <Skeleton variant="rect" height={200} />
                </Grid>
                <Grid item lg={9} md={6}>
                    <Skeleton variant="rect" height={200} />
                </Grid>        <Grid item lg={3} md={6}>
                <Skeleton variant="rect" height={200} />
            </Grid>
                <Grid item lg={9} md={6}>
                    <Skeleton variant="rect" height={200} />
                </Grid>

            </Grid>
        )
    }

    if (list.length === 0) {
        return (
        <Grid container justify="center">
            <Paper style={{height: "60px", padding: "20px", width: "100%", margin: "0px 18px"}}>
                <Typography variant="h5">Δεν υπάρχουν διαθέσιμες διατριβές σε αυτήν την κατηγορία</Typography>
            </Paper>
        </Grid>
        )
    }

    return list.slice((pagination-1)*6, pagination*6).map( thesis => {
        return <ThesisCardLeftImage deleteThesis={(id) => deleteThesis(id)} viewRoute={viewRoute} isEditable={isEditable} fields={fields} thesis={thesis} />;
    })
    
}