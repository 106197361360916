import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import '../assets/css/style.css'
import config from '../config';
import UserValidator from '../DataTypes/Authentication/UserValidator';
import Util from '../Util/Util';
import EmailAuthenticationLabel from './EmailAuthenticationLabel';
import {Grid, Card, CardContent, CardHeader, Button, TextField} from "@material-ui/core";
import {Helmet} from "react-helmet";

export default function AuthenticateEmail(props) {

    const history = useHistory();

    const {email} = useParams();
    const [code, setCode] = useState('');
    const [activationStatus, setActivationStatus] = useState(-1);
    const [jwtKey, setJwtKey] = useState('');

    const sendConfirmationToken = () => {
        Util.apiCall(config.server, "/activate", "POST", {email, code}).then( res => {
            setJwtKey(res["key"]);
            setActivationStatus(res["status"])
            if (res["status"] == "2") {
                history.push('/dashboard/student/profile');
            }
        });
    }

    const resendEmailConfirmation = () => {
        Util.apiCall(config.server, "/changeEmailConfirmationToken", "POST", {email}).then( res => {

        });
    }

    useEffect(() => {
        Util.authenticate().then( res => {
            const validator = new UserValidator(res.type, res.isJWTValid, res.isUserActive, res.email);
            if (validator.isJWTValid == 0 || validator.isUserActive == 1) {
                history.push('/login');
            }
        });
    }, [])

    return (
            <div className="max-width max-height bg-gradient-blue-skies">
                <Helmet>
                    <title>ThesisGate - Επιβεβαίωση email</title>
                </Helmet>
                <Grid container justify="center" alignItems="center" style={{height:'100vh'}}>
                    <Grid container item lg={12} justify="center">
                        <Grid item lg={5}>
                            <Card elevation={3}>
                                <Grid container justify="center">
                                    <Grid lg={10} className="mt-2">

                                        <CardHeader
                                        title={`Επιβεβαίωση email: ${email}`}
                                        />
                                        <CardContent>
                                            <Grid container>
                                                <Grid item lg={11}>
                                                    <Grid container>
                                                        <Grid item lg={6} spacing={3}>
                                                            <TextField 
                                                                id="standard-basic" 
                                                                label="Κωδικός επιβεβαίωσης"
                                                                value={code}
                                                                onChange={(e) => setCode(e.currentTarget.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item lg={6} >
                                                            <div className="mt-2">
                                                                <Button color="primary" variant="contained"  onClick={() => {sendConfirmationToken()}} disableElevation>Επαλήθευση κωδικού</Button>   
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={11}>
                                                    <p className="mt-1">Εάν δε σας έχει έρθει κάποιος email περιμένετε 5 λεπτά και στείλτε ξανά!</p>
                                                </Grid>
                                            </Grid>
                                            <div className="mt-2">
                                                <Grid item lg={11}>
                                                    <Button variant="outlined"  onClick={() => resendEmailConfirmation()}>Λάβετε νέο κωδικό Επαλήθευσης</Button>
                                                </Grid>
                                            </div>
                                            <EmailAuthenticationLabel status={activationStatus} jwt={jwtKey} />
                                        </CardContent>

                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
  
    );
}

