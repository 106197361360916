import {Alert, AlertTitle} from "@material-ui/lab";
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {copyTodoList} from "../../../../../DataTypes/Models/TodoList/TodoList";
import Thesis from "../../../../../DataTypes/Models/Thesis/Thesis";
import React, {useEffect, useState} from "react";

export default function DoListItemQuickSelection({doLists, thesis, setThesis}) {

    const [doListChoice, setDoListChoice] = useState(doLists.length > 0 ? doLists[0] : null);

    return (
        <>
            <Alert severity="warning">
                <AlertTitle>Προσοχή!</AlertTitle>
                <Typography>Δεν υπάρχει λίστα εργασιών. Χωρίς αυτήν οι μαθητές σας δεν θα μπορούν να ολοκληρώσουν την διατριβή</Typography>
            </Alert>
            <Grid container className="mt-2">
                <Card style={{width: '100%'}}>
                    <CardContent>
                        <Grid container>
                            <Grid xs={6} item>
                                <Typography>Επιλογή do list</Typography>
                            </Grid>
                            <Grid xs={6} item>
                                {
                                    doLists.length > 0 ?
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={doListChoice.doListID}
                                            onChange={(e) => {
                                                let newListChoice = null;
                                                doLists.map( doList => {
                                                    if (doList.doListID === e.target.value) {
                                                        newListChoice = doList;
                                                    }
                                                })

                                                setDoListChoice(newListChoice);
                                            }}
                                        >
                                            {
                                                doLists.map( doList => {
                                                    return (
                                                        <MenuItem value={doList.doListID}>{doList.doListTitle}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select> : <></>
                                }
                            </Grid>
                            <Grid xs={12}>
                                <Button variant="contained" color="primary" onClick={() => {
                                    copyTodoList(doListChoice.doListID, thesis.thesisID).then( res => {
                                        if (res.length === 1) {
                                            const id = res[0]["@newDoList"];
                                            const th = Object.assign(new Thesis, thesis);
                                            th.doList = Object.assign({}, doListChoice);
                                            th.doList.doListID = id;
                                            setThesis(th);
                                        }
                                    })
                                }}>Αποθήκευση</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}