import React, {useEffect, useState} from 'react';
import {
    DialogTitle,
    Dialog,
    CardActions,
    CardHeader,
    Divider,
    Grid,
    Card,
    CardContent,
    Tooltip,
    TextField,
    AccordionSummary,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    Button,
    Snackbar,
    Accordion,
    Typography,
    AccordionDetails,
    IconButton,
    DialogContent,
    DialogContentText,
    DialogActions,
    LinearProgress,
    Chip } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Util from '../../../Util/Util';
import config from "../../../config";
import { Alert, AlertTitle } from '@material-ui/lab';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ThesisDataContainer from '../../DataContainers/ThesisDataContainer';
import TodoListItemsDataContainer from '../../DataContainers/TodoListItemsDataContainer';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TodoListContainer from '../../DataContainers/TodoListContainer';
import CreateTodoListItem from "./todoListItem/CreateTodoListItem";
import {Delete, ExpandMore} from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { Editor } from "@tinymce/tinymce-react";
import {
    deleteDoListItem,
    getEstimatedEndDate,
    getEstimatedTotalTimePercentage, getRealEndDate,
    getRealTotalTimePercentage, updateOrder
} from "../../../DataTypes/Models/TodoListItem/TodoListItem";
import Thesis from "../../../DataTypes/Models/Thesis/Thesis";
import {TodoListMenu} from "./TodoListMenu";
import {Helmet} from "react-helmet";

export default function TodoListEdit() {

    const location = useLocation();
    const {id} = useParams();

    const [currentChangingPositionItemIndex, setCurrentChangingPositionItemIndex] = useState(-1);

    // Form data
    const [formValues, setFormValues] = useState({});

    // Thesis to choose from
    const [theses, setTheses] = useState([]);

    // Alert message
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);

    // AddTodoListItemState
    const [todoListItems, setTodoListItems] = useState([]);

    // Editing current todoListItem
    const [todoListItemEditing, setTodoListItemEditing] = useState(null);

    // Todo item add.
    const [todoListItemAddModalVisible, setTodoListItemAddModalVisible] = useState(false);

    useEffect(() => {
        ThesisDataContainer('instructor/theses/no-todo').then( theses => {
            setTheses(theses);
        });
        TodoListItemsDataContainer(`/todo-list/${id}/items`).then( todoListItems => {
            setTodoListItems(todoListItems);
        })
        getTodoList();
    }, []);

    const updateForm = (e, field) => {
        let formValuesCp = Object.assign({}, formValues);
        formValuesCp[field] = e.target.value;
        setFormValues(formValuesCp);
    }

    /**
     * Change the unique identifier of the form
     * entity.
     *
     * @param {Event} e
     * @param {string} field
     * @param {string} uniq
     */
    const updateFormEntity = (e, field, uniq) => {
        let formValuesCp = Object.assign({}, formValues);
        formValuesCp[field][uniq] = parseInt(e.target.value);
        setFormValues(formValuesCp);
    }

    const getTodoList = () => {
        if (location.state !== undefined) {
            setFormValues(location.state.todoList);
            return;
        }

        TodoListContainer(`/todo-list/${id}`).then( res => {
            setFormValues(res[0]);
        });
    }

    const updateDB = (todoList) => {

        if (hasErrors()) {
            return;
        }

        if (!todoList) {
            todoList = formValues;
        }

        const jwt_token = localStorage.getItem("jwt_token");
        Util.apiCall(config.server, '/v1/instructor/todo-list', "PUT", {
            todoList: {doListDescr: todoList.doListDescr, doListTitle: todoList.doListTitle, doListID: todoList.doListID}, jwt_token}).then( (res) => {
            if (!isNaN(res) && res >= 400) {

            } else {
                setIsSuccessOpen(true);
                setTimeout(() => {
                    setIsSuccessOpen(false);
                },3000);
            }
        })
    }

    const addTodoListItem = (item) => {
        let todoListItemsCp = [...todoListItems];

        console.log(item);

        let order = 1;
        if (todoListItemsCp.length > 0) {
            order = todoListItemsCp[todoListItemsCp.length - 1].dliOrder + 1;
        }

        item.dliOrder = order;
        todoListItemsCp.push(item);

        setTodoListItems(todoListItemsCp);
    }

    const hasErrors = () => {
        return formValues.doListTitle === '';
    }

    const isTodoListItemOnEditingMode = (todoListItem) => {
        if (!todoListItemEditing) {
            return false;
        }
        return todoListItem.dliID === todoListItemEditing.dliID;
    }

    const isThereTodoListItemItemEditing = () => todoListItemEditing !== null

    const getNextAction = (todoListItem) => {
        if (todoListItem.dliStudentOK === 0) {
            return (
                <>
                    <Typography variant="subtitle1">Αναμονή ενέργεια από: </Typography>
                    <Typography variant="subtitle1" color="error">Μαθητή</Typography>
                </>
            )
        } else if (todoListItem.dliInstructorOK === 0) {
            return (
                <>
                    <Typography variant="subtitle1">Αναμονή ενέργεια από: </Typography>
                    <Typography variant="subtitle1" color="error">Καθηγητή</Typography>
                </>
            )
        }
    }

    const editTodoListItem = (item) => {

        /**
         * Search for the edited item and add it where
         * the last item was.
         */
        let pos = 0;
        let todoListItemsCp = [...todoListItems];
        for (let i=0;i<todoListItemsCp.length;i++) {
            if (todoListItemsCp[i].dliID === item.dliID) {
                pos = i;
            }
        }

        todoListItemsCp[pos] = item;
        setTodoListItems(todoListItemsCp);
    }

    const onEditorChange = (s) => {
        let formValuesCp = Object.assign({}, formValues);
        formValuesCp["doListDescr"] = s;
        setFormValues(formValuesCp);
    }

    return (
        <Grid container className="mt-2">
            <Helmet>
                <title>ThesisGate - Επεξεργασία εργασίας</title>
            </Helmet>
            <TodoListMenu breadcrumbItems={
                <Typography>Επεξεργασία {formValues.doListTitle}</Typography>
            }/>
            <Grid item lg={7} xs={12}>
                {
                    Object.keys(formValues).length > 0 ?
                    <div style={{margin:'10px'}}>
                    <Card>
                        <CardHeader
                            title="Επεξεργασία"
                        />
                        <Divider />
                        <CardContent>
                            <Grid container justify="center">
                                <Grid item lg={10} xs={12}>

                                    <Grid container>

                                        <Grid item lg={6}>
                                                <TextField
                                                    error={formValues.doListTitle === ''}
                                                    required
                                                    id="title"
                                                    label="Τίτλος"
                                                    value={formValues.doListTitle}
                                                    onChange={(e) => updateForm(e, "doListTitle")}
                                                />
                                        </Grid>

                                        <Grid container className="mt-2">
                                            <Grid item lg={12} className="mt-2">
                                                <Editor
                                                    initialValue={formValues.doListDescr}
                                                    init={{
                                                    height: 150,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar:
                                                        'undo redo | formatselect | bold italic backcolor | \
                                                        alignleft aligncenter alignright alignjustify | \
                                                        bullist numlist outdent indent | removeformat | help'
                                                    }}
                                                    onEditorChange={onEditorChange}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container className="mt-2">
                                            <Grid item lg={6} className="mt-2">
                                                <Button onClick={() => updateDB()} variant="contained" color="primary">Αποθήκευση</Button>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
                 : <></>
                }
            </Grid>
            <Grid item lg={5} xs={12}>
                <Card style={{margin:'10px', maxHeight:'80vh', overflowY:'scroll'}}>
                    <CardHeader
                        title="Λίστα εργασιών"
                        subheader={
                                formValues.thesisID && formValues.thesisID.thesisID != -1 ?
                                    <Chip label={`Προβλεπόμενη απο εσάς ημ/νία λήξης ${new Date(formValues.thesisID.thesisIntendedEnd.date).toLocaleDateString('el-GR')}`} /> :
                                    <></>
                        }
                    />

                    <CardContent style={{paddingTop: "0px"}}>
                        {
                            formValues.thesisID && formValues.thesisID.thesisID != -1 ?
                                <>
                                <p>Εκτιμώμενη ημερ/νία τέλους: {getEstimatedEndDate(todoListItems, formValues.thesisID.thesisStartDate).toLocaleDateString('el-GR')}</p>
                                    {
                                        getEstimatedTotalTimePercentage(todoListItems, formValues.thesisID.thesisStartDate, formValues.thesisID.thesisIntendedEnd) > 100 ?
                                            <>
                                            <Alert severity="warning">
                                                <AlertTitle>Προειδοποίηση</AlertTitle>
                                                Με τις συγκεκριμένες εργασίες η εργασία δεν θα τελειώσει στο — <strong>εκτιμώμενο χρόνο</strong>
                                            </Alert>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={100}
                                                />
                                                </>
                                                :
                                            <>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={getEstimatedTotalTimePercentage(todoListItems, formValues.thesisID.thesisStartDate, formValues.thesisID.thesisIntendedEnd)}
                                                />
                                            </>
                                    }
                                <p>Πραγματική ημερ/νία τέλους: {getRealEndDate(todoListItems, formValues.thesisID.thesisStartDate).toLocaleDateString('el-GR')}</p>
                                    {
                                        getRealTotalTimePercentage(todoListItems, formValues.thesisID.thesisStartDate, formValues.thesisID.thesisIntendedEnd) > 100 ?
                                            <>
                                                <Alert severity="warning">
                                                    <AlertTitle>Προειδοποίηση</AlertTitle>
                                                    Με τις συγκεκριμένες εργασίες η εργασία δεν θα τελειώσει στο — <strong>εκτιμώμενο χρόνο</strong>
                                                </Alert>
                                                <LinearProgress
                                                    color="secondary"
                                                    variant="determinate"
                                                    value={100}
                                                />
                                            </>
                                            :
                                            <>
                                                <LinearProgress
                                                    color="secondary"
                                                    variant="determinate"
                                                    value={getRealTotalTimePercentage(todoListItems, formValues.thesisID.thesisStartDate, formValues.thesisID.thesisIntendedEnd)} />
                                            </>
                                    }
                                </>
                                : <></>
                        }
                    </CardContent>

                    <CardContent>
                        {
                            todoListItems.length === 0 ?
                            <Typography>Φαίνεται πως δεν έχετε προσθέσει ακόμα αντικείμενα εδώ, παρακαλώ προσθέστε ένα νέο αντικείμενο στη λίστα σας με την φόρμα κάτω αριστερά</Typography>
                            :
                            <></>
                        }
                        {

                            todoListItems.map( (todoListItem, index) => {
                                return (
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls={`panel1a-content-${index}`}
                                            id={index}
                                        >
                                            <>
                                                {
                                                    index > 0 ?
                                                        <IconButton onClick={(e) => {
                                                            e.stopPropagation()
                                                            const doListItems = [...todoListItems];

                                                            // Change order
                                                            const temp = doListItems[index].dliOrder;
                                                            doListItems[index].dliOrder = doListItems[index - 1].dliOrder;
                                                            doListItems[index - 1].dliOrder = temp;

                                                            // Change index
                                                            const doListItem1 = Object.assign({}, doListItems[index]);
                                                            const doListItem2 = Object.assign({}, doListItems[index - 1]);

                                                            doListItems[index] = doListItem2;
                                                            doListItems[index - 1] = doListItem1;

                                                            setCurrentChangingPositionItemIndex(doListItem1.dliID);
                                                            setTodoListItems(doListItems);

                                                            updateOrder(doListItem1.dliID, doListItem1.dliOrder);
                                                            updateOrder(doListItem2.dliID, doListItem2.dliOrder);
                                                        }} size="small" color="primary" aria-label="upload picture" component="span">
                                                            <KeyboardArrowUpIcon />
                                                        </IconButton> : <></>
                                                }

                                                {
                                                    index < todoListItems.length - 1 ?
                                                        <IconButton onClick={(e) => {
                                                            e.stopPropagation()
                                                            const doListItems = [...todoListItems];

                                                            // Change order
                                                            const temp = doListItems[index].dliOrder;
                                                            doListItems[index].dliOrder = doListItems[index + 1].dliOrder;
                                                            doListItems[index + 1].dliOrder = temp;

                                                            // Change index
                                                            const doListItem1 = Object.assign({}, doListItems[index]);
                                                            const doListItem2 = Object.assign({}, doListItems[index + 1]);

                                                            doListItems[index] = doListItem2;
                                                            doListItems[index + 1] = doListItem1;

                                                            setTodoListItems(doListItems);
                                                            setCurrentChangingPositionItemIndex(doListItem1.dliID);

                                                            updateOrder(doListItem1.dliID, doListItem1.dliOrder);
                                                            updateOrder(doListItem2.dliID, doListItem2.dliOrder);

                                                        }} size="small" color="primary" aria-label="upload picture" component="span">
                                                            <KeyboardArrowDownIcon />
                                                        </IconButton> : <></>
                                                }

                                                <Typography style={{padding:'5px', paddingRight: "60px"}}>
                                                    {
                                                        currentChangingPositionItemIndex === todoListItem.dliID ?
                                                            <b>{index + 1}. {todoListItem.dliTitle}</b> :
                                                            `${index + 1}. ${todoListItem.dliTitle}`
                                                    }
                                                </Typography>
                                                <IconButton style={{padding:'5px', position: "absolute", right: "85px"}} onClick={(e) =>
                                                {
                                                    e.stopPropagation();
                                                    const todoListItemsCp = Object.assign([], todoListItems);
                                                    const item = todoListItemsCp.splice(index, 1);
                                                    setTodoListItems(todoListItemsCp);
                                                    deleteDoListItem(item[0].dliID);

                                                }
                                                }>
                                                    <Delete />
                                                </IconButton>
                                                <IconButton style={{padding:'5px', position: "absolute", right: "45px"}} onClick={(e) =>
                                                {
                                                    e.stopPropagation();
                                                    setTodoListItemEditing(todoListItem);
                                                    setTodoListItemAddModalVisible(true);
                                                }
                                                }>
                                                    <EditIcon />
                                                </IconButton>
                                            </>
                                        </AccordionSummary>
                                        <Divider />
                                        <AccordionDetails>
                                            <Grid container item>
                                                <Grid lg={12}>
                                                    <Typography>
                                                        {todoListItem.dliDescr}
                                                    </Typography>
                                                </Grid>
                                                <Grid lg={12}>
                                                    <div className="mt-2">
                                                        {
                                                            getNextAction(todoListItem)
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid lg={12}>
                                                    <div className="mt-2">
                                                        <Typography color="primary" variant="subtitle1">Διάρκεια: {todoListItem.dliDuration} {todoListItem.dliDurationUnit}</Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })
                        }
                        <CardActions disableSpacing>
                            <IconButton
                                onClick={() => {setTodoListItemAddModalVisible(true);setTodoListItemEditing(null)}} aria-label="add to favorites">
                                <AddCircleIcon />
                            </IconButton>
                        </CardActions>

                        <Dialog open={todoListItemAddModalVisible} onClose={() => setTodoListItemAddModalVisible(false)} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">
                                {
                                    isThereTodoListItemItemEditing() ?
                                        "Επεξεργασία todolist item" :
                                        "Δημιουργία todolist item"
                                }
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    To subscribe to this website, please enter your email address here. We will send updates
                                    occasionally.
                                </DialogContentText>
                                <CreateTodoListItem
                                    maxOrder={todoListItems.length > 0 ? todoListItems[todoListItems.length - 1].dliOrder : 0}
                                    setTodoListItemEditing={(item) => {setTodoListItemEditing(item)}}
                                    todoListItem={todoListItemEditing}
                                    todoList={formValues}
                                    addTodoListItem={(items) => {addTodoListItem(items)}}
                                    editTodoListItem={(item) => {editTodoListItem(item)}}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setTodoListItemAddModalVisible(false)} color="primary">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </CardContent>
                </Card>
            </Grid>

            <Snackbar open={isSuccessOpen}>
                <Alert severity="success">
                    Η todo-list σας έχει αποθηκευτεί
                </Alert>
            </Snackbar>

        </Grid>
    )
}