import React, {useState} from "react";
import {
    Card,
    CardContent,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    LinearProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import ThesisCardFieldParts from "../ThesisCardFieldParts";
import {
    getCompletedDoListItemsDuration,
    getDoListItemCompletionDays
} from "../../../../../DataTypes/Models/TodoList/TodoList";
import {DeleteForever, Mail} from "@material-ui/icons";
import WarningIcon from '@material-ui/icons/Warning';
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardMedia from "@material-ui/core/CardMedia";
import Badge from "@material-ui/core/Badge";
import Util from "../../../../../Util/Util";
import config from "../../../../../config";
import ImageRetriever from "../../../../../DataTypes/ImageRetriever";

const DEFAULT_FIELDS = [
    ThesisCardFieldParts.title,
    ThesisCardFieldParts.intentedDate,
    ThesisCardFieldParts.assignedTo,
    ThesisCardFieldParts.keywords
];

const useStyles = makeStyles(() => ({
    root: {
        position: "relative",
        height: "260px",
    },
    cover: {
        width: 251,
        height: "100%",
        position: "absolute",
        objectFit: "cover",
        left: "0"
    },
}));

/**
 * Presentational layer of thesis cards.
 *
 * @param {Thesis} param0
 */
export default function ThesisCardLeftImage({thesis, fields, isEditable, viewRoute, deleteThesis}) {

    if (!fields) {
        fields = DEFAULT_FIELDS;
    }

    const classes = useStyles();

    const [isDeleteDoListItemDialogOpen, setIsDeleteDoListItemDialogOpen] = useState(false);

    const isPublic = () => {
        if (thesis.student !== null && thesis.student !== '') {
            return thesis.thesisIsPublicByStu;
        }
        return thesis.thesisIsPublicByInst === "1";
    }

    const isStudentAssigned = () => {
        return (thesis.student !== null && thesis.student !== '');
    }

    const doesHaveType = () => {
        return thesis.thesisAssignType !== null;
    }

    const getCurrentTask = () => {
        if (!thesis.doList) {
            return 'Η διατριβή δεν έχει λίστα υποχρεώσεων';
        }

        let currentTask = null;
        for (let i=0;i<thesis.doList.doListItems.length;i++) {
            if (thesis.doList.doListItems[i].dliInstructorOK === 0 || thesis.doList.doListItems[i].dliStudentOK === 0) {
                currentTask = thesis.doList.doListItems[i].dliTitle;
                break;
            }
        }

        if (!currentTask) {
            currentTask = "Ολοκληρώθηκαν οι υποχρεώσεις"
        }

        return currentTask;
    }


    // Get end date for each one.
    let doListItemDays = [];
    let completedDays = 0;

    if (thesis.doList) {
        doListItemDays = getDoListItemCompletionDays(thesis.doList.doListItems);
        completedDays = getCompletedDoListItemsDuration(thesis.doList.doListItems);
    }


    return (
        <Grid item lg={12} md={6}>
            <Card className={classes.root} style={{margin:'0px 5px 15px 15px'}}>
                <div style={{position: "absolute", top: "0", right: "0"}}>
                    {
                        isEditable ?
                            <>
                                <Link
                                    to={{
                                        pathname: `/dashboard/instructor/thesis/edit/${thesis.thesisID}`,
                                        thesis
                                    }}
                                >
                                    <Tooltip title="Επεξεργασία">
                                        <IconButton onClick={() => {
                                        }}>
                                            <EditIcon color="primary"/>
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                                <Tooltip title="Διαγραφή">
                                    <IconButton onClick={() => {
                                        setIsDeleteDoListItemDialogOpen(true);
                                    }}>
                                        <DeleteForever color="secondary"/>
                                    </IconButton>
                                </Tooltip>
                                {thesis.doList === null ?
                                    <Tooltip
                                        title="Δεν περιέχει todo-list. Χωρίς αυτήν οι μαθητές δε θα μπορούν να πάρουν μέρος στην διατριβή">
                                        <IconButton>
                                            <WarningIcon style={{color: "#f1c40f"}}/>
                                        </IconButton>
                                    </Tooltip> : <></>
                                }
                                {fields.includes(ThesisCardFieldParts.prefersTheses) ?
                                    <>
                                        <Tooltip title={

                                                thesis.prefersTheses.map( thesis => {
                                                    return <p>{thesis.students.uName} {thesis.students.uSurname}</p>
                                                })

                                        }>
                                            <IconButton>
                                                <Badge showZero={false} style={{fontSize: "10px"}} badgeContent={thesis.prefersTheses.length} color="secondary">
                                                    <Mail/>
                                                </Badge>
                                            </IconButton>
                                        </Tooltip>
                                    </> : <></>
                                }
                                </>
                            : <></>
                    }

                </div>
                <CardMedia
                    className={classes.cover}
                    image={ImageRetriever.retrieve(thesis.featuredImageID)}
                    title="Live from space album cover"
                />
                <CardContent style={{marginLeft:"251px"}}>
                    <Link
                        style={{borderBottom: "none !important"}}
                        to={{
                            pathname: `/${viewRoute}/${thesis.thesisID}`,
                            thesis
                        }}>
                        <Typography variant="h5">{thesis.thesisTitle}</Typography>
                    </Link>

                        <Typography variant="caption">
                            Επιβλέπων Καθηγητής: {thesis.instructor.uName + " " + thesis.instructor.uSurname}
                            {
                                fields.includes(ThesisCardFieldParts.assignedTo) && isStudentAssigned() ?

                                        <><br />Του/Της: &#x200b;
                                            <span>
                                            <Link to={
                                                {
                                                    pathname: `/dashboard/instructor/profile/preview/${thesis.student.username}`,
                                                    profile: thesis.student
                                                }
                                            } >
                                                <span>{thesis.student.uName + " " + thesis.student.uSurname}</span>
                                            </Link>
                                        </span>
                                    </>
                                    : <></>
                            }
                        </Typography><br />

                        <div style={{marginTop: "10px"}}>

                    {
                        fields.includes(ThesisCardFieldParts.keywords) ?
                            thesis.keywords.map( keyword => {
                                return <Chip variant='outlined' style={{fontSize: "12px", margin: "3px"}} size="small" label={keyword.keyword} />
                            }) : <></>
                    }

                        </div>

                    <div style={{marginTop: "10px"}}>
                        <Typography>
                        {Util.StripHtmlTags(thesis.thesisDescr).slice(0, 200)}...
                        </Typography>
                    </div>

                    {
                        isStudentAssigned() && !thesis.thesisMark ?
                    <>
                        <Grid container style={{marginTop: "15px"}}>
                            <Grid item lg={6}>
                                <Typography variant="caption">Ολοκλήρωση (% σε χρόνο): <b>{parseFloat(100 - ((doListItemDays[doListItemDays.length - 1] - completedDays) / doListItemDays[doListItemDays.length - 1]) * 100).toFixed(2)}%</b></Typography>
                                {
                                    doListItemDays.length > 0 ?
                                        <>
                                                <LinearProgress
                                                           style={{marginTop: "5px", height: "10px"}} variant="determinate"
                                                           value={100 - ((doListItemDays[doListItemDays.length - 1] - completedDays) / doListItemDays[doListItemDays.length - 1]) * 100}
                                                       />
                                        </>
                                        : <></>
                                }
                            </Grid>
                        </Grid>
                    </> :
                    <div style={{position: "absolute", bottom: "15px"}}>
                        <Grid container>
                        {
                            thesis.thesisIntendedEnd !== null && fields.includes(ThesisCardFieldParts.intentedDate) ?

                                    <Chip label={`Προθεσμία ${new Date(thesis.thesisIntendedEnd).toLocaleDateString('el-GR')}`} />
                                : <></>
                        }
                        </Grid>
                    </div>
                    }

                    {isStudentAssigned() && !thesis.thesisMark ?
                        <div style={{position: "absolute", bottom: "15px"}}>
                            <Chip label={`Ημερ/νια έναρξης: ${new Date(thesis.thesisStartDate).toLocaleDateString('el-GR')}`}/>
                            <Chip style={{marginLeft: "10px"}} label={`Τρέχον Εργασία ${getCurrentTask()}`}/>
                        </div> : <></>
                    }

                    {
                        isEditable ?
                            <Dialog
                                open={isDeleteDoListItemDialogOpen}
                                onClose={() => setIsDeleteDoListItemDialogOpen(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Διαγραφή διατριβής;"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Θέλετε σίγουρα να διαγράψετε οριστικά αυτήν την διατριβή;
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => {
                                        setIsDeleteDoListItemDialogOpen(false);
                                    }} color="primary">
                                        Ακύρωση
                                    </Button>
                                    <Button onClick={() => {
                                        setIsDeleteDoListItemDialogOpen(false);
                                        deleteThesis(thesis.thesisID);
                                    }} color="primary" autoFocus>
                                        Αποδοχή
                                    </Button>
                                </DialogActions>
                            </Dialog> : <></>
                    }

                </CardContent>

            </Card>
        </Grid>

    )
}