import {
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {deleteTodoListItem} from "../../../../../DataTypes/Models/TodoList/TodoList";
import React, {useState} from "react";
import {Delete} from "@material-ui/icons";

export default function DeleteDoListItem({thesis, setThesis}) {

    const [isDeleteDoListItemDialogOpen, setIsDeleteDoListItemDialogOpen] = useState(false);

    return (
        <>
        <IconButton
            variant="contained"
            color="secondary"
            onClick={() => {
                setIsDeleteDoListItemDialogOpen(true)
            }}
        >
            <Delete />
        </IconButton>
        <Card>
            <Dialog
                open={isDeleteDoListItemDialogOpen}
                onClose={() => setIsDeleteDoListItemDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Διαγραφή λίστας;"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Οτιδήποτε σχετίζεται με αυτήν την λίστα όπως όλες οι ενέργειες του μαθητή
                        θα διαγραφτούν.

                        θέλετε να συνεχίσετε;
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setIsDeleteDoListItemDialogOpen(false);
                    }} color="primary">
                        Ακύρωση
                    </Button>
                    <Button onClick={() => {
                        setIsDeleteDoListItemDialogOpen(false);
                        deleteTodoListItem(thesis.doList.doListID).then( () => {
                            const userThesis = Object.assign({}, thesis);
                            userThesis.doList = null;
                            setThesis(userThesis);
                        })
                    }} color="primary" autoFocus>
                        Αποδοχή
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
            </>
    )
}