import React, {useState, useEffect} from "react";
import config from "../config";
import ProfileTemplate from "./Instructor/templates/ProfileTemplate";
import { CardContent, Divider, Grid, Typography } from "@material-ui/core";
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import UserDataContainer from "./DataContainers/UserDataContainer";
import {Helmet} from "react-helmet";

export default function PreviewProfile(props) {

    const [profile, setProfile] = useState({});

    const location = useLocation();
    const {id} = useParams();

    useEffect(() => {
        if (location.hasOwnProperty('profile')) {
            setProfile(location.profile);
        } else {
            // Find the requested profile
            UserDataContainer(`instructor/profile/${id}`).then( student => {
                setProfile(student[0]);
            })
        }
    }, []);

    return (
        <ProfileTemplate>
            <Helmet>
                <title>ThesisGate - Προφίλ</title>
            </Helmet>
            <Divider />
            {
                Object.keys(profile).length > 0 ?
                    <CardContent>
                        <Grid container>

                            <Grid item lg={12}>
                                <Typography variant="h4">Πληροφορίες λογαριασμού</Typography>
                            </Grid>

                            <Grid item lg={2}>
                                <p>Ονοματεπώνυμο</p>
                            </Grid>
                            <Grid item lg={10}>
                                <p>{profile.uName} {profile.uSurname}</p>
                            </Grid>

                            <Grid item lg={2}>
                                <p>Email</p>
                            </Grid>
                            <Grid item lg={10}>
                                <p>{profile.uEmail}</p>
                            </Grid>

                            <Grid container className="mt-2">
                                <Grid item lg={2}>
                                    <Typography><b>Βιογραφικό</b></Typography>
                                </Grid>
                                {
                                    profile.cvFilename ?
                                        <Grid item lg={3}>
                                            <Typography color="primary"><a href={`${config.server}/user/profile/cv/${profile.username}?jwt_token=${localStorage.getItem("jwt_token")}`} download>{profile.cvFilename}</a></Typography>
                                        </Grid> : <></>
                                }
                            </Grid>
                        </Grid>
                    </CardContent> : <></>
            }
        </ProfileTemplate>
    )
}