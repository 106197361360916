import {BrowserRouter, Route} from 'react-router-dom';
import UserAuthentication from './Components/UserAuthentication';
import AuthenticateEmail from './Components/AuthenticateEmail';
import CreateThesis from "./Components/Instructor/thesis/CreateThesis";
import OverviewThesis from "./Components/Instructor/thesis/OverviewThesis";
import EditThesis from "./Components/Instructor/thesis/EditThesis";
import CreateTodoList from './Components/Instructor/ToDoLists/CreateTodoList';
import TodoListEdit from './Components/Instructor/ToDoLists/TodoListEdit';
import TodoOverview from './Components/Instructor/ToDoLists/TodoOverview';
import CreateTodoListItem from './Components/Instructor/ToDoLists/todoListItem/CreateTodoListItem';
import SingleThesis from "./Components/Instructor/thesis/SingleThesis";
import ThesisCardFieldParts from "./Components/Instructor/thesis/parts/ThesisCardFieldParts";
import InsProfile from './Components/Profile/InsProfile';
import UserDataContainer from "./Components/DataContainers/UserDataContainer";
import Dashboard from "./Components/Dashboard";
import DesktopMenu from "./Components/Instructor/Menu/DesktopMenu";
import StudentDesktopMenu from "./Components/Student/Menu/StudentDesktopMenu";
import FilterString from "./Util/Filter/FilterString";
import FilterIsNull from "./Util/Filter/FilterIsNull";
import Login from "./Components/Login";
import Register from "./Components/Register";
import MyThesis from "./Components/Student/MyThesis/MyThesis";
import PreviewProfile from "./Components/PreviewProfile";
import ThesesOverview from "./Components/Instructor/Overview/ThesesOverview";
import React from "react";
import {AccessDenied} from "./Components/AccessDenied";
import { ThemeProvider } from '@material-ui/styles';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {Grid, Typography} from "@material-ui/core";
import {ThesisMenu} from "./Components/Instructor/thesis/ThesisMenu";
import BreadcrumbTemplate from "./Components/Instructor/templates/BreadcrumbTemplate";
import AdminMenu from "./Components/Admin/Menu/AdminMenu";
import AdminProfile from "./Components/Admin/AdminProfile";
import DepartmentControl from "./Components/Admin/Department/DepartmentControl";
import InstitutionControl from "./Components/Admin/Institution/InstitutionControl";
import UserControl from "./Components/Admin/User/UserControl";

const UNGIVEN_THESIS_FIELDS = [
  ThesisCardFieldParts.title,
  ThesisCardFieldParts.intentedDate,
  ThesisCardFieldParts.prefersTheses,
    ThesisCardFieldParts.keywords,
    ThesisCardFieldParts.description
];

const OPEN_STUDENT_THESIS_FIELDS = [
    ThesisCardFieldParts.title,
    ThesisCardFieldParts.intentedDate,
    ThesisCardFieldParts.keywords,
    ThesisCardFieldParts.instructorInfo
]

const FINISHED_THESIS_FIELD = [
    ThesisCardFieldParts.title,
];

const STUDENT_THESIS_VIEW_ROUTE = "dashboard/student/thesis/single";
const INSTRUCTOR_THESIS_VIEW_ROUTE = "dashboard/instructor/thesis/single";

const THEME = createMuiTheme({
    typography: {
        fontFamily: [
            '"Open Sans"',
            'Arial'
        ].join(','),
        h5: {
            fontFamily: [
                '"Roboto Slab"',
                'Arial'
            ].join(','),
        },
        h6: {
            fontFamily: [
                '"Roboto Slab"',
                'Arial'
            ].join(','),
        }
    },
});

function App() {
  return (
      <ThemeProvider theme={THEME}>
        <BrowserRouter>
          <Route exact path="/">
              <UserAuthentication />
              <Login />
          </Route>
          <Route exact path="/register"><UserAuthentication /><Register /></Route>
          <Route exact path="/authenticateEmail/:email"><AuthenticateEmail /></Route>

            <Route exact path="/unathorized">
                <AccessDenied />
            </Route>

            <Route path="/dashboard/instructor">
                <UserAuthentication userType="ins">
                    <Dashboard  menu={<DesktopMenu />}>
                          <Route exact path="/dashboard/instructor">

                          </Route>
                        <Route exact path="/dashboard/instructor/overview">
                            <ThesesOverview />
                        </Route>
                        <Route exact path="/dashboard/instructor/profile/:id?">
                            <InsProfile
                              userType="ins"
                              dataContainer={() => {return UserDataContainer('instructor/profile')}}
                              editUserApi="/v1/instructor/profile/edit"
                          />
                      </Route>

                        <Route path="/dashboard/instructor/profile/preview/:id">
                                <PreviewProfile />
                        </Route>

                      <Route exact path="/dashboard/instructor/thesis/create">
                          <CreateThesis />
                      </Route>

                      <Route exact path="/dashboard/instructor/theses">
                          <OverviewThesis
                              cardTitle="Διατριβές σε εξέλιξη"
                              viewRoute={INSTRUCTOR_THESIS_VIEW_ROUTE}
                              isEditable={true}
                              apiRoute="instructor/theses"
                              breadcrumb={
                                  <ThesisMenu breadcrumbItems={
                                      <Typography>
                                          Διατριβές σε εξέλιξη
                                      </Typography>
                                  } />
                              }
                          />
                      </Route>

                      <Route exact path="/dashboard/instructor/theses/ungiven">
                          <OverviewThesis
                              cardTitle="Διατριβές προς ανάθεση"
                              viewRoute={INSTRUCTOR_THESIS_VIEW_ROUTE}
                              isEditable={true}
                              fields={UNGIVEN_THESIS_FIELDS}
                              apiRoute="instructor/theses/ungiven"
                              breadcrumb={
                                  <ThesisMenu breadcrumbItems={
                                      <Typography>
                                          Διατριβές προς ανάθεση
                                      </Typography>
                                  } />
                              }
                          />
                      </Route>

                      <Route exact path="/dashboard/instructor/theses/ended">
                          <OverviewThesis
                              fields={FINISHED_THESIS_FIELD}
                              cardTitle="Ολοκληρωμένες διατριβές"
                              viewRoute={INSTRUCTOR_THESIS_VIEW_ROUTE}
                              isEditable={true}
                              apiRoute="instructor/theses/ended"
                              breadcrumb={
                                  <ThesisMenu breadcrumbItems={
                                      <Typography>
                                          Ολοκληρωμένες διατριβές
                                      </Typography>
                                  } />
                              }
                          />
                      </Route>
                      <Route exact path="/dashboard/instructor/thesis/edit/:id">
                          <EditThesis />
                      </Route>

                      <Route exact path="/dashboard/instructor/todo-lists">
                          <TodoOverview />
                      </Route>


                      <Route exact path="/dashboard/instructor/todo-list/create">
                          <CreateTodoList />
                      </Route>

                      <Route exact path="/dashboard/instructor/todo-list/edit/:id">
                              <TodoListEdit />
                      </Route>

                      <Route exact path="/dashboard/instructor/todo-list/item/create">
                          <CreateTodoListItem />
                      </Route>
                        <Route exact path="/dashboard/instructor/thesis/single/:id">
                            <SingleThesis dataContainer={() => UserDataContainer('instructor/profile')} userType="instructor" />
                        </Route>

                    </Dashboard>
                </UserAuthentication>
                {/*StudentRoutes*/}
            </Route>

            <Route path="/dashboard/student">
                <UserAuthentication userType="stu">
                    <Dashboard menu={<StudentDesktopMenu />}>
                        <Route exact path="/dashboard/student/profile/:id?">
                            <InsProfile
                                userType="stu"
                                dataContainer={() => {return UserDataContainer('student/profile')}}
                                editUserApi="/v1/student/profile/edit"
                            />
                        </Route>

                        <Route exact path="/dashboard/student/thesis/current">
                            <MyThesis />
                        </Route>

                        <Route exact path="/dashboard/student/theses/open">
                            <OverviewThesis
                                breadcrumb={
                                    <Grid item lg={12}>
                                        <BreadcrumbTemplate items={[<Typography>Διατριβές</Typography>,<Typography>Ανοιχτές Θέσεις</Typography>]} />
                                    </Grid>
                                }
                                cardTitle="Ανοιχτες θέσεις"
                                allFilters={
                                    {
                                        thesisAssignType: new FilterString("=", "thesisAssignType"),
                                        isOngoing: new FilterIsNull(null, "student")
                                    }
                                }
                                fields={OPEN_STUDENT_THESIS_FIELDS}
                                viewRoute={STUDENT_THESIS_VIEW_ROUTE}
                                isEditable={false}
                                apiRoute="student/theses/open" />
                        </Route>

                        <Route exact path="/dashboard/student/thesis/single/:id">
                            <SingleThesis dataContainer={() => UserDataContainer('student/profile')} userType="student" />
                        </Route>
                    </Dashboard>
                </UserAuthentication>
            </Route>

            <Route path="/dashboard/admin">
                <UserAuthentication userType="adm">
                    <Dashboard menu={<AdminMenu />}>
                        <Route exact path="/dashboard/admin/profile">
                            <AdminProfile
                                userType="stu"
                                dataContainer={() => {return UserDataContainer('student/profile')}}
                                editUserApi="/v1/student/profile/edit"
                            />
                        </Route>

                        <Route exact path="/dashboard/admin/departments">
                           <DepartmentControl />
                        </Route>
                        <Route exact path="/dashboard/admin/institutions">
                            <InstitutionControl />
                        </Route>
                        <Route exact path="/dashboard/admin/users">
                            <UserControl />
                        </Route>

                    </Dashboard>
                </UserAuthentication>
            </Route>

            {/*All user routes*/}
        </BrowserRouter>
      </ThemeProvider>
  );
}

export default App;
