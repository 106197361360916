import React from "react";
import {Grid} from "@material-ui/core";
import InstructionActionThesis from "./InstructionActionThesis";
import InstructorNewThesesRequests from "./InstructorNewThesesRequests";
import InstructorThesisExpiring from "./InstructorThesisExpiring";
import {Helmet} from "react-helmet";

export default function ThesesOverview() {
    return (
        <Grid container>
            <Helmet>
                <title>ThesisGate - Overview</title>
            </Helmet>
            <Grid item xs={6}>
                <InstructionActionThesis />
            </Grid>
            <Grid item xs={6}>
                <InstructorNewThesesRequests />
            </Grid>
            <Grid item xs={6}>
                <InstructorThesisExpiring />
            </Grid>
        </Grid>
    )
}