import config from "../../config";
import Util from "../../Util/Util";
import User from "../../DataTypes/Models/Users/User";

export default async function UserDataContainer(route) {
    let users = [];
    const jwt_token = localStorage.getItem("jwt_token");
    await Util.apiCall(config.server, `/v1/${route}?jwt_token=${jwt_token}`, "GET").then( res => {
        res.map( item => {
            const user = new User(
                item.username,
                item.uName,
                item.uSurname,
                item.uEmail,
                item.uIsPublic,
                item.cvFilename,
                item.keywords
            );
            users.push(user);
        });
    });
    return users;
}