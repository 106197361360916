import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import {Grid} from "@material-ui/core";
import Util from "../../../../Util/Util";
import config from "../../../../config";
import ImageRetriever from "../../../../DataTypes/ImageRetriever";

export default function MediaChooserPopup({isOpen = true, onClose, onMediaChose}) {

    const [imageChosen, setImageChose] = useState(null);
    const [imageLinks, setImageLinks] = useState([]);

    useEffect(() => {
        const jwt_token = localStorage.getItem('jwt_token');
        Util.apiCall(config.server, `/v1/instructor/department/images?jwt_token=${jwt_token}`, 'GET', {}).then( res => {
            setImageLinks(res);
        });
    }, [isOpen]);

    return (
        <Dialog open={isOpen} maxWidth="lg" onClose={() => {onClose()}} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Επιλογή Φωτογραφίας</DialogTitle>
            <DialogContent>
                <div style={{display: "flex", flexWrap: "wrap"}}>
                    {
                        imageLinks.map( item => {
                            return (
                                <img
                                    onClick={() => setImageChose(item)}
                                    height={140}
                                    style={{cursor: "pointer", objectFit: "cover", margin: "5px", border: item === imageChosen ? "2px solid red" : "none"}}
                                    width={140}
                                    src={ImageRetriever.retrieve(item)} />
                            )
                        })
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {onClose()}} color="primary">
                    Ακύρωση
                </Button>
                <Button disabled={imageChosen === null} onClick={() => {onMediaChose(imageChosen)}} color="primary">
                    Αποδοχή
                </Button>
            </DialogActions>
        </Dialog>
    )
}