import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import InstituteModel from "../../../DataTypes/Models/Institute/Institute.model";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DepartmentModel, {Department} from "../../../DataTypes/Models/Department/Department.model";

export function DepartmentNewModel({isOpen = true, onSave, onClose}) {

    const [institutions, setInstitutions] = useState([]);
    const [selectedInst, setSelectedInst] = useState(null);

    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [city, setCity] = useState(null);
    const [institute, setInstitute] = useState(null);
    const [lng, setLng] = useState(null);
    const [lat, setLat] = useState(null);
    const [url, setUrl] = useState(null);

    useEffect(() => {

        if (institutions.length === 0) {
            InstituteModel.getAll().then( res => {
                setInstitutions(res);
                if (res.length > 0) {
                    setSelectedInst(res[0].instID);
                }
            })
        }

        return {

        }
    }, [open]);

    const saveDepartment = () => {
        let inst = null;
        for (let i=0;i<institutions.length;i++) {
            if (institutions[i].instID === selectedInst) {
                inst = institutions[i];
            }
        }
        const dep = new Department(null, name, email, phone, city, url, inst, lat, lng);
        DepartmentModel.save(Object.assign({}, dep)).then(r => {
            dep.id = r['id'];
            onSave(dep);

            resetForm();
        });
    }

    const resetForm = () => {
        setName(null);
        setEmail(null);
        setLat(null);
        setCity(null);
        setLng(null);
        setPhone(null);
        setUrl(null);
    }

    return (
            <Dialog fullWidth={true} onClose={() => {onClose();resetForm()}} open={isOpen} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Προσθήκη νέου</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="depName"
                                label="Όνομα"
                                value={name}
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <TextField
                                autoFocus
                                value={email}
                                margin="dense"
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                label="Email"
                                type="Email"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="phone"
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                                label="Phone"
                                type="phone"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="city"
                                onChange={(e) => setCity(e.target.value)}
                                value={city}
                                label="City"
                                type="text"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            {
                                institutions.length > 0 ?
                                    <FormControl>
                                        <InputLabel id="institute">Ινστιτούτο</InputLabel>
                                        <Select
                                            value={selectedInst}
                                            labelId="institute"
                                            id="institute"
                                            onChange={(val) => {
                                                setSelectedInst(val.target.value);
                                            }}
                                            fullWidth
                                        >
                                            {
                                                institutions.map( institute => {
                                                    return <MenuItem value={institute.instID}>{institute.instName}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl> : <></>
                            }
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                onChange={(e) => setUrl(e.target.value)}
                                id="url"
                                label="URL"
                                type="text"
                                value={url}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="lng"
                                onChange={(e) => setLng(e.target.value)}
                                label="Longitude"
                                type="number"
                                value={lng}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="lat"
                                onChange={(e) => setLat(e.target.value)}
                                value={lat}
                                label="Longitude"
                                type="number"
                                fullWidth
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {onClose();resetForm()}} color="primary">
                        Ακύρωση
                    </Button>
                    <Button onClick={() => {
                        saveDepartment()
                    }} color="primary">
                        Αποθήκευση
                    </Button>
                </DialogActions>
            </Dialog>
    )
}