import { Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, Typography, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText } from '@material-ui/core';
import React, {useState} from 'react';

import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import {DeleteForever} from "@material-ui/icons";
import {deleteTodoListItem} from "../../../../../DataTypes/Models/TodoList/TodoList";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiCardHeader-root": {
            margin: "0px",
            padding: "8px 16px"
        },
    },
}));

export default function TodoList(props) {

    const classes = useStyles();

    const todo = props.todo;
    const [isDeleteDoListItemDialogOpen, setIsDeleteDoListItemDialogOpen] = useState(false);

    const handleDoListItemDelete = () => {
        setIsDeleteDoListItemDialogOpen(true);
    }
    
    return (
        <Grid lg={4}>
            <Card className={classes.root} style={{margin:'0px 10px'}}>
                <CardHeader
                    title={<Typography><b>{todo.doListTitle}</b></Typography>}
                    action={
                        <>

                        <Link
                        to={{
                            pathname: `/dashboard/instructor/todo-list/edit/${todo.doListID}`,
                            todo
                        }} 
                            >
                            <IconButton onClick={() => {
                            }}>
                            <EditIcon />
                                
                            </IconButton>
                        </Link>

                                <IconButton onClick={() => handleDoListItemDelete()}>
                                    <DeleteForever />

                                </IconButton>
                        </>
                    }
                />
                <Divider />
                <CardContent>
                    <div className="mt-2">
                        <Typography variant="caption" color="textSecondary">Τελευταία τροποποίηση {todo.doListWhen.split(' ')[0]}</Typography>
                    </div>
                </CardContent>
                <Divider />
                { todo.thesisID.thesisID !== -1 ?
                    <CardContent>
                        <><Typography>Ανήκει στην διατριβή: </Typography><Typography color="primary"><Link to={{
                            pathname: `/dashboard/instructor/thesis/edit/${todo.thesisID.thesisID}`,
                            thesis: todo.thesisID
                        }}>{todo.thesisID.thesisTitle}</Link></Typography></> : <></>
                    </CardContent> : <></>
                }
            </Card>

            <Dialog
                open={isDeleteDoListItemDialogOpen}
                onClose={() => setIsDeleteDoListItemDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Διαγραφή λίστας;"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Εάν η λίστα είναι συνδεδεμένη με διατριβή το άτομο που έχει την διατριβή θα
                        χάσει την πρόοδο του καθώς και τα αρχεία ανεβασμένα σε αύτη.

                        θέλετε να συνεχίσετε;
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setIsDeleteDoListItemDialogOpen(false);
                    }} color="primary">
                        Ακύρωση
                    </Button>
                    <Button onClick={() => {
                        setIsDeleteDoListItemDialogOpen(false);
                        deleteTodoListItem(todo.doListID).then( () => {
                            props.todoListItemDelete(todo.doListID)
                        })
                    }} color="primary" autoFocus>
                        Αποδοχή
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}