import { Button, Paper, Chip, Grid, ButtonGroup } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import AcceptThesisDialog from "./AcceptThesisDialog";
import Util from "../../../Util/Util";
import config from "../../../config";

export default function PreferedThesis(props) {

    const [isAcceptDialogOpen, setIsAcceptDialogOpen] = useState(false);
    const [selectedThesis, setSelectedThesis] = useState(null);

    const getInstructorName = (thesis) => {
        return thesis.instructor.uName + " " + thesis.instructor.uSurname;
    }

    const handleAcceptThesis = () => {
        setIsAcceptDialogOpen(false);
        const jwt_token = localStorage.getItem("jwt_token");
        Util.apiCall(config.server, `/v1/student/preferedThesis/assign/${selectedThesis}`, "PUT", {jwt_token}).then( res => {
            if(res.hasOwnProperty("thesisID")) {
                props.setThesis(res);
            }
        })
    }

    return props.preferedThesis.map( prefThesis => {
        let prefThesisAct = prefThesis[0];
        let isAssigned = prefThesis.isAssigned;
        return (
        <Paper elevation={1} style={{width:"80%", padding: '10px', margin: "10px"}}>
            <Grid container>
                <Grid item xs={6}>
                    <Link
                    to={{
                        pathname: `/dashboard/student/thesis/single/${prefThesisAct.thesisID.thesisID}`,
                    }}
                    >
                        {prefThesisAct.thesisID.thesisTitle}
                    </Link> του καθηγητή {getInstructorName(prefThesisAct.thesisID)}.
                </Grid>
                <Grid item xs={6}>
                    <Chip color={isAssigned ? "primary" : ""} label={isAssigned ? "Ο καθηγητής απάντησε θετικά στο αίτημα σας" : "Ο καθηγητής δεν έχει απαντήσει ακόμα στο αίτημα σας"} /> 
                </Grid>
                <Grid item xs={6}>
                    {
                        isAssigned ?
                    
                        <ButtonGroup style={{marginTop: "10px"}} size="small" variant="contained" color="primary">
                            <Button onClick={() => {setIsAcceptDialogOpen(true);setSelectedThesis(prefThesisAct.thesisID.thesisID)}}>Αποδοχή</Button>
                            <Button>Ακύρωση</Button>
                        </ButtonGroup>
                        :<></>
                    }
                </Grid>
            </Grid>

            <AcceptThesisDialog open={isAcceptDialogOpen} handleAccept={() => handleAcceptThesis()} handleCancel={() => setIsAcceptDialogOpen(false)} />
            
        </Paper>
      )
    })
}