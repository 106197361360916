import Util from "../../../Util/Util";
import config from "../../../config";

export class Institute {

    static types = {
        uni: "grUni",
        tei: "grTEI"
    }

    constructor(id, name, type, url, logo, departments = []) {
        this.instID = id;
        this.instName = name;
        this.instType = type;
        this.instURL = url;
        this.instLogo = logo;
        this.departments = departments;
    }
}

export default class InstituteModel {
    /**
     * @returns {Promise<Department[]>}
     */
    static getAll() {
        const jwt_token = localStorage.getItem('jwt_token');
        return Util.apiCall(config.server, `/v1/user/institutes?jwt_token=${jwt_token}`, "GET");
    }

    /**
     *
     * @param {Institute} institute
     */
    static save(institute) {
        const jwt_token = localStorage.getItem('jwt_token');
        return Util.apiCall(config.server, '/v1/admin/institute', 'POST', {...institute, jwt_token});
    }

    static delete(instID) {
        const jwt_token = localStorage.getItem('jwt_token');
        return Util.apiCall(config.server, `/v1/admin/institute/${instID}`, 'DELETE',  {jwt_token});
    }

    /**
     *
     * @param {Institute} inst
     * @returns {Promise<*>}
     */
    static edit(inst) {
        const jwt_token = localStorage.getItem('jwt_token');
        return Util.apiCall(config.server, `/v1/admin/institute/${inst.instID}`, 'PUT',  {jwt_token, ...inst});
    }
}