import {Button, Card, CardContent, Grid} from "@material-ui/core";
import ImageRetriever from "../../../DataTypes/ImageRetriever";
import {Save, Send} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import config from "../../../config";
import MediaChooserPopup from "./parts/MediaChooserPopup";
import {updateThesisImage} from "../../../DataTypes/Models/Thesis/Thesis";
import CropOriginalIcon from '@material-ui/icons/CropOriginal';

export default function EditThesisImage({featuredImageID, thesisID}) {

    const [selectedFile, setSelectedFile] = useState(featuredImageID);
    const [imageChanged, setImageChanged] = useState(false);
    const [isMediaOpen, setIsMediaOpen] = useState(false);

    useEffect(() => {
        setSelectedFile(featuredImageID);
    }, [featuredImageID]);

    return (
        <Grid item lg={12} style={{
            overflowY: "auto",
            padding: "0 10px"
        }}>
            <Card style={{marginRight: "11px", marginTop: "10px"}}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <p>Εικόνα Προεπισκόπησης</p>

                            {
                                selectedFile !== undefined ?
                                    <img id="preview-img" style={{objectFit: "cover"}}
                                         src={ImageRetriever.retrieve(selectedFile)} width={180} height={180}/>
                                    : <></>
                            }
                            <br />

                        </Grid>
                        <Grid item xs={12}>
                            <p>Εικόνα Διατριβής</p>
                            {
                                selectedFile !== undefined ?
                                    <img id="cover-img" src={ImageRetriever.retrieve(selectedFile)}
                                         style={{objectFit: "cover"}} width={440} height={90}/>
                                : <></>
                            }
                        </Grid>

                        {
                            !imageChanged ?
                                <Button
                                    endIcon={<CropOriginalIcon />}
                                    size="small"
                                    className="btn-choose"
                                    color={"primary"}
                                    variant={"contained"}
                                    onClick={() => setIsMediaOpen(true)}
                                    component="span">
                                    Αλλαγή
                                </Button>
                                :
                                <Button
                                    endIcon={<Save />}
                                    onClick={() => {
                                        updateThesisImage(thesisID, selectedFile).then( () => {
                                            setImageChanged(false);
                                        })
                                    }}
                                    size="small"
                                    className="btn-choose"
                                    color={"primary"}
                                    variant={"contained"}
                                    component="span">
                                    Αποθήκευση
                                </Button>
                        }
                    </Grid>
                    <MediaChooserPopup onMediaChose={(featuredImageID) => {
                        setSelectedFile(featuredImageID);
                        setImageChanged(true);
                        setIsMediaOpen(false);
                    }} onClose={() => setIsMediaOpen(false)} isOpen={isMediaOpen} />
                </CardContent>
            </Card>
        </Grid>
    )
}