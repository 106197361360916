import BreadcrumbTemplate from "../templates/BreadcrumbTemplate";
import {Grid, Menu, MenuItem, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {Link} from "react-router-dom";

export function ThesisMenu(props) {

    const [menuItem, setMenuItem] = useState(null);
    const [menuVisibility, setMenuVisiblity] = useState(false);

    const BREADCRUMB_ITEMS = [
        <>
            <Typography onClick={(e) => {setMenuItem(e.currentTarget);setMenuVisiblity(true)}}>
                <Link>
                    Διατριβές
                </Link>
            </Typography>
            <Menu
                id="simple-menu"
                open={menuVisibility}
                anchorEl={menuItem}
                onClose={() => {setMenuVisiblity(false)}}
            >
                <MenuItem><Link to="/dashboard/instructor/theses/ungiven">Διατριβές προς ανάθεση</Link></MenuItem>
                <MenuItem><Link to="/dashboard/instructor/theses">Διατριβές σε εξέλιξη</Link></MenuItem>
                <MenuItem><Link to="/dashboard/instructor/theses/ended">Ολοκληρωμένες διατριβές</Link></MenuItem>
            </Menu>
        </>,
    ];

    if (props.breadcrumbItems) {
        if (Array.isArray(props.breadcrumbItems)) {
            BREADCRUMB_ITEMS.push(...props.breadcrumbItems);
        } else {
            BREADCRUMB_ITEMS.push(props.breadcrumbItems);
        }
    }

    return (
        <Grid item lg={12}>
            <BreadcrumbTemplate items={BREADCRUMB_ITEMS} />
        </Grid>
    )
}