import BreadcrumbTemplate from "../templates/BreadcrumbTemplate";
import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {Link} from "react-router-dom";

export function TodoListMenu(props) {

    const BREADCRUMB_ITEMS = [
        <>
            <Typography>
                {
                    props.breadcrumbItems ?
                        <Link to="/dashboard/instructor/todo-lists">
                            Λίστες εργασιών
                        </Link> : <>Λίστες εργασιών</>
                }
            </Typography>
        </>,
    ];

    if (props.breadcrumbItems) {
        if (Array.isArray(props.breadcrumbItems)) {
            BREADCRUMB_ITEMS.push(...props.breadcrumbItems);
        } else {
            BREADCRUMB_ITEMS.push(props.breadcrumbItems);
        }
    }

    return (
        <Grid item lg={12}>
            <BreadcrumbTemplate items={BREADCRUMB_ITEMS} />
        </Grid>
    )
}