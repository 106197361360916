/**
 *
 * @param {Date} date
 * @param {Number} days
 * @returns {Date}
 */
function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

/**
 *
 * @param {Date} date1
 * @param {Date} date2
 */
function getDifferentInDays(date1, date2) {
    const diffTime = date1 - date2;
    const daysUntilEnd = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return daysUntilEnd > 0 ? daysUntilEnd : 0;
}

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const DateFunctions = {
    addDays: addDays,
    getDifferentInDays: getDifferentInDays,
    formatDate: formatDate
}
