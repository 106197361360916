import config from "../config";
import Util from "./Util";

export default function authenticate(type) {
    let jwt_token = localStorage.getItem("jwt_token");
    let msg = {jwt_token};
    if (type) {
        msg.type = type;
    }
    return Util.apiCall(config.server, '/authenticate', 'POST', msg);
}