import config from "../../../config";
import Util from "../../../Util/Util";

export default class Thesis {
    constructor (
        thesisID = '',
        thesisTitle = '', 
        thesisDescr = '', 
        thesisAssignType = 'immediate',
        thesisIsPublicByInst = '0',
        thesisIsPublicByStu = 0,
        thesisIntDeadline = '',
        thesisStartDate = '',
        thesisIntendedEnd = '',
        thesisEndDate = '',
        thesisMark = '',
        thesisAbstract = '',
        student = '',
        doList = null,
        prefersTheses = [],
        keywords = [],
        instructor = null,
        attachment = null,
        featuredImageID = null
    ) {
        this.thesisID = thesisID;
        this.thesisTitle = thesisTitle;
        this.thesisDescr = thesisDescr;
        this.thesisAssignType = thesisAssignType;
        this.thesisIsPublicByInst = thesisIsPublicByInst;
        this.thesisIsPublicByStu = thesisIsPublicByStu;
        this.thesisIntDeadline = thesisIntDeadline;
        this.thesisStartDate = thesisStartDate;
        this.thesisIntendedEnd = thesisIntendedEnd;
        this.thesisEndDate = thesisEndDate;
        this.thesisMark = thesisMark;
        this.thesisAbstract = thesisAbstract;
        this.student = student
        this.doList = doList;
        this.prefersTheses = prefersTheses;
        this.keywords = keywords;
        this.instructor = instructor;
        this.attachment = attachment;
        this.featuredImageID = featuredImageID;
    }

    /**
     * Check if there are any errors
     */
    hasErrors() {
        if (this.thesisTitle === '' || this.thesisTitle === null) {
            return true;
        }

        return false;
    }

    toString() {
        return this.thesisTitle;
    }

    empty() {
        return this.id === -1;
    }
}

export function sortDoList(doList) {
    return doList.sort((a,b) => {
        if (a.dliOrder > b.dliOrder) {
            return 1;
        } else if (a.dliOrder === b.dliOrder) {
            return 0;
        } else {
            return -1;
        }
    });
}

/**
 *
 * @param {Thesis} thesis
 */
export function getThesisEndDate(thesis) {
    if (!thesis.thesisMark || !thesis.doList) return false;

    const orderedDoList = sortDoList(thesis.doList.doListItems);

    let date = new Date();
    orderedDoList.map( (doItem) => {
        if (doItem.dliStudentOK === 1 && doItem.dliInstructorOK === 1) {
            date = doItem.instrOKWhen;
        }
    })

    return date.date;
}

export function getTotalMonthsToFinish(thesis)
{
    const thesisEnd = new Date(getThesisEndDate(thesis));
    const thesisStart = new Date(thesis.thesisStartDate);

    console.log(thesisEnd ,thesisStart);

    const diffTime = Math.abs(thesisEnd - thesisStart);
    return Math.ceil(diffTime / (30000 * 60 * 60 * 24));
}

const jwt_token = localStorage.getItem("jwt_token");

/**
 *
 * @param {number} id
 * @param {number} mark
 */
export function updateThesisMark(id, mark)
{
    return Util.apiCall(config.server, `/v1/instructor/thesis/${id}/mark`, "PUT", {mark, jwt_token});
}

export function deleteSingleThesis(id) {
    return Util.apiCall(config.server, `/v1/instructor/thesis/${id}`, "DELETE", {jwt_token});
}

/**
 *
 * @param {number} id
 * @param {Thesis} thesis
 */
export function updateThesis(id, thesis) {
    return Util.apiCall(config.server, `/v1/instructor/thesis/${id}`, "PUT", {thesis, jwt_token});
}

/**
 * @returns {Promise<*>}
 */
export function thesisExpiring() {
    const jwt_token = localStorage.getItem("jwt_token");
    return Util.apiCall(config.server, `/v1/instructor/theses/expiring?jwt_token=${jwt_token}`, "GET");
}

/**
 * @returns {Promise<*>}
 */
export function getNewThesisRequests() {
    const jwt_token = localStorage.getItem("jwt_token");
    return Util.apiCall(config.server, `/v1/instructor/theses/user-requests/new?jwt_token=${jwt_token}`, "GET");
}

/**
 * @returns {Promise<*>}
 */
export function getThesesThatNeedInstructorActions() {
    const jwt_token = localStorage.getItem("jwt_token");
    return Util.apiCall(config.server, `/v1/instructor/theses/instructor-action?jwt_token=${jwt_token}`, "GET");
}

export function updateThesisImage(thesisID, imageID) {
    const jwt_token = localStorage.getItem("jwt_token");
    return Util.apiCall(config.server, `/v1/instructor/thesis/${thesisID}/image/${imageID}`, "PUT", {jwt_token});
}

/**
 *
 * @param {User} student
 * @param {number} thesisID
 * @param assignState
 * @returns {Promise<*>}
 */
export function assingStudent(student, thesisID, assignState = 1) {
    const jwt_token = localStorage.getItem("jwt_token");
    return Util.apiCall(config.server, `/v1/instructor/thesis/assign-student/${student}/${thesisID}/${assignState}`, "PUT", {jwt_token});
}