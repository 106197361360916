import React, {useEffect, useRef, useState} from 'react';
import { ButtonGroup, CardHeader, Divider, Grid, Card, CardContent, TextField, MenuItem, FormControl, Radio, RadioGroup, Select, FormControlLabel, Button, Snackbar, Typography, Breadcrumbs } from '@material-ui/core';
import assignmentTypes from "../../../DataTypes/Models/Thesis/AssignmentTypes";
import Util from '../../../Util/Util';
import config from "../../../config";
import { Alert, Skeleton } from '@material-ui/lab';
import { useParams, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ThesisDataContainer from '../../DataContainers/ThesisDataContainer';
import { Editor } from '@tinymce/tinymce-react';
import SuccessAlert from '../../RootScreenLevelInformation/SuccessAlert';
import TagControl from "../../TagControl/TagControl";
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {ThesisMenu} from "./ThesisMenu";
import {getAllTodoLists} from "../../../DataTypes/Models/TodoList/TodoList";
import DoListItemQuickSelection from "./parts/SingleThesis/DoListItemQuickSelection";
import TodoListItem from "../../Student/MyThesis/TodoListItem";
import {
    getCurrentDoListItemPercentage,
    getCurrentUserTodoItem, getStartAndEndDateOfDoListItem
} from "../../../DataTypes/Models/TodoListItem/TodoListItem";
import DeleteDoListItem from "./parts/SingleThesis/DeleteDoListItem";
import {Helmet} from "react-helmet";
import IconButton from "@material-ui/core/IconButton";
import {Edit, Save, Send} from "@material-ui/icons";
import {Link} from "react-router-dom";
import ImageRetriever from "../../../DataTypes/ImageRetriever";
import EditThesisImages from "./EditThesisImages";

const MAX_FILE_SIZE = 3000000;

/**
 * Thesis assigment acceptable types.
 */
const thesisAssignmentTypes = [
    new assignmentTypes("immediate", "Άμεση ολοκλήρωση"),
    new assignmentTypes("onDeadline", "Ολοκλήρωση στην προθεσμία")
];

export default function EditThesis(props) {

    let location = useLocation();

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedCoverImageFile, setCoverImageSelectedFile] = useState(null);

    // Form data
    const [formValues, setFormValues] = useState({});

    // Alert message
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);

    const [doLists, setDoLists] = useState([]);

    const tagRef = useRef(null);

    const updateForm = (e, field) => {
        let formValuesCp = Object.assign({}, formValues);
        formValuesCp[field] = e.target.value;
        setFormValues(formValuesCp);
    }

    // Id of the currently edditing Thesis
    const {id} = useParams();

    useEffect(() => {
        setThesis();
        if (doLists.length === 0) {
            getAllTodoLists().then( res => {
                if (res.length > 0) setDoLists(res);
            })
        }
    }, []);

    const setThesis = () => {
    
        if (location.hasOwnProperty('thesis')) {
            setFormValues(location.thesis);
        } else {
            ThesisDataContainer(`user/thesis/${id}`).then( thesis => {
                setFormValues(thesis[0]);
            });
        }
    }

    const updateDB = () => {

        if (hasErrors()) {
            return;
        }

        const tags = tagRef.current.childRef.state.tags;
        const tagTexts = tags.map( tag => tag.text );

        for (let i=0;i<formValues.keywords.length;i++) {
            const pos = tagTexts.indexOf(formValues.keywords[i].keyword);
            if (pos === -1) {
                tags.push({text: formValues.keywords[i].keyword, type: "deleted"});
            } else {
                tags[pos] = ({text: formValues.keywords[i].keyword, type: "preExisting"})
            }
        }

        const thesis = Object.assign({}, formValues);
        thesis.keywords = tags;

        thesis.thesisIntendedEnd = Util.DateFunctions.formatDate(thesis.thesisIntendedEnd);
        thesis.thesisStartDate = Util.DateFunctions.formatDate(thesis.thesisStartDate);
        thesis.thesisEndDate = Util.DateFunctions.formatDate(thesis.thesisEndDate);
        thesis.thesisIntDeadline = Util.DateFunctions.formatDate(thesis.thesisIntDeadline);

        const jwt_token = localStorage.getItem("jwt_token");
        Util.apiCall(config.server, `/v1/instructor/thesis/${id}`, "PUT", {thesis, jwt_token}).then( (res) => {
            if (!isNaN(res) && res >= 400) {

            } else {
                const keyWords = [];

                thesis.keywords.map( keyword => {
                    if (keyword.type === "exists" || keyword.type === "new" || keyword.type === "preExisting") {
                        keyWords.push({keyword: keyword.text, type: "exists"})
                    }
                });

                const thesisCp = Object.assign({}, thesis);
                thesisCp.keywords = keyWords;
                setFormValues(thesisCp);

                setIsSuccessOpen(true);
                setTimeout(() => {
                    setIsSuccessOpen(false);
                },3000);
            }
        })
    }

    const onEditorChange = (s) => {
        let formValuesCp = Object.assign({}, formValues);
        formValuesCp["thesisDescr"] = s;
        setFormValues(formValuesCp);
    }


    const hasErrors = () => {
        return formValues.thesisTitle === "" || formValues.thesisTitle === null
    }


    return (

        <Grid container className="mt-2">
            <Helmet>
                <title>ThesisGate - Επεξεργασία διατριβής</title>
            </Helmet>
            <ThesisMenu breadcrumbItems={
                <Typography>
                    Επεξεργασία {formValues.thesisTitle}
                </Typography>
            } />
            <Grid item lg={7}>
                <Card className="ml-2">
                    <CardHeader
                        title="Επεξεργασία διατριβής"
                    />
                    <Divider />
                    {
                        Object.keys(formValues).length === 0 ?
                        <div style={{margin:'20px'}}>
                            <Skeleton variant="rect" height={300} />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                        </div>
                        :
                        <CardContent>
                        <Grid container justify="center">
                            <Grid item lg={10}>

                            <h2><b>Γενικές πληροφορίες</b></h2>
                            <Grid container>

                                <Grid item lg={6}>
                                    <FormControl>
                                        <TextField
                                            error={formValues.thesisTitle === '' || formValues.thesisTitle === null}
                                            required 
                                            id="title" 
                                            value={formValues.thesisTitle} 
                                            placeholder="Τίτλος διατριβής"
                                            onChange={(e) => updateForm(e, "thesisTitle")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item lg={6}>
                                <Select
                                    required
                                    id="thesisAssignType"
                                    value={formValues.thesisAssignType}
                                    placeholder="Τύπος διατριβής"
                                    onChange={(e) => updateForm(e, "thesisAssignType")}
                                    >
                                        {
                                            thesisAssignmentTypes.map((option, index) => {
                                                return (
                                                    <MenuItem key={index} value={option.value}>
                                                        {option.text}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </Grid>

                                <Grid container className="mt-2">
                                    <Grid item lg={12} className="mt-2">
                                        <Editor
                                            initialValue={formValues.thesisDescr}
                                            init={{
                                            height: 350,
                                            menubar: false,
                                                valid_styles : { '*' : 'color,font-size,font-weight,font-style,text-decoration' },
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar:
                                                'undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help'
                                            }}
                                            onEditorChange={onEditorChange}
                                        />
                                    </Grid>
                                </Grid>

                                <TagControl existingTags={formValues.keywords.map( keyword => ({text: keyword.keyword, type: "exists"}) )} ref={tagRef} />

                                <Divider />

                                <h2 className="mt-2"><b>Ορατότητα διατριβής</b></h2>

                                <Grid item lg={12} >
                                    <FormControl component="fieldset">
                                            <RadioGroup 
                                                aria-label="thesisIsPublicByInst" 
                                                name="thesisIsPublicByInst" 
                                                value={formValues.thesisIsPublicByInst} 
                                                onChange={(e) => updateForm(e, "thesisIsPublicByInst")}
                                            >
                                            <FormControl>
                                                <FormControlLabel value="0" control={<Radio />} label="Ιδιωτική" />
                                                <FormControlLabel value="1" control={<Radio />} label="Δημόσια" />
                                            </FormControl>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <h2 className="mt-2"><b>Χρονοπρογραμματισμός</b></h2>
                                <Divider />

                                <Grid container>
                                    <MuiPickersUtilsProvider
                                        utils={MomentUtils}>
                                    <Grid item lg={6} style={{marginTop: "10px"}}>
                                        <FormControl>
                                            <span>Προθεσμία εκδήλωσης ενδιαφέροντος</span>
                                            <DatePicker
                                                variant="dialog"
                                                format="DD-MM-YYYY"
                                                value={
                                                    formValues.thesisIntDeadline
                                                }
                                                onChange={(e) => updateForm({target: {value: e}}, "thesisIntDeadline")}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={6} style={{marginTop: "10px"}}>
                                        <FormControl>
                                            <span>Hμερ/νια έναρξης</span>
                                            <DatePicker
                                                variant="dialog"
                                                format="DD-MM-YYYY"
                                                value={formValues.thesisStartDate}
                                                onChange={(e) => updateForm({target: {value: e}}, "thesisStartDate")}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={12} style={{marginTop: "10px"}}>
                                        <FormControl>
                                            <span>Hμερ/νια επιθυμητής ολο/ρωσης</span>
                                            <DatePicker
                                                variant="dialog"
                                                format="DD-MM-YYYY"
                                                value={formValues.thesisIntendedEnd}
                                                onChange={(e) => updateForm({target: {value: e}}, "thesisIntendedEnd")}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={6} className="mt-2" style={{marginTop: "10px"}}>
                                        <Button onClick={() => updateDB()} variant="contained" color="primary">Αποθήκευση</Button>
                                    </Grid>

                                    </MuiPickersUtilsProvider>

                                </Grid>
                            </Grid>

                            </Grid>
                        </Grid>
                    </CardContent>   
                    }
                </Card>
            </Grid>

            <Grid item lg={5}>
                <Grid container justify="center">
                    <Grid item lg={12} style={{
                        height: "40vh",
                        overflowY: "auto",
                        padding: "0 10px"
                    }}>
                        <Card>
                            <CardHeader
                                title="Εργασίες"
                                action={
                                    <ButtonGroup>
                                        {
                                            formValues.doList ?
                                                <Link to={`/dashboard/instructor/todo-list/edit/${formValues.doList.doListID}`}>
                                                    <IconButton>
                                                        <Edit color="primary" />
                                                    </IconButton>
                                                </Link> : <></>
                                        }
                                    {
                                        formValues.doList && formValues.doList.doListItems.length > 0 ?
                                            <DeleteDoListItem thesis={formValues} setThesis={(thesis) => setThesis(thesis)} /> : <></>
                                    }
                                    </ButtonGroup>
                                }
                            />
                            <CardContent>
                                {
                                    formValues.doList ?
                                        formValues.doList.doListItems.map((doItem, index) => {
                                            return <TodoListItem
                                                hasUser={formValues.student !== null}
                                                pos={index}
                                                currentUserTodoItem={getCurrentUserTodoItem(formValues.doList.doListItems)}
                                                userType="ins"
                                                updateTodoItem={(doItem) => {}}
                                                isOpen={true}
                                                finishPercentage={getCurrentDoListItemPercentage(formValues.doList.doListItems, doItem, formValues.thesisStartDate)}
                                                todoItem={doItem}
                                                startAndEnd={getStartAndEndDateOfDoListItem(formValues.doList.doListItems, doItem, formValues.thesisStartDate)}
                                            />
                                        }) : <></>
                                }

                                {
                                    !formValues.doList && doLists.length > 0 ?
                                        <DoListItemQuickSelection thesis={formValues} setThesis={(thesis) => setFormValues(thesis)} doLists={doLists} /> : <></>
                                }
                            </CardContent>
                        </Card>
                    </Grid>

                    {
                        Object.keys(formValues.length > 0) ?
                            <EditThesisImages thesisID={formValues.thesisID} featuredImageID={formValues.featuredImageID} /> : <></>
                    }

                </Grid>
            </Grid>

            <SuccessAlert message="Η διατριβή σας έχει αποθηκευτεί" isOpen={isSuccessOpen} />

        </Grid>
    )
}