import React, {useEffect, useState} from 'react';
import {Grid, Chip, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import Util from "../../Util/Util";
import config from "../../config";
import KeywordsAPI from "../../DataTypes/API/KeywordsAPI";

export default class TagControl extends React.Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.keywords.length < nextState.keywords.length;
    }

    constructor(props) {
        super(props);

        this.childRef = React.createRef();

        this.state = {
            keywords: [],
        }

        let keywordAPI = new KeywordsAPI();
        keywordAPI.getAll().then( res => {
            this.setState({
                keywords: res.map(item => {
                    return {text: item.keyword.toLowerCase(), type: "exists"}
                })
            })
        })
    }

    render() {
        if (this.state.keywords.length > 0) {
            return (
                <TagControlUnit UIType={this.props.UIType ? this.props.UIType : "free-input"} existingTags={this.props.existingTags} ref={(ref) => this.childRef = ref} allTags={this.state.keywords} />
            )
        } else {
            return <></>
        }
    }
}
export class TagControlUnit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tags: props.existingTags ? props.existingTags : [],
            availableTags: props.allTags,
            suggestion: null,
            searchValue: null,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleDelete(key) {
        this.setState({tags: this.state.tags.filter((tag) => tag.text !== key)})
    };

    getAvailableNonUsedTags() {
         return this.state.availableTags.filter( (tag) => {
            const val = tag.text;

            let tagExists = false;
            this.state.tags.map( sTag => {
                if (sTag.text === val) {
                    tagExists = true;
                }
            })

            return !tagExists;
        })
    }

    handleKeyDown(value) {
        const availableNonUsedTags = this.getAvailableNonUsedTags();

        value = value.toLowerCase();

        for (let i=0;i<availableNonUsedTags.length;i++) {
            let valueIsIdentical = true;

            // If value is bigger it doesn't make sense to search
            if (value.length <= availableNonUsedTags[i].text.length) {
                for (let j=0;j<value.length;j++) {
                    if (value[j] !== availableNonUsedTags[i].text[j]) {
                        valueIsIdentical = false;
                    }
                }
            } else {
                valueIsIdentical = false;
            }

            if (valueIsIdentical) {
                this.setState({suggestion:availableNonUsedTags[i]})
                return;
            }
        }

        this.setState({suggestion: null});
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12} style={{borderBottom: "1px solid black",paddingBottom: '2px'}}>
                    <h3 style={{marginBottom: "1px"}}>Tags</h3>
                </Grid>
                <Grid item xs={12}>
                    {this.state.tags.map((tag, index) => {
                        return (
                            <Chip
                                key={tag.text}
                                style={{margin: '4px'}}
                                label={tag.text}
                                onDelete={() => {this.handleDelete(tag.text)}}
                            />
                        )
                    })}
                </Grid>

                <Grid item xs={12}>
                    <p>Add tags</p>
                    <div id="search_container" style={{position:"relative"}}>
                        <div
                            style={{
                                border: "0",
                                position: 'absolute',
                                borderBottom: '1px solid black',
                                fontSize: "14px",
                                left: '10px',
                                top: "10px"
                            }}
                            id="autocomplete">{this.state.suggestion ? this.state.suggestion.text : ""}</div>
                                <TagFreeInputSearch
                                    updateState={(searchValue, suggestion, tags) => {
                                        this.setState({searchValue : searchValue.toLowerCase(), suggestion, tags});
                                    }}
                                    tags={this.state.tags}
                                    searchValue={this.state.searchValue}
                                    suggestion={this.state.suggestion}
                                    updateSearchValue={(searchValue) => {
                                        this.handleKeyDown(searchValue);
                                        this.setState({searchValue: searchValue.toLowerCase()})
                                    }}
                                />
                    </div>
                </Grid>
            </Grid>
        )
    }
}

function TagFreeInputSearch({updateState, tags, suggestion, searchValue, updateSearchValue}) {

    const handleKeyDown = (key) => {
        if(key === "Enter") {
            if (suggestion) {
                updateState('', null, tags.concat(suggestion))
            } else {
                updateState('', null, tags.concat({
                    text: searchValue,
                    type: 'new'
                }))
            }
        }
    }

    return (
        <input
            onKeyDown={(e) => {
                handleKeyDown(e.key);
            }}
            onChange={(e) => {
                updateSearchValue(e.currentTarget.value);
            }}
            style={{
                border: "0",
                borderBottom:"1px solid black",
                fontSize: "14px",
                padding: "10px"
            }}
            id="search_" value={searchValue} type="text" />
    )
}