import React, {useState, useEffect} from "react";
import config from "../../config";
import SuccessAlert from "../RootScreenLevelInformation/SuccessAlert";
import ProfileTemplate from "../Instructor/templates/ProfileTemplate";
import InformationBlock from "../Profile/InformationBlock";
import {
    Button,
    CardContent,
    Divider,
    Grid,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {Helmet} from "react-helmet";

export default function AdminProfile(props) {

    const [instructor, setInstructor] = useState({});

    const [dataLoaded, setDataLoaded] = useState(false);

    // Alert message
    const [isSuccessOpen ,setIsSuccessOpen] = useState(false);


    useEffect(() => {
        props.dataContainer().then( instructors => {
            let instr = Object.assign({}, instructors[0]);

            setInstructor(instr)
            setDataLoaded(true);
        });
    }, []);

    const setInstructorAttribute = (field, val) => {
        const instr = Object.assign({}, instructor);
        instr[field] = val;
        setInstructor(instr);
    }

    const saveUserInformation = () => {

        const jwt_token = localStorage.getItem("jwt_token");

        const formdata = new FormData();
        formdata.append("jwt_token", jwt_token);
        formdata.append("user", JSON.stringify(instructor));

        var xhr = new XMLHttpRequest();
        // Add any event handlers here...
        xhr.open('POST', config.server + props.editUserApi, true);
        xhr.send(formdata);

        xhr.onreadystatechange = function() {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                const instr = Object.assign({}, instructor);
                if (this.responseText) {
                    instr.cvFilename = this.responseText;
                }
                setInstructor(instr);

                setIsSuccessOpen(true);
                setTimeout( () => {
                    setIsSuccessOpen(false);
                }, 1000);
            }
        }
    }

    return (
        <ProfileTemplate>
            <Helmet>
                <title>ThesisGate - Προφίλ</title>
            </Helmet>
            <Divider />
            {
                dataLoaded ?
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} md={8}>
                                <Grid container>

                                    <InformationBlock updateVal={(field, val) => setInstructorAttribute(field, val)} field="uName" label="Όνομα" value={instructor.uName} />
                                    <InformationBlock updateVal={(field, val) => setInstructorAttribute(field, val)} field="uSurname" label="Επίθετο" value={instructor.uSurname} />
                                    <InformationBlock updateVal={(field, val) => setInstructorAttribute(field, val)} field="uEmail" label="Email" value={instructor.uEmail} />

                                </Grid>
                            </Grid>

                            <Grid container className="mt-2">
                                <Grid item lg={12}>
                                    <Button style={{float: "right"}} color="primary" variant="contained" onClick={() => {saveUserInformation()}}>Αποθήκευση αλλαγών</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent> : <>
                        <Grid container>
                            <Skeleton variant="rect" width={'100%'} height={600} />
                        </Grid>
                    </>
            }
            <SuccessAlert message="Η διατριβή σας έχει αποθηκευτεί" isOpen={isSuccessOpen} />
        </ProfileTemplate>
    )
}