export default class FilterString {

    /**
     * 
     * @param {string} type 
     * @param {string} val 
     */
    constructor(type, key, val = null) {
        this.type = type;
        this.key = key;
        this.val = val;
    }

    empty() {
        this.val = null;
    }

    /**
     * 
     * @param {string} val 
     */
    filter(val) {
        if (this.val == null) {
            return true;
        }
        if (this.type === "<") {
            return this.val.localeCompare(val) < 0;
        } else if (this.type === ">") {
            return this.val.localeCompare(val) > 0;
        } else {
            return this.val.localeCompare(val) === 0;
        }
    }
} 