import React, {useEffect, useState} from "react";
import {getNewThesisRequests} from "../../../DataTypes/Models/Thesis/Thesis";
import {Card, CardContent, CardHeader, IconButton, Tooltip} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {Link} from "react-router-dom";

export default function InstructorNewThesesRequests() {

    const [theses, setTheses] = useState([]);
    const [thesesLoaded, setThesesLoaded] = useState(false);

    useEffect(() => {
        if (!thesesLoaded) {
            setThesesLoaded(true);
            getNewThesisRequests().then( res => {
                setTheses(res)
            })
        }
    })

    return (
        <div style={{margin: "10px"}}>
            <Card>
                <CardHeader
                    title="Νέες αιτήσεις μαθητών για να αναλάβουν τις διατριβές την τελευταία εβδομάδα"
                />
                <CardContent>
                    {
                        theses.length === 0 || !Array.isArray(theses)?
                            "Κανένας μαθητής δεν δήλωσε την τελευταία εβδομάδα" :
                            <ul>
                                {
                                    theses.map( thesis => {
                                        return <li>
                                            <Link
                                                to={{
                                                    pathname: `/dashboard/instructor/thesis/single/${thesis.thesisID}`
                                                }}
                                            >
                                                {thesis.thesisTitle}
                                            </Link>
                                        </li>
                                    })
                                }
                            </ul>
                    }
                </CardContent>
            </Card>
        </div>
    )
}