import Util from "../../../Util/Util";
import config from "../../../config";

export class Department {

    /**
     *
     * @param {int|null} id
     * @param {string} name
     * @param {string} email
     * @param {string} phone
     * @param {string} city
     * @param {string} url
     * @param {Institute} institute
     * @param {float} lat
     * @param {float} lng
     */
    constructor(id, name, email, phone, city, url, institute, lat = 0.0, lng = 0.0) {
        this.depID = id;
        this.depName = name;
        this.depEmail = email;
        this.depPhone = phone;
        this.depCity = city;
        this.depUrl = url;
        this.institution = institute;
        this.depLat = lat;
        this.depLng = lng;
    }
}

export default class DepartmentModel {
    /**
     * @returns {Promise<Department[]>}
     */
    static getAll() {
        return Util.apiCall(config.server, '/departments', "GET");
    }

    /**
     *
     * @param {Department} department
     */
    static save(department) {
        const jwt_token = localStorage.getItem('jwt_token');
        const instID = department.institution.instID;
        delete department.institution;
        return Util.apiCall(config.server, '/v1/admin/department', 'POST', {...department, instID, jwt_token});
    }

    static delete(depID) {
        const jwt_token = localStorage.getItem('jwt_token');

        return Util.apiCall(config.server, `/v1/admin/department/${depID}`, 'DELETE', {jwt_token});
    }

    static edit(department) {
        const jwt_token = localStorage.getItem('jwt_token');
        const instID = department.institution.instID;
        delete department.institution;
        return Util.apiCall(config.server, `/v1/admin/department/${department.depID}`, 'PUT', {...department, instID, jwt_token});
    }
}