import React, {useEffect, useState} from 'react';
import { CardHeader, Divider, Grid, Card, CardContent, TextField, MenuItem, FormControl, Select, InputLabel, Button, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import TodoListItem from '../../../../DataTypes/Models/TodoListItem/TodoListItem';
import dliDurationType from '../../../../DataTypes/Models/TodoListItem/dliDurationType';
import config from '../../../../config';
import Util from '../../../../Util/Util';
import SaveIcon from '@material-ui/icons/Save';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {Add} from "@material-ui/icons";
import {Helmet} from "react-helmet";

const titleConstraint = {
    maxChar: 64
};

const descriptionConstraint = {
    maxChar: 512
}

export default function CreateTodoListItem(props) {

    const [formValues, setFormValues] = useState(new TodoListItem());

    // Alert message
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);

    const [titleCharLeft, setTitleCharLeft] = useState(titleConstraint.maxChar);

    const [descriptionCharLeft, setDescriptionCharLeft] = useState(descriptionConstraint.maxChar);

    const updateForm = (e, field) => {
        let formValuesCp = Object.assign({}, formValues);
        formValuesCp[field] = e.target.value;
        setFormValues(formValuesCp);
    }

    useEffect( () => {
        if (!props.todoListItem && formValues.dliID !== null) {
            setFormValues(new TodoListItem());
        } else if (props.todoListItem && (formValues.dliID !== props.todoListItem.dliID)) {
            setFormValues(props.todoListItem ? props.todoListItem : new TodoListItem());
        }
    });

    const updateDB = () => {

        if (hasErrors()) {
            return;
        }

        const todoListItem = formValues;
        const todoList = props.todoList;

        todoListItem.dliOrder = props.maxOrder + 1;
    
        const jwt_token = localStorage.getItem("jwt_token");
        Util.apiCall(config.server, '/v1/instructor/todo-list/item', "POST", {todoListItem, todoList, jwt_token}).then( (res) => {
            if (!isNaN(res) && res >= 400) {

            } else {
                setIsSuccessOpen(true);
                setTimeout(() => {
                    setIsSuccessOpen(false);
                },3000);
                setFormValues(new TodoListItem());
                resetConstraints();
            }
            props.addTodoListItem(res);
        })
    }

    const resetConstraints = () => {
        setDescriptionCharLeft(descriptionConstraint.maxChar);
        setTitleCharLeft(titleConstraint.maxChar);
    }

    const editDB = () => {
        if (hasErrors()) {
            return;
        }

        const todoListItem = formValues;
        const todoList = props.todoList;
    
        const jwt_token = localStorage.getItem("jwt_token");
        Util.apiCall(config.server, `/v1/instructor/todo-list/item/${todoListItem.dliID}`, "PUT", {todoListItem, todoList, jwt_token}).then( (res) => {
            if (!isNaN(res) && res >= 400) {

            } else {
                setIsSuccessOpen(true);
                setTimeout(() => {
                    setIsSuccessOpen(false);
                },3000);
                resetConstraints();
                props.editTodoListItem(todoListItem);
            }
        })
    }

    const hasErrors = () => {
        return false;
    }

    const isOnEditingMode = () => {
        return formValues.dliID !== null;
    }

    return (
        <Grid container className="mt-2">
            <Helmet>
                <title>ThesisGate - Δημιουργία Εργασίας</title>
            </Helmet>
            <Grid item lg={12}>
                

                        <Grid container justify="center">
                            <Grid item lg={10}>
                            
                                <Grid container className="mt-2">
                                    <Grid item lg={12}>
                                        <TextField
                                            style={{width:'100%'}}
                                            error={formValues.dliTitle === ''}
                                            required 
                                            id="title"
                                            label="Τίτλος"
                                            value={formValues.dliTitle} 
                                            onChange={(e) => {
                                                const valueLen = e.currentTarget.value.length; 
                                                if (valueLen > titleConstraint.maxChar) {
                                                    return;
                                                }
                                                setTitleCharLeft(titleConstraint.maxChar - valueLen);
                                                updateForm(e, "dliTitle"); }
                                            }
                                            helperText={`'Εμειναν ${titleCharLeft} χαρακτήρες`}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container className="mt-2">
                                    <Grid item lg={12} className="mt-2">
                                        <FormControl style={{width:'100%'}}>
                                            <TextField
                                                id="description"
                                                placeholder="Περιγράψτε την todo λίστα"
                                                multiline
                                                rows={3}
                                                onChange={(e) => {
                                                    const valueLen = e.currentTarget.value.length; 
                                                    if (valueLen > descriptionConstraint.maxChar) {
                                                        return;
                                                    }
                                                    setDescriptionCharLeft(descriptionConstraint.maxChar - valueLen);
                                                    updateForm(e, "dliDescr")
                                                }}
                                                value={formValues.dliDescr}
                                                helperText={`'Εμειναν ${descriptionCharLeft} χαρακτήρες`}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container className="mt-2">
                                    <Grid item lg={6}>
                                        <TextField
                                            error={formValues.dliDuration === ''}
                                            required 
                                            
                                            id="title"
                                            label="Μονάδες χρόνου"
                                            value={formValues.dliDuration} 
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                if(isNaN(val)) {
                                                    return;
                                                }

                                                if (formValues.dliDurationUnit === "days" && val > 255) {
                                                    return;
                                                }

                                                if (formValues.dliDurationUnit === "weeks" && val > 52) {
                                                    return;
                                                }

                                                if (formValues.dliDurationUnit === "months" && val > 12) {
                                                    return;
                                                }

                                                updateForm(e, "dliDuration")
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormControl style={{width:'100%'}}>
                                            <InputLabel id="thesisIDLabel">Τύπος μονάδας χρόνου</InputLabel>
                                            <Select
                                                style={{width:'100% !important'}}
                                                required
                                                id="thesisID"
                                                
                                                value={formValues.dliDurationUnit ? formValues.dliDurationUnit : 'days'}
                                                placeholder="Ανήκει σε διατριβή"
                                                onChange={(e) => {
                                                    if (e.target.value > 255) {
                                                        return;
                                                    }
                                                    updateForm(e, "dliDurationUnit", "id")
                                                }}
                                            >
                                                {
                                                    Object.keys(dliDurationType).map((option, index) => {
                                                        return (
                                                            <MenuItem key={index} value={option}>
                                                                {dliDurationType[option]}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>                        
                                </Grid>

                                <Grid container className="mt-2" spacing={2} style={{marginTop: "20px"}}>
                                    <Grid item lg={6} className="mt-2">
                                        {
                                            isOnEditingMode() ?
                                            <Button startIcon={<SaveIcon />} size="small" onClick={() => editDB()} variant="contained" color="primary">Αποθήκευση</Button> :
                                            <Button startIcon={<SaveIcon />} size="small" onClick={() => updateDB()} variant="contained" color="primary">Αποθήκευση</Button>
                                        }
                                    </Grid>
                                    <Grid item lg={6}>
                                        {
                                            isOnEditingMode() ?
                                            <Button startIcon={<AddCircleIcon />} size="small"  onClick={() => props.setTodoListItemEditing(null)} variant="contained" color="secondary">Δημιουργία νέου</Button> : <></>
                                        }
                                    </Grid>
                                </Grid>
                            
                            </Grid>
                        </Grid>

            </Grid>
            <Snackbar open={isSuccessOpen}>
                <Alert severity="success">
                    Η διατριβή σας έχει αποθηκευτεί
                </Alert>
            </Snackbar>

        </Grid>
    )
}