export default class UserValidator {
    /**
     * 
     * @param {null|string} isTypeValid 
     * @param {0|1} isJWTValid 
     * @param {0|1} isUserActive 
     * @param {null|string} email 
     */
    constructor(type, isJWTValid, isUserActive, email) {
        this.isJWTValid = isJWTValid;
        this.type = type;
        this.isUserActive = isUserActive;
        this.email = email;
    }
}