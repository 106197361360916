import React, {useState, useEffect} from 'react';
import OverviewTemplate from '../templates/OverviewTemplate';
import TodoListCollection from "./parts/TodoList/TodoListCollection";
import TodoListContainer from '../../DataContainers/TodoListContainer';
import PaginationWrapper from '../thesis/parts/PaginationWrapper';
import {TodoListMenu} from "./TodoListMenu";

export default function TodoOverview() {

    const [filteredCards, setFilteredCards] = useState(null);
    const [pagination, setPagination] = useState(1);

    useEffect(() => {
        TodoListContainer('/todo-lists/templates').then( todoList => {
            setFilteredCards(todoList);
        });
    }, []);

    const todoListItemDelete = (id) => {
        setFilteredCards(filteredCards.filter( todoListItem => {
            return todoListItem.doListID !== id;
        }))
    }

    return (
        <OverviewTemplate
            breadcrumb={
                <TodoListMenu />
            }
            cardTitle={'Όλες οι todo λίστες σας'}
            list={
                <TodoListCollection
                    paginationAmount={8}
                    pagination={pagination}
                    list={filteredCards}
                    todoListItemDelete={(id) => todoListItemDelete(id)}
                />}
            //filters={filters}
            pagination={
                <PaginationWrapper
                    paginationAmount={8}
                    setPagination={(val) => setPagination(val)}
                    list={filteredCards}
                />
            }
        />
    )
}