import React, {useEffect, useState} from 'react';

import {ListItem, ListItemIcon, ListItemText, List, ListSubheader, MenuList, Badge} from "@material-ui/core";

import { useHistory } from 'react-router-dom';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HomeIcon from '@material-ui/icons/Home';
import Util from "../../../Util/Util";
import config from "../../../config";
import makeStyles from "@material-ui/core/styles/makeStyles";

const itemList = [
    {
        text: "Προς ανάθεση",
        href: "/dashboard/instructor/theses/ungiven",
        index: 0,
        icon: <AssignmentLateIcon />
    },
    {
        text: "Σε εξέλιξη",
        href: "/dashboard/instructor/theses",
        index: 1,
        icon: <AssignmentReturnedIcon />
    },
    {
        text: "Ολοκληρωμένες",
        href: "/dashboard/instructor/theses/ended",
        index: 2,
        icon: <AssignmentTurnedInIcon />
    },
    {
        text: "Προσθήκη νέας",
        href: "/dashboard/instructor/thesis/create",
        index: 3,
        icon: <AssignmentIcon />
    },
];

const todoItemList = [
    {
        text: "Όλες οι λίστες",
        href: "/dashboard/instructor/todo-lists",
        index: 1,
        icon: <FormatListBulletedIcon />
    },
    {
        text: "Προσθήκη νέας",
        href: "/dashboard/instructor/todo-list/create",
        index: 2,
        icon: <PlaylistAddIcon />
    },
]

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
        "& .MuiListItemText-primary": {
            fontSize: "14px",
            color: "#333"
        },
        "& .MuiListSubheader-sticky": {
            fontSize: "16px",
            color: "#000"
        }
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
}));

export default function DesktopMenu() {
    const history = useHistory();
    const classes = useStyles();

    const [overviewRequestDone, setOverviewRequestDone] = useState(false);
    const [profileSettings, setProfileSettings] = useState([
        {
            text: "Αρχική",
            href: "/dashboard/instructor/overview",
            index: 0,
            icon: <><Badge badgeContent={0} color="primary"><HomeIcon /></Badge></>
        },
        {
            text: "Προφιλ",
            href: "/dashboard/instructor/profile",
            index: 1,
            icon: <AccountCircleIcon />
        },
    ]);

    useEffect(() => {
        const jwt_token = localStorage.getItem("jwt_token");
        if (!overviewRequestDone) {
            setOverviewRequestDone(true);
            Util.apiCall(config.server, `/v1/instructor/notifications/overview?jwt_token=${jwt_token}`, "GET").then(res => {
                const profileSettingsCp = Object.assign([], profileSettings);
                profileSettingsCp[0].icon = <><Badge badgeContent={res.total} color="primary"><HomeIcon /></Badge></>;
                setProfileSettings(profileSettingsCp);
            });
        }
    });

    return (

        <MenuList
            style={{borderRight:'1px solid #e0e0e0', maxHeight:'100vh', height: '98vh', backgroundColor: '#fff', paddingRight: '14px'}}
        >
            <List
                className={classes.root}
                dense={true}
                component="nav"
                aria-labelledby="nested-list-subheader"
                style={{position:'relative !important'}}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                    Προφιλ
                    </ListSubheader>
                }
            >
                
                {
                    profileSettings.map( (item, index) => {
                        return (
                            <ListItem
                                button
                                key={index}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText onClick={() => history.push(item.href)} primary={item.text} />
                            </ListItem>
                        )
                    })
                }
            </List>
            <List
                className={classes.root}
                component="nav"
                aria-labelledby="nested-list-subheader"
                style={{position:'relative !important'}}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                    Διατριβές
                    </ListSubheader>
                }
            >
                
                {
                    itemList.map( (item, index) => {
                        return (
                            <ListItem
                                button
                                key={index}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText onClick={() => history.push(item.href)} primary={item.text} />
                            </ListItem>
                        )
                    })
                }
            </List>
            <List
                className={classes.root}
                component="nav"
                aria-labelledby="nested-list-subheader"
                style={{position:'relative !important'}}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                    Λίστες εργασιών
                    </ListSubheader>
                }
            >
                {
                    todoItemList.map( (item, index) => {
                        return (
                            <ListItem
                                button
                                key={index}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText onClick={() => history.push(item.href)} primary={item.text} />
                            </ListItem>
                        )
                    })
                }
            </List>

        </MenuList>
    )
}