import { Grid, TextField, Typography } from "@material-ui/core";
import React from "react";

export default function InformationBlock({label, value, field, updateVal}) {
    return (
        <Grid container className="mt-2">
            <Grid item lg={7} xs={12}>
                <TextField
                    fullWidth
                    onChange={(e) => {updateVal(field, e.currentTarget.value)}}
                    id={label}
                    value={value}
                    label={label}
                    required
                />
            </Grid>
        </Grid>
    )
}