import React, {useState} from 'react';
import Util from '../Util/Util';
import DesktopMenu from './Instructor/Menu/DesktopMenu';
import { Button, Drawer, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import MailIcon from '@material-ui/icons/Mail';

export default function MainMenu(props) {
    const viewPortSize = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    if (viewPortSize > Util.viewPortSizes.phoneSize) {
        return props.desktopMenu
    } else {
        return (
            <>
              <Drawer
                  onClose={() => props.setIsPhoneMenuOpen(false)}
                anchor={'left'}
                open={props.isPhoneMenuOpen}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                  {props.desktopMenu}
              </Drawer>
            </>
          );
    }
}