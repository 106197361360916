import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import InstituteModel, {Institute} from "../../../DataTypes/Models/Institute/Institute.model";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Instructor, {InstructorModel} from "../../../DataTypes/Models/Users/Instructor";


/**
 *
 * @param isOpen
 * @param onSave
 * @param onClose
 * @param {Institute} institute
 * @returns {JSX.Element}
 * @constructor
 */
export function NewUserModel({isOpen = true, onSave, onClose}) {

    const [username, setUsername] = useState(null);
    const [name, setName] = useState(null);
    const [surname, setSurname] = useState(null);
    const [email, setEmail] = useState(null);
    const [instrRank, setInstrRank] = useState(InstructorModel.instrRank.prof);

    const [selectedInst, setSelectedInst] = useState(null);
    const [selectedDep, setSelectedDep] = useState(null);

    const [institutions, setInstitutions] = useState([]);

    useEffect(() => {
        InstituteModel.getAll().then( ins => {
            setInstitutions(ins);
            if (ins.length > 0) {
                setSelectedInst(ins[0]);
                if (ins[0].departments.length > 0) {
                    setSelectedDep(ins[0].departments[0].depID);
                }
            }
        })
    }, []);

    const save = () => {
        const inst = new Instructor(username, name, surname, email, 1, null, instrRank);
        InstructorModel.save(Object.assign({}, inst), selectedDep).then(r => {
            onSave(inst);
            resetForm();
        });

    }

    const resetForm = () => {
        setName(null);
        setSurname(null);
        setEmail(null);
        setUsername(null);
    }

    return (
        <Dialog fullWidth={true} onClose={() => {onClose();resetForm()}} open={isOpen} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Προσθήκη νέου</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="username"
                            label="Όνομα χρήστη"
                            value={username}
                            type="text"
                            onChange={(e) => setUsername(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <TextField
                            autoFocus
                            value={name}
                            margin="dense"
                            id="name"
                            onChange={(e) => setName(e.target.value)}
                            label="Όνομα"
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <TextField
                            autoFocus
                            value={surname}
                            margin="dense"
                            id="surname"
                            onChange={(e) => setSurname(e.target.value)}
                            label="Επίθετο"
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <TextField
                            autoFocus
                            value={email}
                            margin="dense"
                            id="email"
                            onChange={(e) => setEmail(e.target.value)}
                            label="Email"
                            type="email"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <InputLabel id="position">Θέση</InputLabel>
                            <Select
                                value={instrRank}
                                labelId="position"
                                id="position"
                                onChange={(e) => {
                                    setInstrRank(e.target.value);
                                }}
                                fullWidth
                            >
                            {
                                Object.keys(InstructorModel.instrRank).map( instr => {
                                    return <MenuItem value={InstructorModel.instrRank[instr]}>{InstructorModel.instrRank[instr]}</MenuItem>
                                })
                            }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        {
                            institutions.length > 0 ?
                                <FormControl>
                                    <InputLabel id="institute">Ινστιτούτο</InputLabel>
                                    <Select
                                        value={selectedInst}
                                        labelId="institute"
                                        id="institute"
                                        onChange={(e) => {
                                            setSelectedInst(e.target.value);
                                            setSelectedDep(e.target.value.departments[0].depID);
                                        }}
                                        fullWidth
                                    >
                                        {
                                            institutions.map( institute => {
                                                return <MenuItem value={institute}>{institute.instName}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl> : <></>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {
                            selectedInst ?
                                <FormControl>
                                    <InputLabel id="institute">Τμήμα</InputLabel>
                                    <Select
                                        value={selectedDep}
                                        labelId="institute"
                                        id="institute"
                                        onChange={(val) => {
                                            setSelectedDep(val.target.value);
                                        }}
                                        fullWidth
                                    >
                                        {
                                            selectedInst.departments.map( department => {
                                                return <MenuItem value={department.depID}>{department.depName}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl> : <></>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {onClose();resetForm()}} color="primary">
                    Ακύρωση
                </Button>
                <Button onClick={() => {
                    save();
                }} color="primary">
                    Αποθήκευση
                </Button>
            </DialogActions>
        </Dialog>
    )
}