import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React from 'react';

export default function PaginationWrapper({list, setPagination, pagination, paginationAmount = 6}) {

    if (list === null) {
        return <></>
    }

    return (
        list.length > paginationAmount ?
        <Grid container justify="center">
            <Pagination style={{position:'absolute', bottom: '50px'}} onChange={(e, v) => {setPagination(v)}} page={pagination} count={parseInt(list.length / paginationAmount) + 1} />
        </Grid> : <></>
    )
}