import React from "react";
import "../App.css";
import {Helmet} from "react-helmet";

export function AccessDenied() {
    return (
        <div style={{width: "100%", justifyContent: "center", display: "flex", height: "100vh", backgroundColor: "#1a1a1a"}}>
            <Helmet>
                <title>ThesisGate - 403</title>
            </Helmet>
            <h1 style={{color: "#fff", alignSelf: "center"}}>403: ACCESS DENIED</h1>
        </div>
    )
}