export default class FilterIsNull {

    /**
     * 
     * @param {string} type 
     * @param {string} key 
     */
    constructor(type, key) {
        this.type = type;
        this.key = key;
    }

    empty() {
        this.type = null;
    }

    filter(val) {
        if (this.type === null) {
            return true;
        }
        
        if (this.type === "=") {
            return val === null;
        } else {
            return val !== null;
        }
    }
} 