import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Paper } from "@material-ui/core";
import {useEffect, useState} from "react";
import DepartmentModel, {Department} from "../../../DataTypes/Models/Department/Department.model";
import {Add, Delete, Edit} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";
import InstituteModel from "../../../DataTypes/Models/Institute/Institute.model";
import {InstitusionNewModel} from "./InstitutionNewModel";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import InstitutionEditModel from "./InstitutionEditModel";

const columns = [
    { field: 'id', headerName: 'ID', width: 70},
    { field: 'instName', headerName: 'Όνομα', width: 180},
    { field: 'instType', headerName: 'Είδος', width: 180},
    {
        field: 'instURL',
        headerName: 'URL',
        width: 150
    },
];

export default function InstitutionControl() {

    const [items, setItems] = useState([]);
    const [rowSelected, setRowSelected] = useState(null);

    const [isSaveOpen, setIsSaveOpen] = useState(false);
    const [isDeleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);
    const [deleteSBText, setDeleteSBText] = useState(null);

    const [isEditOpen, setIsEditOpen] = useState(false);


    /**
     *
     * @param {Department[]} rows
     */
    const adaptDBColumns = (rows) => {
        const depsCp = [...items];
        for (let i=0;i<rows.length;i++) {
            depsCp.push({...rows[i], id: rows[i].instID});
        }
        setItems(depsCp);
    }

    useEffect(() => {
        InstituteModel.getAll().then( res => {
            adaptDBColumns(res);
        })
    }, []);

    const deleteInstitution = () => {
        InstituteModel.delete(rowSelected.instID).then( res => {
            if (res.hasOwnProperty('message')) {
                setDeleteSBText(res.message);
                setDeleteSnackbarOpen(true);
            } else {
                const depCp = [...items];
                let pos = -1;
                for (let i=0;i<depCp.length;i++) {
                    if (depCp[i].instID === rowSelected.instID) {
                        pos = i;
                        break;
                    }
                }

                depCp.splice(pos, 1);
                setItems(depCp);
                setRowSelected(null);
            }
        });
    }

    return (
        <>
            <Paper style={{padding: "10px", margin: "15px"}}>
                <Typography variant="h5">Διαχείρηση ινστιτούτων</Typography>
            </Paper>
            <Paper elevation={2} style={{margin: "5px 15px"}}>
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid onRowSelected={(e) => setRowSelected(e.data)} rows={items} columns={columns} />
                </div>
            </Paper>
            <Fab onClick={() => setIsSaveOpen(true)} style={{float: 'right', marginRight: "15px"}} color="primary" aria-label="add">
                <Add />
            </Fab>
            <Fab onClick={() => setIsEditOpen(true)} disabled={rowSelected === null} style={{float: 'right', marginRight: "15px"}} color="primary" aria-label="add">
                <Edit />
            </Fab>
            <Fab onClick={() => deleteInstitution()} disabled={rowSelected === null} style={{float: 'right', marginRight: "15px"}} color="primary" aria-label="add">
                <Delete />
            </Fab>

            <InstitusionNewModel onSave={(inst) => {
                setItems(items.concat(inst));
                setIsSaveOpen(false);
            }} onClose={() => setIsSaveOpen(false)} isOpen={isSaveOpen} />

            <InstitutionEditModel
                institute={rowSelected !== null ? rowSelected : null}
                onSave={(inst) => {
                    const itemsCp = Object.assign([], items);
                    let pos = -1;

                    for (let i=0;i<itemsCp.length;i++) {
                        if (itemsCp[i].instID === inst.instID) {
                            pos = i;
                            break;
                        }
                    }

                    itemsCp[pos] = inst;
                    setItems(itemsCp);
                }}
                onClose={() => setIsEditOpen(false)}
                isOpen={isEditOpen}
            />

            <Snackbar open={isDeleteSnackbarOpen} onClose={() => setDeleteSnackbarOpen(false)}>
                <Alert onClose={() => setDeleteSnackbarOpen(false)} severity="error">
                    {deleteSBText}
                </Alert>
            </Snackbar>
        </>
    );
}