import Util from "../../Util/Util";
import config from "../../config";

export default class PrefersThesis {

    /**
     *
     * @param {number} thesisID
     * @param {Student} student
     * @param {number}prefOrder
     * @param {Date} prefWhen
     * @param {boolean} isAssigned
     */
    constructor(thesisID, student, prefOrder, prefWhen, isAssigned) {
        this.thesisID = thesisID;
        this.student = student;
        this.prefOrder = prefOrder;
        this.prefWhen = prefWhen;
        this.isAssigned = isAssigned;
    }
}

export async function addNewPrefersThesis(thesisID, prefOrder) {
    const jwt_token = localStorage.getItem("jwt_token");
    const call = await Util.apiCall(
        config.server,
        `/v1/student/preferedThesis/add/${thesisID}`,
        "POST",
        {jwt_token, prefOrder}
        ).then( res => {
            console.log(res);
        return res;
    });
    return call;
}

export async function removePrefersThesis(thesisID) {
    const jwt_token = localStorage.getItem("jwt_token");
    await Util.apiCall(config.server, `/v1/student/preferedThesis/remove/${thesisID}`, "POST", {jwt_token});
    return;
}