import React, {useState} from 'react';
import Util from '../Util/Util';
import MainMenu from './MainMenu';
import {Grid, AppBar, IconButton, Button, Toolbar} from "@material-ui/core"
import MenuIcon from '@material-ui/icons/Menu';
import logoutUser from "../lib/LogoutUser";
import {useHistory} from "react-router-dom";
import useWindowSize from "../Hooks/WindowResizeHook";

export default function Dashboard(props) {

    const history = useHistory();
    const viewPortSize = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    const windowSize = useWindowSize();

    const [isPhoneMenuOpen, setIsPhoneMenuOpen] = useState(false);

    if (viewPortSize > Util.viewPortSizes.phoneSize) {
        return (
            <div style={{display: "flex", justifyContent: "center", backgroundColor: "#dfdfdf"}}>
                <div style={{maxWidth: "1440px", width: "1440px", borderRadius: "10px", backgroundColor: "#fafafa"}}>
                    <div style={{display: "grid", gridTemplateColumns: "17% 83%"}}>
                        <MainMenu
                            desktopMenu={props.menu}
                        />

                        <div style={{position: "relative"}}>
                            <AppBar elevation={0} position="sticky">
                                <Toolbar>
                                    <Button color="inherit" onClick={() => logoutUser(history)}>Logout</Button>
                                </Toolbar>
                            </AppBar>
                            {props.children}

                            {/*<footer style={{position: "absolute", bottom: "0", padding: "10px", borderTop: "1px solid #e0e0e0", backgroundColor: "#fff"}}>*/}
                            {/*    <span>Something</span>*/}
                            {/*</footer>*/}
                        </div>
                    </div>
                </div>
            </div>
        )       
    } else {
        return (
            <div>
                <MainMenu desktopMenu={props.menu} setIsPhoneMenuOpen={(bool) => setIsPhoneMenuOpen(bool)} isPhoneMenuOpen={isPhoneMenuOpen} />

                <div>
                    <AppBar position="sticky">
                        <Toolbar>
                            <IconButton onClick={() => setIsPhoneMenuOpen(true)} edge="end" color="inherit" aria-label="menu">
                                <MenuIcon />
                            </IconButton>
                            <Button color="inherit" onClick={() => logoutUser(history)}>Logout</Button>
                        </Toolbar>
                    </AppBar>
                    {props.children}
                </div>
            </div>
        )
    }
}