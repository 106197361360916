import apiCall from "./apiCall";
import authenticate from "./authenticate";
import isEmail from "./IsEmail";
import viewPortSizes from "./viewPortSizes";
import {DateFunctions} from "./DateFunctions";
import stripHtmlTags from "./StripHtmlTags";

const Util = {
    apiCall: apiCall,
    authenticate: authenticate,
    isEmail: isEmail,
    viewPortSizes: viewPortSizes,
    DateFunctions: DateFunctions,
    StripHtmlTags: stripHtmlTags
};

export default Util;